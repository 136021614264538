:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --Dark-toot-color: #33385B;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
}

.my-footer .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--Dark-toot-color);
}
.my-footer .container .left img {
  width: 70%;
}
.my-footer .container .right {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  color: var(--background-color);
}
.my-footer .container .right h4 {
  cursor: pointer;
  color: var(--white-color);
}
.my-footer .container .right h4:hover {
  text-decoration: underline;
}

/* 
    Start Media
*/
@media (max-width: 480px) {
  .my-footer .container .left img {
    width: 80px;
  }
  .my-footer .container .right {
    gap: 15px;
  }
  .my-footer .container .right h4 {
    font-size: 12px;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_footer.css.map */