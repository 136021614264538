:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --Dark-toot-color: #33385B;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
}

.my-destenations .container {
  margin-top: 1em;
}
.my-destenations .container .head {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  color: var(--toot-color);
  padding-top: 4em;
}
.my-destenations .container .head span {
  background-color: var(--toot-color);
  width: 150px;
  height: 2px;
}
.my-destenations .container .head img {
  mix-blend-mode: darken;
  width: 12%;
}
.my-destenations .container .content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  flex-wrap: wrap;
  margin-top: 2em;
}
.my-destenations .container .content .card {
  text-align: center;
}
.my-destenations .container .content .card img {
  width: 250px;
}
.my-destenations .container .explore-more {
  text-align: center;
  margin-top: 6em;
}
.my-destenations .container .explore-more button {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--toot-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
}
.my-destenations .container .explore-more button:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  border: 1px solid var(--toot-color);
  transition: 0.3s ease;
}
.my-destenations .container .explore-more button:hover i {
  transform: rotate(0deg);
  transition: 0.3s ease;
}
.my-destenations .container .explore-more button i {
  transform: rotate(-45deg);
  transition: 0.3s ease;
}

/* 
    Start Media
*/
@media (max-width: 767px) {
  .my-destenations .container .head h1 {
    font-size: 1.5em;
  }
  .my-destenations .container .head img {
    width: 20%;
  }
  .my-destenations .container .content .card img {
    width: 200px;
  }
}
@media (max-width: 480px) {
  .my-destenations .container .content .card img {
    width: 150px;
  }
  .my-destenations .container .explore-more {
    margin-top: 4em;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_destenations.css.map */