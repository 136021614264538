:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.my-privicy-dashboard .container .add {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 3em;
}
.my-privicy-dashboard .container .add .add-title {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-bottom: 1em;
}
.my-privicy-dashboard .container .add .add-title label {
  margin-bottom: 0.5em;
}
.my-privicy-dashboard .container .add .add-title input {
  padding: 0.8em;
  direction: rtl;
  border-radius: 6px;
  border: 1px solid #C8C7CC;
  width: 800px;
  outline: none;
}
.my-privicy-dashboard .container .add .add-title textarea {
  padding: 0.8em;
  direction: rtl;
  border-radius: 6px;
  border: 1px solid #C8C7CC;
  max-width: 800px;
  min-width: 800px;
  height: 100px;
  outline: none;
}
.my-privicy-dashboard .container .add button {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: 1px solid var(--blue-color);
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 150px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-privicy-dashboard .container .add button:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}
.my-privicy-dashboard .container .head-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 4em;
}
.my-privicy-dashboard .container .head-content span {
  width: 350px;
  height: 2px;
  background-color: var(--toot-color);
  display: inline-block;
}
.my-privicy-dashboard .container .head-content h2 {
  color: var(--toot-color);
}
.my-privicy-dashboard .container .all-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.my-privicy-dashboard .container .all-content .my-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  cursor: pointer;
  margin-top: 1em;
}
.my-privicy-dashboard .container .all-content .my-details details {
  background-color: #E1E1E1;
  width: 70%;
  padding: 1.5em;
  border-radius: 6px;
}
.my-privicy-dashboard .container .all-content .my-details details summary {
  text-align: right;
  color: var(--toot-color);
  font-weight: bold;
}
.my-privicy-dashboard .container .all-content .my-details details p {
  padding: 1em;
  font-size: 15px;
}
.my-privicy-dashboard .container .all-content .my-details i {
  background-color: rgba(0, 0, 0, 0.4941176471);
  color: var(--white-color);
  padding: 0.5em;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.my-privicy-dashboard .container .all-content .option {
  display: flex;
  gap: 20px;
  margin-top: 1em;
  margin-right: auto;
  margin-left: auto;
}
.my-privicy-dashboard .container .all-content .option button {
  color: var(--white-color);
  border: none;
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 100px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-privicy-dashboard .container .all-content .option button.edit {
  background-color: #33A850;
  border: 1px solid #33A850;
  transition: 0.3s ease;
}
.my-privicy-dashboard .container .all-content .option button.edit:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}
.my-privicy-dashboard .container .all-content .option button.delete {
  background-color: #E74133;
  border: 1px solid #E74133;
}
.my-privicy-dashboard .container .all-content .option button.delete:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}

/* 
    Start Media
*/
@media (max-width: 767px) {
  .my-privicy-dashboard .container .add .add-title input {
    width: 500px;
  }
  .my-privicy-dashboard .container .add .add-title textarea {
    min-width: 500px;
    max-width: 500px;
  }
}
@media (max-width: 480px) {
  .my-privicy-dashboard .container .add .add-title input {
    width: 300px;
  }
  .my-privicy-dashboard .container .add .add-title textarea {
    min-width: 300px;
    max-width: 300px;
  }
  .my-privicy-dashboard .container .head-content {
    gap: 10px;
  }
  .my-privicy-dashboard .container .head-content span {
    width: 100px;
  }
  .my-privicy-dashboard .container .head-content h2 {
    font-size: 16px;
  }
  .my-privicy-dashboard .container .my-details details {
    width: 90%;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_privicy_dashboard.css.map */