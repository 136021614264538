:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }

//   body {
//     height: 3000px;
//   }

  // Public Class
  .hide-avalable-sites {
    visibility: hidden;
  }
  
  .my-search-result-flight {
    .container {
        .this-search-flight {
            padding-top: 7em;
            
            h1 {
                background-color: var(--place-holder-color);
            }
        }

        .head {
            .top {
                display: flex;
                justify-content: center;
                align-items: center;

                background-color: var(--white-color);

                .left {
                    img {
                        mix-blend-mode: multiply;
                    }
                }

                .right {
                    text-align: center;

                    h2 {
                        color: var(--toot-color);
                        font-weight: bold;
                    }
                }
            }

            .down {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 40px;

                background-color: #E2F1FF;
                padding: 1em;

                .info {
                    text-align: center;

                    p {
                        margin-bottom: 0.5em;
                    }
                }

                span {
                    width: 2px;
                    height: 25px;
                    background-color: var(--toot-color);
                    display: inline-block;
                }
            }

            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
    
                padding-top: 4em;

                // For search result flight
                // p {
                //     &:nth-of-type(3) {
                //         color: var(--toot-color);
                //         font-weight: bold;
                //     }

                //     &:nth-of-type(4) {
                //         color: var(--toot-color);
                //         font-weight: bold;
                //     }

                //     &:nth-of-type(5) {
                //         color: var(--toot-color);
                //         font-weight: bold;
                //     }

                // }

                //  For back tripe
                p {
                    &:nth-of-type(1) {
                        color: var(--toot-color);
                        font-weight: bold;
                    }

                    &:nth-of-type(2) {
                        color: var(--toot-color);
                        font-weight: bold;
                    }

                    &:nth-of-type(3) {
                        color: var(--toot-color);
                        font-weight: bold;
                    }

                    &:nth-of-type(4) {
                        color: var(--toot-color);
                        font-weight: bold;
                    }

                }
                
    
                // For search result flight
                // p.circle {
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
    
                //     width: 20px;
                //     height: 20px;
                //     border-radius: 50%;
    
                //     &:nth-child(2) {
                //         border: 1px solid #C2C0C0;
                //         color: #C2C0C0;
                //     }
    
                //     &:nth-child(5) {
                //         background-color: var(--toot-color);
                //         color: var(--background-color);
                //     }
                // }

                //  For back tripe
                p.circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
    
                    &:nth-child(2) {
                        border: 1px solid var(--toot-color);
                        color: var(--toot-color);
                    }
    
                    &:nth-child(5) {
                        background-color: var(--toot-color);
                        color: var(--background-color);
                    }
                }
    
                hr {
                    width: 15%;
                    height: 2px;
                    background-color: var(--toot-color);
                    border: none;
                }
    
                i {
                    color: var(--toot-color);
                }
            }

            .arrange {
                display: flex;
                align-items: center;
                justify-content: end;
                gap: 10px;

                margin: 2em;

                button {
                    background-color: var(--white-color);
                    color: var(--toot-color);
                    border: 1px solid var(--blue-color);
                    outline: none;
                    padding: 0.5em;
                    border-radius: 6px;
                    width: 150px;
                    font-size: medium;
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.3s ease;

                    &:hover {
                        background-color: var(--blue-color);
                        color: var(--white-color);
                        transition: 0.3s ease;
                    }
                }
            }
        }

        .content {
            display: flex;
            gap: 1em;
  
            //margin-top: 5em;
            width: 100%;
  
            .left {
              width: 25%;
              margin-left: 1em;
              //margin-top: 1em;
  
              .show-hide-filter {
                text-align: center;
                display: none;

                button {
                    background-color: var(--blue-color);
                    color: var(--background-color);
                    border: 1px solid transparent;
                    outline: none;
                    padding: 0.5em;
                    border-radius: 6px;
                    width: 50%;
                    margin: 1em auto;
                    font-size: medium;
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.3s ease;

                    &:hover {
                        background-color: var(--background-color);
                        color: var(--blue-color);
                        border: 1px solid var(--blue-color);
                        transition: 0.3s ease;
                    }
                }
              }
  
              .my-filter {
                background-color: var(--white-color);
                padding: 0.5em;
                border-radius: 6px;
                border: 1px solid var(--blue-color);
  
                .rate-hotel {
                    padding: 0.5em;

                    h2 {
                      color: var(--toot-color);
                      display: flex;
                      gap: 5px;
                      justify-content: center;
                      align-items: center;
                      margin-bottom: 1em;
                    }
    
                    .my-content {
                      display: flex;
                      flex-direction: column;
                      align-items: end;
                      
                      margin-top: 1em;

                      &:not(:nth-child(1)) {
                        margin-top: 2em;
                      }

                      h4 {
                        color: var(--blue-color);
                      }
  
                      .my-clock {
                        display: flex;
                        gap: 5px;
                        align-items: center;

                        margin-top: 0.5em;
                      }
                    }
                }
  
                hr {
                  margin: 1em 0;
                  width: 80%;
                  height: 1px;
                  background-color: gray;
                  margin-left: auto;
                  margin-right: auto;
                }

                .rate-hotel {
                    .my-content-flight {
                        display: flex;
                        justify-content: space-between !important;
                        align-items: center;

                        margin-top: 0.5em;

                        .left {
                            margin-left: 0;
                            img {
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                            }
                        }

                        .right {
                            display: flex;
                            //align-items: center;
                            gap: 5px;

                            width: fit-content;
                        }
                    }

                    .show-all {
                        display: flex;
                        align-items: end;
                        justify-content: end;

                        color: var(--blue-color);
                        cursor: pointer;
                        margin-top: 1em;

                        a {
                            text-decoration: underline;
                            color: var(--blue-color);
                        }
                    }

                    
                }
  
                .price {
                  h2 {
                    color: var(--toot-color);
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1em;
                  }
  
                  .my-content {
                    text-align: center;
                    display: flex;
                    input {
                      width: 80%;
                      margin-left: auto;
                    }
                  }
                }
                
              }
            }
  
            .right {
              width: 75%;
              //margin-right: 1em;
  
              .my-hotels {
            
                .my-content {

                    // For back tripe
                    .go-tripe {
                        .go-head {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            background-color: #33A850;
                            padding: 2em 1em;
                            border-radius: 6px;
                            

                            button {
                                background-color: var(--white-color);
                                color: #33A850;
                                border: none;
                                outline: none;
                                padding: 0.5em;
                                border-radius: 6px;
                                width: 100px;
                                font-size: medium;
                                font-weight: 700;
                                cursor: pointer;
                                transition: 0.3s ease;
            
                                &:hover {
                                    background-color: #2e6b3d;
                                    color: var(--white-color);
                                    transition: 0.3s ease;
                                }
                            }

                            .choose-tripe {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                color: var(--white-color);

                                span {
                                    width: 2px;
                                    height: 30px;
                                    background-color: var(--background-color);
                                    display: inline-block;
                                }
                            }
                        }

                        .card-go-tripe {
                            //background-color: var(--white-color);
                            padding: 1em;
                            border-radius: 6px;
                            border: 1px solid var(--blue-color);
    
                            .top {
                               display: flex;
                               align-items: center;
                               justify-content: center;
                               gap: 20px;
    
                               .time {
                                text-align: center;
    
                                h4 {
                                    color: var(--toot-color);
                                }
    
                                span {
                                    color: #E33838;
                                    font-size: 14px;
                                }
                               }
    
                               .line-flight {
                                text-align: center;
                                width: 50%;
    
                                p {
                                    color: #878686;
                                    font-size: 14px;
                                }
    
                                img {
                                    width: 100%;
                                }
                               }
    
                               span.v-line {
                                width: 2px;
                                height: 60px;
                                background-color: var(--blue-color);
                                display: inline-block;
                               }
    
                               .logo-flight {
                                text-align: center;
                                img {
                                    width: 70px;
                                    height: 70px;
                                    border-radius: 50%;
                                }
    
                                span {
                                    color: #878686;
                                    font-size: 12px;
                                }
                               }
                            }
    
                            .details {
                                display: flex;
                                align-items: center;
                                gap: 5px;
    
                                margin: 0 1em;
    
                                cursor: pointer;
    
                                i {
                                    font-size: 14px;
                                }
                            }
    
                            hr {
                                width: 80%;
                                margin: 2em auto;
                            }
    
                            .down {
                                display: flex;
                                justify-content: end;
    
                                .right {
                                    width: fit-content;
                                    text-align: center;
                                    color: var(--blue-color);
                                    margin: 0 1em;
                                }
                            }
                        }
                    }

                    .card {
                        background-color: var(--white-color);
                        padding: 1em;
                        border-radius: 6px;
                       // border: 1px solid var(--blue-color);

                        &:not(:nth-child(1)) {
                            margin-top: 2em;
                        }

                        .top {
                           display: flex;
                           align-items: center;
                           justify-content: center;
                           gap: 20px;

                           .time {
                            text-align: center;

                            h4 {
                                color: var(--toot-color);
                            }

                            span {
                                color: #E33838;
                                font-size: 14px;
                            }
                           }

                           .line-flight {
                            text-align: center;
                            width: 50%;

                            p {
                                color: #878686;
                                font-size: 14px;
                            }

                            img {
                                width: 100%;
                            }
                           }

                           span.v-line {
                            width: 2px;
                            height: 60px;
                            background-color: var(--blue-color);
                            display: inline-block;
                           }

                           .logo-flight {
                            text-align: center;
                            img {
                                width: 70px;
                                height: 70px;
                                border-radius: 50%;
                            }

                            span {
                                color: #878686;
                                font-size: 12px;
                            }
                           }
                        }

                        .details {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            margin: 0 1em;

                            cursor: pointer;

                            i {
                                font-size: 14px;
                            }
                        }

                        hr {
                            width: 80%;
                            margin: 2em auto;
                        }

                        .down {
                            display: flex;
                            justify-content: space-between !important;

                            .left {
                                button {
                                    background-color: var(--blue-color);
                                    color: var(--white-color);
                                    border: 1px solid transparent;
                                    outline: none;
                                    padding: 0.5em;
                                    border-radius: 6px;
                                    width: 150px;
                                    font-size: medium;
                                    font-weight: 700;
                                    cursor: pointer;
                                    transition: 0.3s ease;
                
                                    &:hover {
                                        background-color: var(--background-color);
                                        color: var(--blue-color);
                                        border: 1px solid var(--blue-color);
                                        transition: 0.3s ease;
                                    }
                                }
                            }

                            .right {
                                width: fit-content;
                                text-align: center;
                                color: var(--blue-color);
                                margin: 0 1em;
                            }
                        }
                    }
                    
                }
              }
            }
          }
    }
  }




  /* 
    Start Media
  */
@media(max-width: 767px) {
    .my-search-result-flight {
        .container {
            .head {
                .top {
                    flex-direction: column;

                    .right {
                        margin-bottom: 2em;
                    }
                }

                .down {
                    flex-wrap: wrap;
                    gap: 20px;
                }
            }

            .content {
                flex-direction: column;

                .left {
                    width: 100%;
                    margin-left: 0;

                    .show-hide-filter {
                        display: block;
                    }
                }

                .right {
                    width: 100%;
                }
            }
        }
    }
}




@media(max-width: 480px) {
    .my-search-result-flight {
        .container {
            .head {
                .title {
                    gap: 2px;

                    p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}


  /* 
    End Media
  */