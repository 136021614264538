:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }

  // body {
  //   height: 2500px;
  // }


  .my-search-result-hotel {
    .container {
        .bar-hotel {
          .this-search-bar-hotel {
            .this-container {
        
                form.hotel-form {
                    
                    .content {
                        display: flex;
                        align-items: center;
                        padding-top: 8em;
                        justify-content: center;
            
                        margin-top: 0em;
                        .search {
                            display: flex;
                            justify-content: center;
                            //align-items: center;
                            gap: 5px;
            
                            background-color: var(--toot-color);
                            padding: 0.4em;
                            border-radius: 6px;
            
                            input, select {
                                padding: 0.8em;
                                border-radius: 6px;
                                border: none;
                                outline: none;
                                // To Arabic
                                direction: rtl;
                                text-align: right;
                            }
            
                            
            
                            .data-from {
                                display: flex;
                                background-color: var(--background-color);
                                border-radius: 6px;
            
                                span {
                                    color: var(--place-holder-color);
                                    font-size: 12px;
                                    margin: 8px 5px 0px 5px;
                                }
                            }
            
                            .location {
                                display: flex;
                                background-color: var(--background-color);
                                border-radius: 6px;
            
                                input {
                                    width: 20em;
                                }
            
                                select {
                                    width: 20em;
                                }
            
                                i {
                                    color: var(--toot-color);
                                    margin: 10px 5px 0px 5px;
                                    font-size: 16px;
                                }
            
                                i.fa-caret-down {
                                    color: var(--place-holder-color);
                                    font-size: 14px;
                                    margin: 11px 5px 0px 5px;
                                    cursor: pointer;
                                }
                            }
            
                            .Guest {
                                cursor: pointer;
                            }
                        }
            
                        .search-icon {
                            transform: translateX(-2em);
                            margin-left: 4em;
                            cursor: pointer;
            
                            button {
                                border-radius: 50%;
                                cursor: pointer;
                                border: none;
                                outline: none;
                                background-color: var(--toot-color);
                                
            
                                i {
                                    color: var(--background-color);
                                    background-color: var(--toot-color);
                                    padding: 0.5em;
                                    border-radius: 50%;
                                    font-size: 25px;
                                    transition: 0.3s ease;
            
                                    &:hover {
                                        background-color: var(--background-color);
                                        color: var(--toot-color);
                                        transition: 0.3s ease;
                                    }
                                }
            
                                
                            }
                        }
                    }
        
                    .open-destinations {
                        .my-content {
                            display: flex;
                            flex-direction: row-reverse;
                            justify-content: center;
                            gap: 5px;
        
                            // position: absolute;
                            // top: 300%;
                            // left: -80%;
                            background-color: #1976d24b;
                            //width: 290%;
                            text-align: right;
                            //z-index: 999;
        
                            .card {
                                display: flex;
        
                                background-color: var(--background-color);
                                width: 30%;
                                //height: 110px;
                                cursor: pointer;
                                border-radius: 14px;
        
                                .left {
                                    padding: 0.5em;
        
                                    &:hover {
                                        h4, h5 {
                                            color: var(--toot-color);
                                            transition: 0.3s ease;
                                        }
                                    }
        
                                    p {
                                        font-size: 12px;
                                        color: rgb(218, 48, 48);
                                        margin-top: 1em;
                                    }
                                }
                            }
                        }
                    }
        
                    .open-other-select {
                        .my-content {
                            display: flex;
                            flex-direction: row-reverse;
                            gap: 5px;
        
                            text-align: right;
                            //z-index: 999;
        
                            .card {
                                background-color: var(--background-color);
                                width: 100%;
                                //height: 110px;
                                cursor: pointer;
                                border-radius: 14px;
                                padding: 1em;
        
                                .first {
                                    display: flex;
                                    gap: 30px;
                                    flex-direction: row-reverse;
        
                                    .test {
                                        visibility: hidden;
                                    }
        
                                    h5 {
                                        color: rgb(99, 99, 99);
                                    }
                                }
        
                                .secound {
                                    display: flex;
                                    align-items: center;
                                    flex-direction: row-reverse;
                                    gap: 45px;
        
                                    margin-top: 2em;
        
                                    .room {
                                        display: flex;
                                        align-items: center;
                                        gap: 5px;
        
                                        h4, i {
                                            color: rgb(28, 28, 32);
                                        }
                                    }
        
                                    .my-form {
                                        .choose-room {
                                            display: flex;
                                            flex-direction: row-reverse;
                                            gap: 45px;
        
                                            .adult {
                                                select {
                                                    color: var(--toot-color);
                                                    border: none;
                                                    outline: none;
                                                    padding: 0.5em;
                                                    font-weight: bold;
                                                    text-align: center;
                                                    cursor: pointer;
                                                }
                                            }
                    
                                            .children {
                                                select {
                                                    color: var(--toot-color);
                                                    border: none;
                                                    outline: none;
                                                    padding: 0.5em;
                                                    font-weight: bold;
                                                    text-align: center;
                                                    cursor: pointer;
                                                }
                                            }
                                        } 
                                    }
                                }
        
        
                                .save {
                                        display: flex;
                                        justify-content: center;
        
                                    button {
                                        margin-top: 2em;
                                        background-color: var(--toot-color);
                                        color: var(--background-color);
                                        border: 1px solid transparent;
                                        outline: none;
                                        transition: 0.3s ease;
                                        padding: 0.5em;
                                        border-radius: 6px;
                                        cursor: pointer;
                                        font-size: 16px;
                                        width: 20%;
                        
                                        &:hover {
                                            background-color: var(--background-color);
                                            color: var(--toot-color);
                                            border: 1px solid var(--toot-color);
                                            transition: 0.3s ease;
                                        }
                                    }
                                }
        
        
        
                                hr {
                                    margin-top: 1em;
                                }
        
        
        
                                .add-room {
                                    display: flex;
                                    flex-direction: row-reverse;
                                    align-items: center;
                                    gap: 10px;
        
                                    margin-top: 1em;
                                    color: var(--toot-color);
                                }
                                
                            }
                        }
                    }
                }
        
                
            }
          }
        }

        .head {
          background-color: var(--white-color);
          padding: 1em;
          .top {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10em;

            .filter {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              select {
                padding: 0.5em;
                background-color: var(--blue-color);
                color: var(--background-color);
                width: 150px;
                text-align: center;
                font-weight: bold;
                border: none;
                border-radius: 6px;
                outline: none;
                cursor: pointer;
              }

              h3 {
                color: var(--toot-color);
              }
            }

            .result {
              text-align: center;
              h2 {
                color: var(--toot-color);
              }
            }
          }

          .down {
            h2 {
              text-align: end;
              margin-bottom: 1em;
              color: var(--toot-color);
            }

            .open-destinations {
              display: flex;
              align-items: center;
          
              i {
                  color: var(--toot-color);
                  font-size: 2em;
                  cursor: pointer;
                  transition: 0.3s ease;
                  margin: 0 1em;
          
                  &:hover {
                      color: var(--blue-color);
                      transition: 0.3s ease;
                  }
              }
          
              .my-content {
                  display: flex;
                  gap: 10px;
                  overflow-x: scroll !important;
                  scroll-behavior: smooth;
                  width: 90%;

                  &::-webkit-scrollbar {
                    display: none;
                  }
                  
                  .card {
                      display: flex;
                      width: 500px; // يمكنك تغيير العرض كما تريد
                      background-color: var(--background-color);
                      border-radius: 14px;
          
                      .left {
                          padding: 1em;
                          text-align: center;
                          width: 200px;
          
                          h4, h5 {
                              margin: 0;
                          }
          
                          p {
                              font-size: 12px;
                              color: rgb(218, 48, 48);
                              margin-top: 1em;
                          }
          
                          button {
                            padding: 0.5em;
                            background-color: var(--blue-color);
                            color: var(--background-color);
                            border: 1px solid transparent;
                            outline: none;
                            cursor: pointer;
                            border-radius: 6px;
                            margin-top: 3em;
                            width: 50%;
                            transition: 0.3s ease;

                            &:hover {
                              background-color: var(--background-color);
                              color: var(--blue-color);
                              border: 1px solid var(--blue-color);
                              transition: 0.3s ease;
                            }
                          }
                      }
          
                      .right img {
                          width: 150px;
                          height: auto;
                      }
                  }
              }
          }
          
          }
        }

        .content {
          display: flex;
          gap: 1em;

          margin-top: 2em;
          width: 100%;

          .left {
            width: 25%;
            margin-left: 1em;

            .my-map {
              //width: 25%;
              img {
                width: 100%;
              }
            }

            .my-filter {
              background-color: var(--white-color);
              padding: 0.5em;
              border-radius: 6px;
              border: 1px solid var(--blue-color);

              .rate-hotel {
                  h2 {
                    color: var(--toot-color);
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1em;
                  }
  
                  .my-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    //justify-content: space-around;
                    
                    margin-top: 1em;

                    .right {
                      display: flex;
                      align-items: center;
                      justify-content: right;
                      gap: 5px;
    
                      img {
                        width: 100px;
                      }
                    }
                  }
              }

              hr {
                margin: 1em 0;
                width: 80%;
                height: 1px;
                background-color: gray;
                margin-left: auto;
                margin-right: auto;
              }

              .price {
                h2 {
                  color: var(--toot-color);
                  display: flex;
                  gap: 5px;
                  justify-content: center;
                  align-items: center;
                  margin-bottom: 1em;
                }

                .my-content {
                  text-align: center;
                  display: flex;
                  input {
                    width: 80%;
                    margin-left: auto;
                  }
                }
              }
              
            }
          }

          .right {
            width: 75%;
            //margin-right: 1em;

            h1 {
              text-align: center;
              border: 1px solid var(--blue-color);
              padding: 0.6em;
              border-radius: 6px;
              box-shadow: 0px 0px 5px rgba(145, 145, 145, 0.623);
            }

            .my-hotels {
          
              .my-content {
                  
                  .card {
                      display: flex;
                      justify-content: space-between;
                      //width: 500px; // يمكنك تغيير العرض كما تريد
                      background-color: var(--white-color);
                      border-radius: 14px;
                      margin-top: 1em;
                      
                      .details {
                        padding: 1em;

                        i {
                          color: gray;
                          font-size: 18px;
                          cursor: pointer;
                          transition: 0.3s ease;

                          &:hover {
                            color: red;
                            transition: 0.3s ease;
                          }

                        }

                        h2 {
                          color: var(--toot-color);
                          margin-top: 2em;
                          //direction: rtl;
                        }

                        p {
                          font-size: 12px;
                          margin-top: 1em;
                        }

                        button {
                          padding: 0.5em;
                          background-color: var(--blue-color);
                          color: var(--background-color);
                          border: 1px solid transparent;
                          outline: none;
                          cursor: pointer;
                          border-radius: 6px;
                          margin-top: 3em;
                          width: 100%;
                          transition: 0.3s ease;
                          font-weight: bold;

                          &:hover {
                            background-color: var(--background-color);
                            color: var(--blue-color);
                            border: 1px solid var(--blue-color);
                            transition: 0.3s ease;
                          }
                        }
                      }
          
                      .my-image {
                        display: flex;
                        justify-content: end;

                        .left {
                          padding: 1em;
                          text-align: right;
                          width: auto;
                          // text-align: center;
                          // width: 200px;
          
                          h2 {
                            color: var(--toot-color);
                          }

                          img {
                            width: 100px;
                          }
          
                          p {
                              font-size: 12px;
                          }

                          h5 {
                            margin-top: 4em;
                            border: 1px solid var(--blue-color);
                            padding: 0.5em;
                            border-radius: 6px;
                            color: var(--blue-color);
                            width: fit-content;
                            margin-left: auto;

                            display: flex;
                            align-items: center;
                            gap: 5px;
                          }

                          p.count {
                            color: red;
                            margin-top: 0.5em;
                          }
                      }
          
                      .right {
                        //margin-left: auto !important;
                        //text-align: end;
                        width: fit-content !important;
                          img {
                            width: 150px;
                            //border-radius: 16px;
                          }
                          
                          //height: auto;
                      }
                      }

                      
                  }
              }
            }
          }
        }
    }
  }



  /* 
    Start Media
  */
@media(max-width: 992px) {
  .my-search-result-hotel {
      .container {
          form.hotel-form {
              .content {
                  flex-direction: column-reverse;
                  justify-content: center;
      
                  .search {
                      flex-direction: column-reverse;
                      //margin-left: 2em;
      
                      .data-from {
                          input {
                              width: 100%;
                          }
                          span {
                              width: 30px;
                          }
                      }
      
                      .location {
                          i {
                              width: 26px;
                          }
                      }
      
                      .my-select {
                          
                          width: 100%;
                          
                          i.fa-user {
                              //margin-left: 40px;
                              padding-right: 0 !important;
                              margin-right: 0 !important;
                              width: 20px !important;
                          }
                      }
      
                      
                  }
      
                  .search-icon {
                      margin-top: 1em;
                      
                      button {
                          margin-left: 2em;
                      }
                  }
              }
          }
      }
  }
}


// For Search Bar
// @media(max-width: 767px) {
//   .my-search-result-hotel {
//       .container {
          
//       }
//   }
// }


  @media(max-width: 767px) {
    .my-search-result-hotel {
      .container {
        form.hotel-form {
          .open-destinations {
              .my-content {
                  flex-direction: column !important;
                  background-color: transparent;

                  .card {
                    width: 100% !important;
                    justify-content: end;
                  }
              }
          }

          // .open-other-select {
          //     .my-content {
          //         justify-content: center;
          //         left: -100%;

          //         .card {
          //             width: 60%;
                      
          //         }
          //     }
             
          // }
      }

        .head {
          .top {
            flex-direction: column;
            gap: 2em;

            .filter {
              img {
                width: 70px;
              }
            }

            .result {
              margin-bottom: 2em;
            }
          }
        }

        .content {
          flex-direction: column;

          .left {
            width: 100%;
            margin-left: 0;

            .my-map {
              img {
                height: 300px;
                object-fit: cover;
                
              }
            }
          }

          .right {
            width: 100%;

            .my-hotels {
              .my-content {
                .card {
                  flex-direction: column-reverse;
                  text-align: center;

                  .details {
                    i {
                      font-size: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }













  @media(max-width: 480px) {
    .my-search-result-hotel {
      .container {

        form.hotel-form {

          .open-other-select {
              .my-content {
                
                  left: 0%;
                  top: 110%;
                  width: 100%;
                

                  .card {
                      //width: 110%;

                      .first {
                          gap: 10px !important;
                          justify-content: center;

                          .test {
                            display: none;
                          }
                      }

                      .secound {
                          gap: 10px !important;
                          justify-content: center;

                          &:nth-child(2) {
                            margin-left: 2em;
                          }
                      }

                      .save {
                        button {
                          width: 60% !important;
                        }
                      }
                      
                  }
              }
             
          }
      }

        .head {
          .down {
            h2 {
              text-align: center;
            }

            .open-destinations {
              i {
                margin: 0 0.5em;
              }

              .my-content {
                width: 100%;

                .card {
                  flex-direction: column-reverse;

                  .right {
                    margin-left: auto;
                    margin-right: auto;
                    img {
                      width: 100%;
                      border-radius: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }



   /* 
    End Media
  */