:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.my-Roules .container .hero {
  background-image: url(../../images/rules.png);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.my-Roules .container .hero h1 {
  padding-top: 17em;
  color: var(--background-color);
  margin: 0 2em 0.5em;
}
.my-Roules .container .hero hr {
  width: 50%;
  margin: 0 4em;
}
.my-Roules .container .content .head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 4em;
  margin-bottom: 6em;
}
.my-Roules .container .content .head span {
  width: 100px;
  height: 2px;
  background-color: var(--toot-color);
  display: inline-block;
}
.my-Roules .container .content .head h1 {
  width: 50%;
  text-align: center;
  color: var(--toot-color);
}
.my-Roules .container .my-details {
  text-align: center;
  cursor: pointer;
  margin-top: 1em;
}
.my-Roules .container .my-details details {
  background-color: #E1E1E1;
  width: 70%;
  margin: 0 auto;
  padding: 1.5em;
  border-radius: 6px;
}
.my-Roules .container .my-details details summary {
  text-align: right;
  color: var(--toot-color);
  font-weight: bold;
}
.my-Roules .container .my-details details p {
  padding: 1em;
  font-size: 15px;
}

/*
  Start Media
*/
@media (max-width: 480px) {
  .my-Roules .container .hero h1 {
    padding-top: 12em;
    text-align: center;
    margin: 0 auto;
  }
  .my-Roules .container .hero hr {
    width: 80%;
    margin: 1em auto;
  }
  .my-Roules .container .content .head {
    margin-bottom: 3em;
  }
  .my-Roules .container .content .head h1 {
    font-size: 18px;
    font-weight: bold;
  }
  .my-Roules .container .my-details details {
    width: 90%;
  }
}
/* 
  End Media
*//*# sourceMappingURL=style_roules.css.map */