:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.my-pakage-flight .container .this-search-flight {
  padding-top: 7em;
}
.my-pakage-flight .container .this-search-flight h1 {
  background-color: var(--place-holder-color);
}
.my-pakage-flight .container .head .top {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
}
.my-pakage-flight .container .head .top .left img {
  mix-blend-mode: multiply;
}
.my-pakage-flight .container .head .top .right {
  text-align: center;
}
.my-pakage-flight .container .head .top .right h2 {
  color: var(--toot-color);
  font-weight: bold;
}
.my-pakage-flight .container .head .down {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background-color: #E2F1FF;
  padding: 1em;
}
.my-pakage-flight .container .head .down .info {
  text-align: center;
}
.my-pakage-flight .container .head .down .info p {
  margin-bottom: 0.5em;
}
.my-pakage-flight .container .head .down span {
  width: 2px;
  height: 25px;
  background-color: var(--toot-color);
  display: inline-block;
}
.my-pakage-flight .container .head .title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 4em;
}
.my-pakage-flight .container .head .title p:nth-of-type(1) {
  color: var(--toot-color);
  font-weight: bold;
}
.my-pakage-flight .container .head .title p:nth-of-type(2) {
  color: var(--toot-color);
  font-weight: bold;
}
.my-pakage-flight .container .head .title p:nth-of-type(3) {
  color: var(--toot-color);
  font-weight: bold;
}
.my-pakage-flight .container .head .title p:nth-of-type(4) {
  color: var(--toot-color);
  font-weight: bold;
}
.my-pakage-flight .container .head .title p.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.my-pakage-flight .container .head .title p.circle:nth-child(2) {
  border: 1px solid var(--toot-color);
  color: var(--toot-color);
}
.my-pakage-flight .container .head .title p.circle:nth-child(5) {
  background-color: var(--toot-color);
  color: var(--background-color);
}
.my-pakage-flight .container .head .title hr {
  width: 15%;
  height: 2px;
  background-color: var(--toot-color);
  border: none;
}
.my-pakage-flight .container .head .title i {
  color: var(--toot-color);
}
.my-pakage-flight .container .content {
  margin-top: 2em;
}
.my-pakage-flight .container .content .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #33A850;
  padding: 1em 1em;
  border-radius: 6px;
}
.my-pakage-flight .container .content .head button {
  background-color: var(--white-color);
  color: #33A850;
  border: none;
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 100px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-pakage-flight .container .content .head button:hover {
  background-color: #2e6b3d;
  color: var(--white-color);
  transition: 0.3s ease;
}
.my-pakage-flight .container .content .head .choose-tripe {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--white-color);
}
.my-pakage-flight .container .content .head .choose-tripe span {
  width: 2px;
  height: 30px;
  background-color: var(--background-color);
  display: inline-block;
}
.my-pakage-flight .container .content .card-tripe {
  background-color: var(--white-color);
  padding: 1em;
  border-radius: 6px;
  border: 1px solid #33A850;
  width: 60%;
  margin: 0 auto;
}
.my-pakage-flight .container .content .card-tripe .top {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.my-pakage-flight .container .content .card-tripe .top .time {
  text-align: center;
}
.my-pakage-flight .container .content .card-tripe .top .time h4 {
  color: var(--toot-color);
}
.my-pakage-flight .container .content .card-tripe .top .time span {
  color: #E33838;
  font-size: 14px;
}
.my-pakage-flight .container .content .card-tripe .top .line-flight {
  text-align: center;
  width: 50%;
}
.my-pakage-flight .container .content .card-tripe .top .line-flight p {
  color: #878686;
  font-size: 14px;
}
.my-pakage-flight .container .content .card-tripe .top .line-flight img {
  width: 100%;
}
.my-pakage-flight .container .content .card-tripe .top span.v-line {
  width: 2px;
  height: 60px;
  background-color: var(--blue-color);
  display: inline-block;
}
.my-pakage-flight .container .content .card-tripe .top .logo-flight {
  text-align: center;
}
.my-pakage-flight .container .content .card-tripe .top .logo-flight img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.my-pakage-flight .container .content .card-tripe .top .logo-flight span {
  color: #878686;
  font-size: 12px;
}
.my-pakage-flight .container .content .card-tripe .details {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 1em;
  cursor: pointer;
}
.my-pakage-flight .container .content .card-tripe .details i {
  font-size: 14px;
}
.my-pakage-flight .container .content .card-tripe hr {
  width: 80%;
  margin: 2em auto;
}
.my-pakage-flight .container .content .card-tripe .down {
  display: flex;
  justify-content: end;
}
.my-pakage-flight .container .content .card-tripe .down .right {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  color: var(--blue-color);
  margin: 0 1em;
}
.my-pakage-flight .container .content .pakage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}
.my-pakage-flight .container .content .pakage .card-pakage {
  display: flex;
  gap: 20px;
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage {
  text-align: center;
  background-color: var(--white-color);
  padding: 1em;
  width: 300px;
  margin-top: 2em;
  border-radius: 6px;
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage .pakage-head h4 {
  color: var(--blue-color);
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage .pakage-head h3 {
  direction: rtl;
  color: #33A850;
  margin: 0.5em 0;
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage hr {
  width: 80%;
  margin: 1em auto;
  height: 1px;
  border: none;
  background-color: var(--place-holder-color);
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage .pakage-content p {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: var(--toot-color);
  font-weight: bold;
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage .pakage-content p i {
  color: var(--toot-color);
  font-size: 18px;
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage .pakage-content .bag {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  direction: rtl;
  margin-top: 1em;
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage .pakage-content .bag span {
  font-size: 14px;
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage .choose {
  margin-top: 1em;
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage .choose button {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: 1px solid var(--blue-color);
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 100%;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-pakage-flight .container .content .pakage .card-pakage .this-pakage .choose button:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}
.my-pakage-flight .container .total-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  margin: 2em auto;
  background-color: #E2F1FF;
  padding: 1em;
}
.my-pakage-flight .container .total-price button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--blue-color);
  color: var(--white-color);
  border: 1px solid var(--blue-color);
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-pakage-flight .container .total-price button:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}

/* 
  Start Media
*/
@media (max-width: 767px) {
  .my-pakage-flight .container .head .top {
    flex-direction: column;
  }
  .my-pakage-flight .container .head .top .right {
    margin-bottom: 2em;
  }
  .my-pakage-flight .container .head .down {
    flex-wrap: wrap;
    gap: 20px;
  }
  .my-pakage-flight .container .content .card-tripe {
    width: 100%;
  }
  .my-pakage-flight .container .pakage .card-pakage {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .my-pakage-flight .container .head .title {
    gap: 2px;
  }
  .my-pakage-flight .container .head .title p {
    font-size: 12px;
  }
  .my-pakage-flight .container .head .title hr {
    width: 10%;
  }
  .my-pakage-flight .container .content .head .choose-tripe {
    text-align: right;
  }
  .my-pakage-flight .container .content .head .choose-tripe p, .my-pakage-flight .container .content .head .choose-tripe h3 {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .my-pakage-flight .container .total-price {
    width: 100%;
  }
  .my-pakage-flight .container .total-price h2 {
    font-size: 18px;
  }
}
/* 
  End Media
*//*# sourceMappingURL=style_pakage_flight.css.map */