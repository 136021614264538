:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.hide-avalable-sites {
  visibility: hidden;
}

.my-search-result-flight .container .this-search-flight {
  padding-top: 7em;
}
.my-search-result-flight .container .this-search-flight h1 {
  background-color: var(--place-holder-color);
}
.my-search-result-flight .container .head .top {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
}
.my-search-result-flight .container .head .top .left img {
  mix-blend-mode: multiply;
}
.my-search-result-flight .container .head .top .right {
  text-align: center;
}
.my-search-result-flight .container .head .top .right h2 {
  color: var(--toot-color);
  font-weight: bold;
}
.my-search-result-flight .container .head .down {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background-color: #E2F1FF;
  padding: 1em;
}
.my-search-result-flight .container .head .down .info {
  text-align: center;
}
.my-search-result-flight .container .head .down .info p {
  margin-bottom: 0.5em;
}
.my-search-result-flight .container .head .down span {
  width: 2px;
  height: 25px;
  background-color: var(--toot-color);
  display: inline-block;
}
.my-search-result-flight .container .head .title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 4em;
}
.my-search-result-flight .container .head .title p:nth-of-type(1) {
  color: var(--toot-color);
  font-weight: bold;
}
.my-search-result-flight .container .head .title p:nth-of-type(2) {
  color: var(--toot-color);
  font-weight: bold;
}
.my-search-result-flight .container .head .title p:nth-of-type(3) {
  color: var(--toot-color);
  font-weight: bold;
}
.my-search-result-flight .container .head .title p:nth-of-type(4) {
  color: var(--toot-color);
  font-weight: bold;
}
.my-search-result-flight .container .head .title p.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.my-search-result-flight .container .head .title p.circle:nth-child(2) {
  border: 1px solid var(--toot-color);
  color: var(--toot-color);
}
.my-search-result-flight .container .head .title p.circle:nth-child(5) {
  background-color: var(--toot-color);
  color: var(--background-color);
}
.my-search-result-flight .container .head .title hr {
  width: 15%;
  height: 2px;
  background-color: var(--toot-color);
  border: none;
}
.my-search-result-flight .container .head .title i {
  color: var(--toot-color);
}
.my-search-result-flight .container .head .arrange {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin: 2em;
}
.my-search-result-flight .container .head .arrange button {
  background-color: var(--white-color);
  color: var(--toot-color);
  border: 1px solid var(--blue-color);
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 150px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-search-result-flight .container .head .arrange button:hover {
  background-color: var(--blue-color);
  color: var(--white-color);
  transition: 0.3s ease;
}
.my-search-result-flight .container .content {
  display: flex;
  gap: 1em;
  width: 100%;
}
.my-search-result-flight .container .content .left {
  width: 25%;
  margin-left: 1em;
}
.my-search-result-flight .container .content .left .show-hide-filter {
  text-align: center;
  display: none;
}
.my-search-result-flight .container .content .left .show-hide-filter button {
  background-color: var(--blue-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 50%;
  margin: 1em auto;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-search-result-flight .container .content .left .show-hide-filter button:hover {
  background-color: var(--background-color);
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
  transition: 0.3s ease;
}
.my-search-result-flight .container .content .left .my-filter {
  background-color: var(--white-color);
  padding: 0.5em;
  border-radius: 6px;
  border: 1px solid var(--blue-color);
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel {
  padding: 0.5em;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel h2 {
  color: var(--toot-color);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .my-content {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 1em;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .my-content:not(:nth-child(1)) {
  margin-top: 2em;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .my-content h4 {
  color: var(--blue-color);
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .my-content .my-clock {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 0.5em;
}
.my-search-result-flight .container .content .left .my-filter hr {
  margin: 1em 0;
  width: 80%;
  height: 1px;
  background-color: gray;
  margin-left: auto;
  margin-right: auto;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .my-content-flight {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-top: 0.5em;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .my-content-flight .left {
  margin-left: 0;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .my-content-flight .left img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .my-content-flight .right {
  display: flex;
  gap: 5px;
  width: -moz-fit-content;
  width: fit-content;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .show-all {
  display: flex;
  align-items: end;
  justify-content: end;
  color: var(--blue-color);
  cursor: pointer;
  margin-top: 1em;
}
.my-search-result-flight .container .content .left .my-filter .rate-hotel .show-all a {
  text-decoration: underline;
  color: var(--blue-color);
}
.my-search-result-flight .container .content .left .my-filter .price h2 {
  color: var(--toot-color);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.my-search-result-flight .container .content .left .my-filter .price .my-content {
  text-align: center;
  display: flex;
}
.my-search-result-flight .container .content .left .my-filter .price .my-content input {
  width: 80%;
  margin-left: auto;
}
.my-search-result-flight .container .content .right {
  width: 75%;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .go-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #33A850;
  padding: 2em 1em;
  border-radius: 6px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .go-head button {
  background-color: var(--white-color);
  color: #33A850;
  border: none;
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 100px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .go-head button:hover {
  background-color: #2e6b3d;
  color: var(--white-color);
  transition: 0.3s ease;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .go-head .choose-tripe {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--white-color);
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .go-head .choose-tripe span {
  width: 2px;
  height: 30px;
  background-color: var(--background-color);
  display: inline-block;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe {
  padding: 1em;
  border-radius: 6px;
  border: 1px solid var(--blue-color);
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top .time {
  text-align: center;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top .time h4 {
  color: var(--toot-color);
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top .time span {
  color: #E33838;
  font-size: 14px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top .line-flight {
  text-align: center;
  width: 50%;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top .line-flight p {
  color: #878686;
  font-size: 14px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top .line-flight img {
  width: 100%;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top span.v-line {
  width: 2px;
  height: 60px;
  background-color: var(--blue-color);
  display: inline-block;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top .logo-flight {
  text-align: center;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top .logo-flight img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .top .logo-flight span {
  color: #878686;
  font-size: 12px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .details {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 1em;
  cursor: pointer;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .details i {
  font-size: 14px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe hr {
  width: 80%;
  margin: 2em auto;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .down {
  display: flex;
  justify-content: end;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .go-tripe .card-go-tripe .down .right {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  color: var(--blue-color);
  margin: 0 1em;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card {
  background-color: var(--white-color);
  padding: 1em;
  border-radius: 6px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card:not(:nth-child(1)) {
  margin-top: 2em;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top .time {
  text-align: center;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top .time h4 {
  color: var(--toot-color);
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top .time span {
  color: #E33838;
  font-size: 14px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top .line-flight {
  text-align: center;
  width: 50%;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top .line-flight p {
  color: #878686;
  font-size: 14px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top .line-flight img {
  width: 100%;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top span.v-line {
  width: 2px;
  height: 60px;
  background-color: var(--blue-color);
  display: inline-block;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top .logo-flight {
  text-align: center;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top .logo-flight img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .top .logo-flight span {
  color: #878686;
  font-size: 12px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .details {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0 1em;
  cursor: pointer;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .details i {
  font-size: 14px;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card hr {
  width: 80%;
  margin: 2em auto;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .down {
  display: flex;
  justify-content: space-between !important;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .down .left button {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: 1px solid transparent;
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 150px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .down .left button:hover {
  background-color: var(--background-color);
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
  transition: 0.3s ease;
}
.my-search-result-flight .container .content .right .my-hotels .my-content .card .down .right {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  color: var(--blue-color);
  margin: 0 1em;
}

/* 
  Start Media
*/
@media (max-width: 767px) {
  .my-search-result-flight .container .head .top {
    flex-direction: column;
  }
  .my-search-result-flight .container .head .top .right {
    margin-bottom: 2em;
  }
  .my-search-result-flight .container .head .down {
    flex-wrap: wrap;
    gap: 20px;
  }
  .my-search-result-flight .container .content {
    flex-direction: column;
  }
  .my-search-result-flight .container .content .left {
    width: 100%;
    margin-left: 0;
  }
  .my-search-result-flight .container .content .left .show-hide-filter {
    display: block;
  }
  .my-search-result-flight .container .content .right {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .my-search-result-flight .container .head .title {
    gap: 2px;
  }
  .my-search-result-flight .container .head .title p {
    font-size: 12px;
  }
}
/* 
  End Media
*//*# sourceMappingURL=style_search_result_flight.css.map */