:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }



  // Public Class:-
.active-for-search-subscribe-user {
    background-color: var(--toot-color) !important;
    color: var(--background-color) !important;
}



.my-subscibe-user {
    .container {
        .head-content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
      
            margin-top: 4em;
      
            span {
              width: 350px;
              height: 2px;
              background-color: var(--toot-color);
              display: inline-block;
            }
      
            h2 {
              color: var(--toot-color);
            }
        }

        .search-user {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;

            .btn {
                display: flex;
                gap: 5px;

                margin-top: 2em;

                button {
                    padding: 0.5em;
                    outline: none;
                    width: 7em;
                    font-size: 20px;
                    font-weight: bold;
                    cursor: pointer;
                    border-radius: 6px;
                    transition: 0.3s ease;
                }

                button.mobile {
                    background-color: transparent;
                    border: 2px solid var(--toot-color);
                    color: var(--toot-color);

                    &:hover {
                        background-color: var(--toot-color);
                        color: var(--background-color);
                        transition: 0.3s ease;
                    }
                }

                button.email {
                    background-color: var(--toot-color);
                    border: 2px solid var(--toot-color);
                    color: var(--background-color);

                    &:hover {
                        background-color: var(--toot-color) !important;
                        color: var(--background-color) !important;
                        transition: 0.3s ease;
                    }
                }
            }

            input {
                padding: 0.8em;
                direction: rtl;
                border-radius: 6px;
                border: 1px solid #C8C7CC;
                width: 80%;
                outline: none;
                text-align: center;

                &::placeholder {
                    text-align: center;
                }
            }

            button.btn-search {
                background-color: var(--blue-color);
                color: var(--white-color);
                border: 1px solid var(--blue-color);
                outline: none;
                padding: 0.5em;
                border-radius: 6px;
                width: 150px;
                font-size: medium;
                font-weight: 700;
                cursor: pointer;
                transition: 0.3s ease;
                margin-top: 1em;
        
                &:hover {
                    background-color: var(--white-color);
                    color: var(--blue-color);
                    transition: 0.3s ease;
                }
            }
        }

        .subscribe-content {
            text-align: center;
            margin-top: 2em;

            .master-search {
                .search-content {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;

                    margin-top: 2em;

                    button {
                        background-color: #E74133;
                        color: var(--white-color);
                        border: 1px solid #E74133;
                        outline: none;
                        padding: 0.3em;
                        border-radius: 6px;
                        margin-bottom: 0.5em;
                        width: 50px;
                        font-size: medium;
                        font-weight: 700;
                        cursor: pointer;
                        transition: 0.3s ease;
                
                        &:hover {
                            background-color: var(--white-color);
                            color: #E74133;
                            transition: 0.3s ease;
                        }
                    }

                    p {
                        background-color: var(--white-color);
                        padding: 0.5em;
                        border-radius: 6px;
                    }
                }
            }

            .my-users {
                display: flex;
                flex-direction: column;
                align-items: end;

                margin-top: 2em;

                .info-user {
                    display: flex;
                    gap: 20px;
                    //align-items: center;

                    button {
                        background-color: #E74133;
                        color: var(--white-color);
                        border: 1px solid #E74133;
                        outline: none;
                        padding: 0.3em;
                        border-radius: 6px;
                        margin-bottom: 0.5em;
                        width: 100px;
                        font-size: medium;
                        font-weight: 700;
                        cursor: pointer;
                        transition: 0.3s ease;
                
                        &:hover {
                            background-color: var(--white-color);
                            color: #E74133;
                            transition: 0.3s ease;
                        }
                    }
  

                    input {
                        padding: 0.5em;
                        direction: rtl;
                        border-radius: 6px;
                        border: 1px solid #C8C7CC;
                        width: 300px;
                        outline: none;
                        text-align: center;
                    }
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}





/* 
    Start Media
*/
@media(max-width: 480px) {
    .my-subscibe-user {
        .container {
            .head-content {
                gap: 10px;
        
                span {
                  width: 100px;
                }
        
                h2 {
                  font-size: 16px;
                }
            }

            .subscribe-content {
                .my-users {
                    .info-user {
                        flex-direction: column-reverse;
                        gap: 5px;
                        align-items: end;
                    }
                }
            }
        }
    }
}




/* 
    End Media
*/