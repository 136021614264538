:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }



.my-offers-details {
    .container {
        .head-content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
      
            padding-top: 8em;
      
            span {
              width: 350px;
              height: 2px;
              background-color: var(--toot-color);
              display: inline-block;
            }
      
            h2 {
              color: var(--toot-color);
            }
        }

        .content {
            display: flex;
            gap: 20px;

            width: 100%;
            margin-top: 4em;
            padding: 1em;

            .left {
                width: 50%;

                img {
                    width: 100%;
                    border-radius: 10px;
                }
            }

            .right {
                width: 50%;
                text-align: center;

                h2 {
                    color: var(--toot-color);
                }

                p {
                    color: #3B3B3B;
                    margin-top: 1em;
                }

                h3 {
                    color: var(--toot-color);
                    margin-top: 5em;
                }

                .code {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    justify-content: center;

                    margin-top: 2em;

                    button {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        background-color: #FED415;
                        //color: var(--white-color);
                        border: 1px solid #FED415;
                        outline: none;
                        padding: 0.5em;
                        border-radius: 6px;
                        margin-bottom: 0.5em;
                        //width: 100%;
                        font-size: medium;
                        font-weight: 700;
                        cursor: pointer;
                        transition: 0.3s ease;
                
                        &:hover {
                            background-color: var(--white-color);
                            color: var(--toot-color);
                            transition: 0.3s ease;
                        }
                    }

                    input {
                        padding: 0.8em;
                        direction: rtl;
                        border-radius: 6px;
                        border: 1px solid #C8C7CC;
                        width: 300px;
                        outline: none;
                        text-align: center;
                      }
                }

                button.watch {
                    background-color: var(--blue-color);
                    color: var(--white-color);
                    border: 1px solid var(--blue-color);
                    outline: none;
                    padding: 0.5em;
                    border-radius: 6px;
                    margin-bottom: 0.5em;
                    margin-top: 4em;
                    width: 100%;
                    font-size: medium;
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.3s ease;
            
                    &:hover {
                        background-color: var(--white-color);
                        color: var(--blue-color);
                        transition: 0.3s ease;
                    }
                }
            }
        }
    }
}








/* 
    Start Media
*/

@media(max-width: 767px) {
    .my-offers-details {
        .container {
            .content {
                flex-direction: column;
                //justify-content: center;
                align-items: center;

                .left {
                    width: 70%;
                }
            }
        }
    }
}







@media(max-width: 480px) {
    .my-offers-details {
        .container {
            .head-content {
                gap: 10px;
        
                span {
                  width: 100px;
                }
        
                h2 {
                  font-size: 16px;
                }
              }

            .content {
                margin-top: 2em;
                .left {
                    width: 90%;
                }

                .right {
                    width: 90%;

                    .code {
                        input {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
}


/* 
    End Media
*/