:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.information-for-flight .container .first {
  padding-top: 8em;
}
.information-for-flight .container .first .tripe-info {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
  margin: 1em 2em;
}
.information-for-flight .container .first .tripe-info h4 {
  color: var(--blue-color);
}
.information-for-flight .container .first .tripe-info span {
  width: 1px;
  height: 20px;
  background-color: var(--blue-color);
  display: inline-block;
}
.information-for-flight .container .first .tripe-info h4.padge {
  background-color: var(--blue-color);
  color: var(--white-color);
  padding: 0.5em;
  border-radius: 6px;
  width: 60px;
}
.information-for-flight .container .pakage-in-booking {
  background-color: var(--white-color);
  padding: 1em;
}
.information-for-flight .container .pakage-in-booking h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--toot-color);
}
.information-for-flight .container .pakage-in-booking .all-pakage {
  display: flex;
  justify-content: space-around;
  margin-top: 2em;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage {
  width: 300px;
  margin: 1em 0;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .head {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--blue-color);
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .head h4.padge {
  background-color: #C7E1FB;
  padding: 0.5em;
  border-radius: 6px;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .your-pakage {
  display: flex;
  gap: 10px;
  margin-top: 1em;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .your-pakage .left {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .your-pakage .left h4 {
  color: var(--toot-color);
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .your-pakage .left p.red {
  color: #DF2040;
  font-size: 14px;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .your-pakage .left .my-span {
  display: flex;
  direction: rtl;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .your-pakage .left .my-span span {
  font-size: 14px;
  color: #A5A5A5;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .your-pakage .right i {
  color: var(--toot-color);
  font-size: 18px;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .add-pakage {
  margin-top: 1.5em;
  text-align: center;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .add-pakage button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #C7E1FB;
  color: var(--blue-color);
  border: 1px solid #C7E1FB;
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 240px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.information-for-flight .container .pakage-in-booking .all-pakage .pakage .content .add-pakage button:hover {
  background-color: var(--blue-color);
  color: #C7E1FB;
  transition: 0.3s ease;
}
.information-for-flight .container .pakage-in-booking .all-pakage span.center {
  width: 2px;
  height: 300px;
  background-color: var(--blue-color);
  display: inline-block;
}
.information-for-flight .container .content .info-flight .my-head {
  background-color: #F38585;
  padding: 1em;
  border-radius: 6px;
}
.information-for-flight .container .content .info-flight .my-head h2 {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
  color: var(--background-color);
}
.information-for-flight .container .content .info-flight .my-head h2 a {
  text-decoration: underline;
  color: black;
}
.information-for-flight .container .content .info-flight form.form-for-flight {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--white-color);
}
.information-for-flight .container .content .info-flight form.form-for-flight h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--toot-color);
}
.information-for-flight .container .content .info-flight form.form-for-flight h2 i {
  font-size: 30px;
}
.information-for-flight .container .content .info-flight form.form-for-flight .scane {
  margin-top: 1.5em;
  margin-bottom: 1em;
  text-align: center;
}
.information-for-flight .container .content .info-flight form.form-for-flight .scane button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #C7E1FB;
  color: var(--blue-color);
  border: 1px solid #C7E1FB;
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 240px;
  margin: 0 auto;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.information-for-flight .container .content .info-flight form.form-for-flight .scane button:hover {
  background-color: var(--blue-color);
  color: #C7E1FB;
  transition: 0.3s ease;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body {
  display: flex;
  justify-content: space-around;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-left {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-left input {
  padding: 0.5em;
  border-radius: 6px;
  outline: none;
  border: 1px solid #C8C7CC;
  margin-top: 10px;
  width: 250px;
  direction: rtl;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-left input::-moz-placeholder {
  text-align: right;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-left input::placeholder {
  text-align: right;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .part {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .part:not(:nth-child(1)) {
  margin-top: 2em;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .part input {
  padding: 0.5em;
  border-radius: 6px;
  outline: none;
  border: 1px solid #C8C7CC;
  margin-top: 10px;
  width: 250px;
  direction: rtl;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .part input::-moz-placeholder {
  text-align: right;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .part input::placeholder {
  text-align: right;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .part .my-radio {
  display: flex;
  align-items: center;
  gap: 10px;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .part .my-radio input {
  width: -moz-fit-content;
  width: fit-content;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .part p {
  font-size: 14px;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .part select {
  width: 250px;
  padding: 0.5em;
  direction: rtl;
  border-radius: 6px;
  outline: none;
  margin-top: 0.5em;
  border: 1px solid #C8C7CC;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .nationality label {
  margin-bottom: 0.5em;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .nationality .my-Select {
  width: 250px;
  border-radius: 6px;
  direction: rtl;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .check {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 2em;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .check .main {
  display: flex;
  gap: 5px;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .check .more-info {
  display: flex;
  flex-direction: column;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .check .more-info input {
  padding: 0.5em;
  border-radius: 6px;
  outline: none;
  border: 1px solid #C8C7CC;
  margin-top: 10px;
  width: 250px;
  direction: rtl;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .check .more-info input::-moz-placeholder {
  text-align: right;
}
.information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-right .check .more-info input::placeholder {
  text-align: right;
}
.information-for-flight .container .content .info-flight form.form-for-flight hr {
  width: 80%;
  margin: 1em auto;
  height: 2px;
  background-color: #E4E4E4;
  border: none;
}
.information-for-flight .container .content .info-flight form.form-for-flight .passnger {
  display: flex;
  justify-content: space-between;
  background-color: var(--toot-color);
  color: var(--white-color);
  padding: 1em;
  margin: 2em 0;
  cursor: pointer;
}
.information-for-flight .container .content .info-flight form.form-for-flight .passnger .passenger-number {
  display: flex;
  align-items: center;
  gap: 5px;
}
.information-for-flight .container .content .info-flight form.form-for-flight .total-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  margin: 2em auto;
  background-color: #E2F1FF;
  padding: 1em;
}
.information-for-flight .container .content .info-flight form.form-for-flight .total-price button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--blue-color);
  color: var(--white-color);
  border: 1px solid var(--blue-color);
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.information-for-flight .container .content .info-flight form.form-for-flight .total-price button:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}

/* 
  Start Media
*/
@media (max-width: 767px) {
  .information-for-flight .container .pakage-in-booking .all-pakage {
    flex-direction: column;
    align-items: end;
    margin: 2em 1em;
  }
  .information-for-flight .container .pakage-in-booking .all-pakage span.center {
    height: 2px;
    width: 85%;
    margin: 0 auto;
  }
}
@media (min-width: 480px) {
  .hide-in-large {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .information-for-flight .container .content .info-flight form.form-for-flight .my-body {
    flex-direction: column-reverse;
  }
  .information-for-flight .container .content .info-flight form.form-for-flight .my-body .my-left {
    display: none;
  }
}
/* 
  End Media
*//*# sourceMappingURL=style_information_for_flight.css.map */