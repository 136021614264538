:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
}

.my-more-hotel {
    .container {
        margin-top: 4em;

        .head {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            text-align: center;
            color: var(--toot-color);

            span {
                background-color: var(--toot-color);
                width: 150px;
                height: 2px;
            }

            i {
                font-size: 3em;
            }
        }

        .hotel-card-container {
            display: flex;
            align-items: center;

            margin-top: 4em;

            i {
                color: var(--toot-color);
                font-size: 2em;
                cursor: pointer;
                transition: 0.3s ease;
                margin-right: 1em;
                margin-left: 1em;

                &:hover {
                    color: var(--blue-color);
                    transition: 0.3s ease;
                }
            }

            .hotel-card {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                //justify-content: center;
                gap: 20px;
                margin-left: auto;
                margin-right: auto;
    
                width: 90%;
                overflow-x: scroll !important;

                &::-webkit-scrollbar {
                    display: none;
                }
    
                .content {
                    margin: 2em 0em;
                    position: relative;
                    cursor: pointer;
        
                    img {
                        width: 300px;
                        //display: block;
                        border-radius: 16px;
                        transition: 0.3s ease;
    
                        &:hover {
                            transform: scale(1.1);
                            transition: 0.3s ease;
                        }
                    }
        
                    .card {
                        position: absolute;
                        bottom: 5px;
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0.584);
                        text-align: center;
                        border-radius: 16px;
                        color: var(--background-color);
                        padding-bottom: 0.5em;
                        
                        h3 {
                            padding: 0.5em;
                        }
                    }
                }
            }
        }

        .explore-more {
            text-align: center;
            margin-top: 2em;

            button {
                display: flex;
                gap: 10px;
                //justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                background-color: var(--toot-color);
                color: var(--background-color);
                border: 1px solid transparent;
                outline: none;
                transition: 0.3s ease;
                padding: 0.5em;
                border-radius: 6px;
                cursor: pointer;
                font-size: 20px;

                &:hover {
                    background-color: var(--background-color);
                    color: var(--toot-color);
                    border: 1px solid var(--toot-color);
                    transition: 0.3s ease;

                    i {
                        transform: rotate(0deg);
                        transition: 0.3s ease;
                    }
                }

                i {
                    //margin: 0 10px;
                    transform: rotate(-45deg);
                    transition: 0.3s ease;
                }
            }
        }
    }
}




/* 
    Start Media
*/
@media(max-width: 767px) {
    .my-more-hotel {
        .container {
            .head {
                h1 {
                    font-size: 1.5em;
                }

                i {
                    font-size: 2em;
                }
            }
        }
    }
}







@media(max-width: 480px) {
    .my-more-hotel {
        .container {
            .hotel-card-container {
                margin-top: 2em;

                i {
                    margin-left: 0.5em;
                    margin-right: 0.5em;
                }

                .hotel-card {
                    width: 95%;

                    .content {
                        img {
                            width: 220px;
                        }
                    }
                }
            }
        }
    }
}


/* 
    End Media
*/