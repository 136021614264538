:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
}

.my-more-hotel .container {
  margin-top: 4em;
}
.my-more-hotel .container .head {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  color: var(--toot-color);
}
.my-more-hotel .container .head span {
  background-color: var(--toot-color);
  width: 150px;
  height: 2px;
}
.my-more-hotel .container .head i {
  font-size: 3em;
}
.my-more-hotel .container .hotel-card-container {
  display: flex;
  align-items: center;
  margin-top: 4em;
}
.my-more-hotel .container .hotel-card-container i {
  color: var(--toot-color);
  font-size: 2em;
  cursor: pointer;
  transition: 0.3s ease;
  margin-right: 1em;
  margin-left: 1em;
}
.my-more-hotel .container .hotel-card-container i:hover {
  color: var(--blue-color);
  transition: 0.3s ease;
}
.my-more-hotel .container .hotel-card-container .hotel-card {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  overflow-x: scroll !important;
}
.my-more-hotel .container .hotel-card-container .hotel-card::-webkit-scrollbar {
  display: none;
}
.my-more-hotel .container .hotel-card-container .hotel-card .content {
  margin: 2em 0em;
  position: relative;
  cursor: pointer;
}
.my-more-hotel .container .hotel-card-container .hotel-card .content img {
  width: 300px;
  border-radius: 16px;
  transition: 0.3s ease;
}
.my-more-hotel .container .hotel-card-container .hotel-card .content img:hover {
  transform: scale(1.1);
  transition: 0.3s ease;
}
.my-more-hotel .container .hotel-card-container .hotel-card .content .card {
  position: absolute;
  bottom: 5px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.584);
  text-align: center;
  border-radius: 16px;
  color: var(--background-color);
  padding-bottom: 0.5em;
}
.my-more-hotel .container .hotel-card-container .hotel-card .content .card h3 {
  padding: 0.5em;
}
.my-more-hotel .container .explore-more {
  text-align: center;
  margin-top: 2em;
}
.my-more-hotel .container .explore-more button {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--toot-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
}
.my-more-hotel .container .explore-more button:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  border: 1px solid var(--toot-color);
  transition: 0.3s ease;
}
.my-more-hotel .container .explore-more button:hover i {
  transform: rotate(0deg);
  transition: 0.3s ease;
}
.my-more-hotel .container .explore-more button i {
  transform: rotate(-45deg);
  transition: 0.3s ease;
}

/* 
    Start Media
*/
@media (max-width: 767px) {
  .my-more-hotel .container .head h1 {
    font-size: 1.5em;
  }
  .my-more-hotel .container .head i {
    font-size: 2em;
  }
}
@media (max-width: 480px) {
  .my-more-hotel .container .hotel-card-container {
    margin-top: 2em;
  }
  .my-more-hotel .container .hotel-card-container i {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .my-more-hotel .container .hotel-card-container .hotel-card {
    width: 95%;
  }
  .my-more-hotel .container .hotel-card-container .hotel-card .content img {
    width: 220px;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_more_hotel.css.map */