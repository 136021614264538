:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.active-link-for-sidebar-dashboard {
  background-color: #96C9D1;
  color: var(--white-color);
  transition: 0.3s ease;
}

.my-sidebar-dashboard .container .content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.my-sidebar-dashboard .container .content .left {
  width: 80%;
  padding: 1em;
}
.my-sidebar-dashboard .container .content .left .main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-sidebar-dashboard .container .content .left .main .to-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}
.my-sidebar-dashboard .container .content .left .main .to-bar i {
  cursor: pointer;
}
.my-sidebar-dashboard .container .content .right {
  width: 20%;
  background-color: #33385B;
  padding: 1em;
  height: auto;
}
.my-sidebar-dashboard .container .content .right .head {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.my-sidebar-dashboard .container .content .right .head i {
  color: var(--white-color);
  cursor: pointer;
}
.my-sidebar-dashboard .container .content .right .head Link img {
  width: 30% !important;
}
.my-sidebar-dashboard .container .content .right .my-link .this-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5em;
}
.my-sidebar-dashboard .container .content .right .my-link .this-link:hover {
  background-color: #96C9D1;
  color: var(--white-color);
  transition: 0.3s ease;
}
.my-sidebar-dashboard .container .content .right .my-link .this-link h4 {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--background-color);
  cursor: pointer;
  transition: 0.3s ease;
  padding: 0.7em;
}

/* 
  Start Media
*/
@media (min-width: 767px) {
  .my-sidebar-dashboard .container .content {
    position: relative;
  }
  .my-sidebar-dashboard .container .content .left .main i.fa-bars {
    display: none;
  }
  .my-sidebar-dashboard .container .content .right .head i.fa-circle-xmark {
    display: none;
  }
}
@media (max-width: 767px) {
  .my-sidebar-dashboard .container .content {
    position: relative;
    overflow-x: hidden !important;
  }
  .my-sidebar-dashboard .container .content .left {
    width: 100%;
  }
  .my-sidebar-dashboard .container .content .right {
    width: 30%;
    position: absolute;
    right: -40%;
    height: 100%;
  }
  .my-sidebar-dashboard .container .content .right .my-link .this-link h4 {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .my-sidebar-dashboard .container .content .left .main h2 {
    font-size: 18px;
  }
  .my-sidebar-dashboard .container .content .right {
    width: 40%;
  }
}
/* 
   End Media
 *//*# sourceMappingURL=style_sidebar_dashboard.css.map */