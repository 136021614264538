:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }


//   body {
//     height: 2000px;
//   }


.my-who-we-are {
    .container {
        .hero {
            background-image: url(../../images/who_we_are.png);
            height: 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            display: flex;
            flex-direction: column;
            align-items: end;

            h1 {
                padding-top: 17em;
                color: var(--background-color);
                margin: 0 2em 0.5em;
            }

            hr {
                width: 50%;
                margin: 0 4em;
            }
        }

        .content {
            background-color: var(--white-color);
            width: 95%;
            margin: 2em auto;
            border-radius: 6px;
            //padding: 1em 0;

            .vision {
                display: flex;
                flex-direction: column;
                align-items: end;

                padding: 2em;
                margin-top: 2em;

                h2 {
                    color: var(--toot-color);
                    margin-bottom: 1em;
                }

                .text {
                    display: flex;
                    align-items: center;
                    gap: 40px;
                    position: relative;

                    img {
                        width: 25%;
                        position: absolute;
                        top: -90%;
                    }

                    p.behinde-img {
                        width: 60%;
                        margin-left: auto;
                    }

                    p {
                        text-align: right;
                        color: #3B3B3B;
                        margin-top: 1em;
                    }

                    i {
                        font-size: 30px;
                        color: var(--toot-color);
                    }
                }

                img.hide-large-screen {
                    width: 40%;
                    margin: 2em auto;
                }

                // .by-img {
                //     flex-direction: column-reverse;
                // }
            }
        }
    }
}





/* 
    Start Media
*/
@media(min-width: 767px) {
    .my-who-we-are {
        .container {
            .content {
                .vision {
                    img.hide-large-screen {
                        display: none;
                    }
                }
            }
        }
    }

}


@media(max-width: 767px) {
    .my-who-we-are {
        .container {
            .content {
                .vision {
                    .text {
                        img {
                            display: none;
                        }

                        p.behinde-img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}


@media(max-width: 480px) {
    .my-who-we-are {
        .container {
            .hero {
                //text-align: center;
                h1 {
                    padding-top: 12em;
                    text-align: center;
                    margin: 0 auto;
                }

                hr {
                    width: 80%;
                    margin: 1em auto;
                }
            }

            .content {
                .vision {
                    img.hide-large-screen {
                        width: 70%;
                    }
                }
            }
        }
    }
}


/* 
    End Media
*/