:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --place-holder-color: #aaaaaa;
}

.active-for-flight-search-bar {
  background-color: var(--toot-color) !important;
  color: var(--background-color) !important;
}

.my-search-bar-hotel .container .top {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.my-search-bar-hotel .container .top button {
  padding: 0.8em;
  outline: none;
  width: 8em;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease;
}
.my-search-bar-hotel .container .top button i {
  margin-right: 5px;
  margin-left: 5px;
}
.my-search-bar-hotel .container .top button.Hotel {
  background-color: var(--toot-color);
  border: 2px solid var(--toot-color);
  color: var(--background-color);
}
.my-search-bar-hotel .container .top button.Hotel:hover {
  background-color: var(--toot-color) !important;
  transition: 0.3s ease;
}
.my-search-bar-hotel .container .top button.Flight {
  background-color: transparent;
  border: 2px solid var(--toot-color);
  color: var(--background-color);
}
.my-search-bar-hotel .container .top button.Flight:hover {
  background-color: var(--toot-color);
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.hotel-form .content {
  display: flex;
  align-items: center;
  margin-top: 4em;
  left: 50%;
  transform: translateX(-50%);
}
.my-search-bar-hotel .container form.hotel-form .content .search {
  display: flex;
  justify-content: center;
  gap: 5px;
  background-color: var(--toot-color);
  padding: 0.4em;
  border-radius: 6px;
}
.my-search-bar-hotel .container form.hotel-form .content .search input, .my-search-bar-hotel .container form.hotel-form .content .search select {
  padding: 0.8em;
  border-radius: 6px;
  border: none;
  outline: none;
}
.my-search-bar-hotel .container form.hotel-form .content .search .data-from {
  display: flex;
  background-color: var(--background-color);
  border-radius: 6px;
}
.my-search-bar-hotel .container form.hotel-form .content .search .data-from span {
  color: var(--place-holder-color);
  font-size: 12px;
  margin: 8px 5px 0px 5px;
}
.my-search-bar-hotel .container form.hotel-form .content .search .location {
  display: flex;
  background-color: var(--background-color);
  border-radius: 6px;
}
.my-search-bar-hotel .container form.hotel-form .content .search .location input {
  width: 20em;
}
.my-search-bar-hotel .container form.hotel-form .content .search .location select {
  width: 20em;
}
.my-search-bar-hotel .container form.hotel-form .content .search .location i {
  color: var(--toot-color);
  margin: 10px 5px 0px 5px;
  font-size: 16px;
}
.my-search-bar-hotel .container form.hotel-form .content .search .location i.fa-caret-down {
  color: var(--place-holder-color);
  font-size: 14px;
  margin: 11px 5px 0px 5px;
  cursor: pointer;
}
.my-search-bar-hotel .container form.hotel-form .content .search .Guest {
  cursor: pointer;
}
.my-search-bar-hotel .container form.hotel-form .content .search-icon {
  transform: translateX(-2em);
  margin-left: 4em;
  cursor: pointer;
}
.my-search-bar-hotel .container form.hotel-form .content .search-icon button {
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--toot-color);
}
.my-search-bar-hotel .container form.hotel-form .content .search-icon button i {
  color: var(--background-color);
  background-color: var(--toot-color);
  padding: 0.5em;
  border-radius: 50%;
  font-size: 25px;
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.hotel-form .content .search-icon button i:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.hotel-form .open-destinations .my-content {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  position: absolute;
  top: 300%;
  left: -80%;
  background-color: rgba(25, 118, 210, 0.2941176471);
  width: 290%;
  text-align: right;
}
.my-search-bar-hotel .container form.hotel-form .open-destinations .my-content .card {
  display: flex;
  background-color: var(--background-color);
  width: 30%;
  height: 110px;
  cursor: pointer;
  border-radius: 14px;
}
.my-search-bar-hotel .container form.hotel-form .open-destinations .my-content .card .left {
  padding: 0.5em;
}
.my-search-bar-hotel .container form.hotel-form .open-destinations .my-content .card .left:hover h4, .my-search-bar-hotel .container form.hotel-form .open-destinations .my-content .card .left:hover h5 {
  color: var(--toot-color);
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.hotel-form .open-destinations .my-content .card .left p {
  font-size: 12px;
  color: rgb(218, 48, 48);
  margin-top: 1em;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  position: absolute;
  top: 300%;
  left: -80%;
  width: 290%;
  text-align: right;
  z-index: 999;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card {
  background-color: var(--background-color);
  width: 100%;
  cursor: pointer;
  border-radius: 14px;
  padding: 1em;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .first {
  display: flex;
  gap: 30px;
  flex-direction: row-reverse;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .first .test {
  visibility: hidden;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .first h5 {
  color: rgb(99, 99, 99);
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .secound {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 45px;
  margin-top: 2em;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .secound .room {
  display: flex;
  align-items: center;
  gap: 5px;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .secound .room h4, .my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .secound .room i {
  color: rgb(28, 28, 32);
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .secound .my-form .choose-room {
  display: flex;
  flex-direction: row-reverse;
  gap: 45px;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .secound .my-form .choose-room .adult select {
  color: var(--toot-color);
  border: none;
  outline: none;
  padding: 0.5em;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .secound .my-form .choose-room .children select {
  color: var(--toot-color);
  border: none;
  outline: none;
  padding: 0.5em;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .save {
  display: flex;
  justify-content: center;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .save button {
  margin-top: 2em;
  background-color: var(--toot-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  width: 20%;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .save button:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  border: 1px solid var(--toot-color);
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card hr {
  margin-top: 1em;
}
.my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .add-room {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  margin-top: 1em;
  color: var(--toot-color);
}
.my-search-bar-hotel .container form.flight-form {
  margin-top: 2em;
}
.my-search-bar-hotel .container form.flight-form .head-flight {
  display: flex;
  gap: 10px;
  justify-content: center;
}
.my-search-bar-hotel .container form.flight-form .head-flight button {
  background-color: var(--background-color);
  color: var(--toot-color);
  border: none;
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 150px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.flight-form .head-flight button:hover {
  background-color: var(--toot-color);
  color: var(--background-color);
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.flight-form .content-flight {
  display: flex;
  align-items: center;
  margin-top: 1em;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search {
  display: flex;
  justify-content: center;
  gap: 5px;
  background-color: var(--toot-color);
  padding: 0.4em;
  border-radius: 6px;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search input, .my-search-bar-hotel .container form.flight-form .content-flight .search select {
  padding: 0.8em;
  border-radius: 6px;
  border: none;
  outline: none;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search .data-from {
  display: flex;
  background-color: var(--background-color);
  border-radius: 6px;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search .data-from span {
  color: var(--place-holder-color);
  font-size: 12px;
  margin: 8px 5px 0px 5px;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search .location {
  display: flex;
  background-color: var(--background-color);
  border-radius: 6px;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search .location input {
  width: 20em;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search .location select {
  width: 20em;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search .location i {
  color: var(--toot-color);
  margin: 10px 5px 0px 5px;
  font-size: 16px;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search .location i.fa-caret-down {
  color: var(--place-holder-color);
  font-size: 14px;
  margin: 11px 5px 0px 5px;
  cursor: pointer;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search .Guest {
  cursor: pointer;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search-icon {
  transform: translateX(-2em);
  margin-left: 4em;
  cursor: pointer;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search-icon button {
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--toot-color);
}
.my-search-bar-hotel .container form.flight-form .content-flight .search-icon button i {
  color: var(--background-color);
  background-color: var(--toot-color);
  padding: 0.5em;
  border-radius: 50%;
  font-size: 25px;
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.flight-form .content-flight .search-icon button i:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .head-multi-city {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
  padding: 1em 1em 0em;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .head-multi-city .left h3 {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
  color: var(--background-color);
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .head-multi-city hr {
  width: 80%;
  height: 2px;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .head-multi-city .right i {
  color: rgb(194, 194, 194);
  cursor: pointer;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search {
  display: flex;
  justify-content: center;
  gap: 5px;
  background-color: var(--toot-color);
  padding: 0.4em;
  border-radius: 6px;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search input, .my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search select {
  padding: 0.8em;
  border-radius: 6px;
  border: none;
  outline: none;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .data-from {
  display: flex;
  background-color: var(--background-color);
  border-radius: 6px;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .data-from span {
  color: var(--place-holder-color);
  font-size: 12px;
  margin: 8px 5px 0px 5px;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .location {
  display: flex;
  background-color: var(--background-color);
  border-radius: 6px;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .location input {
  width: 20em;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .location i {
  color: var(--toot-color);
  margin: 10px 5px 0px 5px;
  font-size: 16px;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .location i.fa-caret-down {
  color: var(--place-holder-color);
  font-size: 14px;
  margin: 11px 5px 0px 5px;
  cursor: pointer;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .Guest {
  cursor: pointer;
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .add-tripe {
  display: flex;
  justify-content: end;
  gap: 5px;
  margin: 0.5em;
  padding-bottom: 1em;
  color: var(--white-color);
}
.my-search-bar-hotel .container form.flight-form .this-multi-city .add-tripe i {
  cursor: pointer;
}
.my-search-bar-hotel .container form.flight-form .down-flight {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 1em;
}
.my-search-bar-hotel .container form.flight-form .down-flight button[type=submit] {
  background-color: #33A850;
  color: var(--background-color);
  border: none;
  outline: none;
  padding: 1em;
  border-radius: 6px;
  font-size: medium;
  font-weight: bold;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.flight-form .down-flight button[type=submit]:hover {
  background-color: var(--background-color);
  color: #33A850;
  transition: 0.3s ease;
}
.my-search-bar-hotel .container form.flight-form .down-flight select {
  background-color: var(--background-color);
  color: var(--toot-color);
  border-radius: 6px;
  outline: none;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  width: 150px;
  font-weight: 600;
}

/* 
    Start Media
*/
@media (max-width: 992px) {
  .my-search-bar-hotel .container form.hotel-form .content {
    flex-direction: column;
    justify-content: center;
  }
  .my-search-bar-hotel .container form.hotel-form .content .search {
    flex-direction: column-reverse;
    margin-left: 2em;
  }
  .my-search-bar-hotel .container form.hotel-form .content .search .data-from input {
    width: 100%;
  }
  .my-search-bar-hotel .container form.hotel-form .content .search .data-from span {
    width: 30px;
  }
  .my-search-bar-hotel .container form.hotel-form .content .search .location i {
    width: 26px;
  }
  .my-search-bar-hotel .container form.hotel-form .content .search .my-select {
    width: 100%;
  }
  .my-search-bar-hotel .container form.hotel-form .content .search .my-select i.fa-user {
    padding-right: 0 !important;
    margin-right: 0 !important;
    width: 20px !important;
  }
  .my-search-bar-hotel .container form.hotel-form .content .search-icon {
    margin-top: 1em;
  }
  .my-search-bar-hotel .container form.hotel-form .content .search-icon button {
    margin-left: 2em;
  }
  .my-search-bar-hotel .container form.flight-form .content-flight {
    flex-direction: column !important;
  }
  .my-search-bar-hotel .container form.flight-form .content-flight .search {
    flex-direction: column-reverse;
  }
  .my-search-bar-hotel .container form.flight-form .content-flight .search .data-from input {
    width: 100%;
  }
  .my-search-bar-hotel .container form.flight-form .content-flight .search .data-from span {
    width: 30px;
  }
  .my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search {
    flex-direction: column-reverse;
  }
  .my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .data-from input {
    width: 100%;
  }
  .my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .data-from span {
    width: 30px;
  }
  .my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .location {
    width: 100%;
  }
  .my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .my-select {
    width: 100%;
  }
  .my-search-bar-hotel .container form.flight-form .this-multi-city .new-tripe .search .my-select i.fa-user {
    padding-right: 0 !important;
    margin-right: 0 !important;
    width: 20px !important;
  }
}
@media (max-width: 767px) {
  .my-search-bar-hotel .container form.hotel-form .open-destinations .my-content {
    flex-direction: column;
    left: 0;
    background-color: transparent;
  }
  .my-search-bar-hotel .container form.hotel-form .open-other-select .my-content {
    justify-content: center;
    left: -100%;
  }
  .my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card {
    width: 60%;
  }
}
@media (max-width: 480px) {
  .my-search-bar-hotel .container form.hotel-form .open-destinations .my-content {
    width: 100%;
  }
  .my-search-bar-hotel .container form.hotel-form .open-destinations .my-content .card {
    width: 100%;
  }
  .my-search-bar-hotel .container form.hotel-form .open-other-select .my-content {
    left: 0%;
    top: 110%;
    width: 100%;
  }
  .my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card {
    width: 110%;
  }
  .my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .first {
    gap: 20px;
  }
  .my-search-bar-hotel .container form.hotel-form .open-other-select .my-content .card .secound {
    gap: 20px;
  }
  .my-search-bar-hotel .container form.flight-form {
    margin-top: 1em;
  }
  .my-search-bar-hotel .container form.flight-form .head-flight {
    gap: 5px;
  }
  .my-search-bar-hotel .container form.flight-form .head-flight button {
    width: 100px;
  }
  .my-search-bar-hotel .container form.flight-form .down-flight {
    gap: 5px;
  }
  .my-search-bar-hotel .container form.flight-form .down-flight button[type=submit] {
    padding: 0.5em;
  }
  .my-search-bar-hotel .container form.flight-form .down-flight select {
    width: 100px;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_search_bar_hotel.css.map */