:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
}

a {
  text-decoration: none;
}

.my-room-avaliable .success-message {
  background-color: rgba(0, 0, 0, 0.486);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-room-avaliable .success-message .my-massage {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.747);
  padding: 1em;
  border-radius: 1em;
  position: relative;
  width: 60%;
}
.my-room-avaliable .success-message .my-massage h1 {
  color: var(--background-color);
  margin-bottom: 2em;
}
.my-room-avaliable .success-message .my-massage .book-details {
  display: flex;
  justify-content: space-between;
  color: var(--background-color);
}
.my-room-avaliable .success-message .my-massage .book-details .left {
  text-align: left;
}
.my-room-avaliable .success-message .my-massage .book-details .right {
  text-align: right;
}
.my-room-avaliable .success-message .my-massage .book-details .right h2:nth-of-type(1) {
  color: var(--blue-color);
}
.my-room-avaliable .success-message .my-massage .book-details .right h2:nth-of-type(2) {
  color: #F23737;
}
.my-room-avaliable .success-message .my-massage hr {
  width: 80%;
  margin: 1em auto;
}
.my-room-avaliable .success-message .my-massage h2.final {
  margin: 2em 0;
  color: #33A850;
}
.my-room-avaliable .success-message .my-massage h3 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}
.my-room-avaliable .success-message .my-massage h3 i {
  color: white;
  font-size: 40px;
  margin: 1em 0;
}
.my-room-avaliable .success-message .my-massage button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}
.my-room-avaliable .success-message .my-massage button:hover {
  background-color: #33A850;
  color: var(--background-color);
  border: 1px solid var(--background-color);
  transition: 0.3s ease;
}
.my-room-avaliable .success-message .my-massage i.fa-circle-xmark {
  position: absolute;
  right: 1%;
  top: 1%;
  color: white;
  font-size: 30px;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-room-avaliable .success-message .my-massage i.fa-circle-xmark:hover {
  color: rgb(122, 122, 122);
  transition: 0.3s ease;
}
.my-room-avaliable .container .top {
  margin: 0 2em;
}
.my-room-avaliable .container .top .head {
  text-align: right;
  padding-top: 7.5em;
}
.my-room-avaliable .container .top .head h1 {
  color: var(--toot-color);
}
.my-room-avaliable .container .top .head .my-img {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 1em;
}
.my-room-avaliable .container .top .head .my-img img {
  width: 40px;
}
.my-room-avaliable .container .top .head .my-img h4 {
  color: var(--toot-color);
}
.my-room-avaliable .container .top .head hr {
  width: 50%;
  margin-left: auto;
  height: 3px;
  background-color: var(--toot-color);
}
.my-room-avaliable .container .top .head img {
  width: 100px;
}
.my-room-avaliable .container .top .content {
  display: flex;
  align-items: start;
  gap: 10px;
  width: 100%;
}
.my-room-avaliable .container .top .content .left {
  width: 25%;
}
.my-room-avaliable .container .top .content .left .top {
  border: 1px solid var(--blue-color);
  box-shadow: 0px 0px 5px rgba(145, 145, 145, 0.623);
  border-radius: 6px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}
.my-room-avaliable .container .top .content .left .top .comment h4 {
  text-align: center;
  margin-bottom: 0.5em;
  color: var(--toot-color);
}
.my-room-avaliable .container .top .content .left .top .comment .my-avatar {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.my-room-avaliable .container .top .content .left .top .comment .my-avatar img {
  width: 50px;
  mix-blend-mode: multiply;
}
.my-room-avaliable .container .top .content .left .top .comment .my-avatar h5 {
  color: var(--toot-color);
}
.my-room-avaliable .container .top .content .left .top .comment p {
  text-align: right;
}
.my-room-avaliable .container .top .content .left .top hr {
  margin: 1em;
}
.my-room-avaliable .container .top .content .left .my-map {
  margin-top: 1em;
}
.my-room-avaliable .container .top .content .left .my-map img {
  width: 100%;
  height: 260px;
  border-radius: 6px;
}
.my-room-avaliable .container .top .content .right {
  width: 75%;
  cursor: pointer;
}
.my-room-avaliable .container .top .content .right .big-img {
  display: flex;
}
.my-room-avaliable .container .top .content .right .big-img .img-main img {
  width: 580px;
}
.my-room-avaliable .container .top .content .right .big-img .img-secound img {
  width: 390px;
}
.my-room-avaliable .container .top .content .right .small-img {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
.my-room-avaliable .container .top .content .right .small-img .more-img {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  text-align: center;
  color: var(--white-color);
}
.my-room-avaliable .container .top .content .right .small-img img {
  width: 200px;
}
.my-room-avaliable .container .top .content .right .small-img img:nth-of-type(1) {
  filter: brightness(0.5);
}
.my-room-avaliable .container .top .more-img-small-screen {
  text-align: center;
}
.my-room-avaliable .container .top .more-img-small-screen button {
  padding: 0.5em;
  background-color: var(--blue-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  transition: 0.3s ease;
  font-weight: bold;
}
.my-room-avaliable .container .top .more-img-small-screen button:hover {
  background-color: var(--background-color);
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
  transition: 0.3s ease;
}
.my-room-avaliable .container .top .room-filter {
  text-align: center;
  background-color: var(--white-color);
  padding: 1em;
}
.my-room-avaliable .container .top .room-filter h2 {
  color: var(--toot-color);
}
.my-room-avaliable .container .top .room-filter .filter {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 2em;
  margin-bottom: 1em;
}
.my-room-avaliable .container .top .room-filter .filter h4 {
  color: var(--toot-color);
  border: 1px solid var(--blue-color);
  width: -moz-max-content;
  width: max-content;
  padding: 0.5em;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-room-avaliable .container .top .room-filter .filter h4:hover {
  background-color: #E2F1FF;
  transition: 0.3s ease;
}
.my-room-avaliable .container .down .my-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 4em;
}
.my-room-avaliable .container .down .my-main .card {
  width: 300px;
  background-color: var(--white-color);
  border: 1px solid var(--blue-color);
  border-radius: 6px;
}
.my-room-avaliable .container .down .my-main .card .mian-img {
  position: relative;
}
.my-room-avaliable .container .down .my-main .card .mian-img img {
  width: 100%;
  border-radius: 6px;
}
.my-room-avaliable .container .down .my-main .card .mian-img .my-arrow {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.my-room-avaliable .container .down .my-main .card .mian-img .my-arrow i {
  color: var(--white-color);
  margin-left: 0.1em;
  cursor: pointer;
}
.my-room-avaliable .container .down .my-main .card .mian-img .my-arrow i:nth-of-type(2) {
  transform: translateX(1600%);
}
.my-room-avaliable .container .down .my-main .card .mian-img .my-number {
  display: flex;
  gap: 5px;
  background-color: rgba(0, 0, 0, 0.801);
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.3em;
  position: absolute;
  bottom: 1%;
}
.my-room-avaliable .container .down .my-main .card .mian-img .my-number p {
  font-size: 10px;
  color: var(--white-color);
}
.my-room-avaliable .container .down .my-main .card h3 {
  color: var(--toot-color);
  margin: 1em 0;
  text-align: center;
}
.my-room-avaliable .container .down .my-main .card .details p {
  display: flex;
  gap: 5px;
  justify-content: end;
  align-items: center;
  margin: 0.5em;
}
.my-room-avaliable .container .down .my-main .card .details p i {
  color: var(--blue-color);
}
.my-room-avaliable .container .down .my-main .card .my-select {
  display: flex;
  justify-content: space-around;
}
.my-room-avaliable .container .down .my-main .card .my-select select {
  text-align: center;
  outline: none;
  border: 1px solid var(--blue-color);
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  margin: 1em 0;
}
.my-room-avaliable .container .down .my-main .card h4 {
  color: var(--blue-color);
  text-align: center;
  margin: 1em 0;
}
.my-room-avaliable .container .down .more-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 4em;
}
.my-room-avaliable .container .down .more-option .my-option {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #BCBCBC;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5em;
  border-radius: 6px;
}
.my-room-avaliable .container .down .more-option .my-option i {
  color: #3B3B3B;
  font-size: 18px;
}
.my-room-avaliable .container .book-now {
  text-align: center;
}
.my-room-avaliable .container .book-now button {
  padding: 0.5em;
  background-color: var(--blue-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 3em;
  width: 30%;
  transition: 0.3s ease;
  font-weight: bold;
  font-size: 18px;
  margin: 4em auto;
}
.my-room-avaliable .container .book-now button:hover {
  background-color: var(--background-color);
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
  transition: 0.3s ease;
}

/* 
  Start Media
*/
@media (min-width: 767px) {
  .more-img-small-screen {
    display: none;
  }
}
@media (max-width: 767px) {
  .my-room-avaliable .success-message .my-massage {
    width: 90%;
  }
  .my-room-avaliable .container .top {
    margin: 0 0;
  }
  .my-room-avaliable .container .top .head {
    text-align: center;
  }
  .my-room-avaliable .container .top .head .my-img {
    justify-content: center;
  }
  .my-room-avaliable .container .top .head hr {
    margin: 0 auto;
  }
  .my-room-avaliable .container .top .content {
    flex-direction: column;
  }
  .my-room-avaliable .container .top .content .left {
    display: flex;
    gap: 5px;
    width: 100%;
  }
  .my-room-avaliable .container .top .content .left .my-map {
    margin-top: 0;
  }
  .my-room-avaliable .container .top .content .right {
    width: 100%;
  }
  .my-room-avaliable .container .top .content .right .big-img .img-main img {
    width: 200px;
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-left: 10px;
  }
  .my-room-avaliable .container .top .content .right .big-img .img-secound img {
    width: 200px;
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-left: 10px;
  }
  .my-room-avaliable .container .top .content .right .small-img {
    flex-wrap: wrap;
  }
  .my-room-avaliable .container .top .content .right .small-img .more-img {
    display: none;
  }
  .my-room-avaliable .container .top .content .right .small-img img {
    width: 200px;
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-left: 10px;
  }
  .my-room-avaliable .container .top .content .right .small-img img:nth-of-type(1) {
    filter: brightness(1);
  }
  .my-room-avaliable .container .down .more-option {
    flex-wrap: wrap;
  }
  .my-room-avaliable .container .book-now button {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .last-img {
    display: none;
  }
  .success-message .my-massage h1 {
    margin-bottom: 0.5em !important;
  }
  .success-message .my-massage .book-details {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .success-message .my-massage .book-details .left, .success-message .my-massage .book-details .right {
    text-align: center !important;
  }
  .success-message .my-massage .book-details .right h2 {
    font-size: 18px;
  }
  .success-message .my-massage button {
    width: 50% !important;
  }
  .my-room-avaliable .container .top .head {
    text-align: center;
  }
  .my-room-avaliable .container .top .head .my-img {
    justify-content: center;
  }
  .my-room-avaliable .container .top .head hr {
    margin: 0 auto;
  }
  .my-room-avaliable .container .top .content {
    flex-direction: column;
  }
  .my-room-avaliable .container .top .content .left {
    display: flex;
    gap: 5px;
    width: 100%;
  }
  .my-room-avaliable .container .top .content .left .my-map {
    margin-top: 0;
  }
  .my-room-avaliable .container .top .content .right {
    width: 100%;
    margin: 0 auto;
  }
  .my-room-avaliable .container .top .content .right .big-img {
    justify-content: center;
    margin: 0 auto;
  }
  .my-room-avaliable .container .top .content .right .big-img .img-main img {
    width: 150px;
    height: 150px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-left: 10px;
  }
  .my-room-avaliable .container .top .content .right .big-img .img-secound img {
    width: 150px;
    height: 150px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-left: 10px;
  }
  .my-room-avaliable .container .top .content .right .small-img {
    justify-content: center;
    flex-wrap: wrap;
  }
  .my-room-avaliable .container .top .content .right .small-img .more-img {
    display: none;
  }
  .my-room-avaliable .container .top .content .right .small-img img {
    width: 150px;
    height: 150px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-left: 10px;
  }
  .my-room-avaliable .container .top .content .right .small-img img:nth-of-type(1) {
    filter: brightness(1);
  }
  .my-room-avaliable .container .top .room-filter .filter {
    flex-wrap: wrap;
  }
}
/* 
  End Media
*//*# sourceMappingURL=style_room_avaliable.css.map */