:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }

//   body {
//     height: 2000px;
//   }



  .information-for-flight {
    .container {
        .first {
            padding-top: 8em;

            .tripe-info {
                display: flex;
                align-items: center;
                justify-content: end;
                gap: 15px;
    
                //padding-top: 8em;
                margin: 1em 2em;
    
                h4 {
                    color: var(--blue-color);
                }
    
                span {
                    width: 1px;
                    height: 20px;
                    background-color: var(--blue-color);
                    display: inline-block;
                }
    
                h4.padge {
                    background-color: var(--blue-color);
                    color: var(--white-color);
                    padding: 0.5em;
                    border-radius: 6px;
                    width: 60px;
                }
            }
        }

        .pakage-in-booking {

            background-color: var(--white-color);
            padding: 1em;

            h2 {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                color: var(--toot-color);
            }

            .all-pakage {
                display: flex;
                justify-content: space-around;
                margin-top: 2em;

                .pakage {
                    width: 300px;
                    margin: 1em 0;
                    //background-color: gray;
    
                    .content {
                        display: flex;
                        flex-direction: column;
                        align-items: end;
    
                        .head {
                            display: flex;
                            align-items: center;
                            gap: 10px;
    
                            color: var(--blue-color);
    
                            h4.padge {
                                background-color: #C7E1FB;
                                padding: 0.5em;
                                border-radius: 6px;
                            }
                        }
    
                        .your-pakage {
                            display: flex;
                            gap: 10px;
    
                            margin-top: 1em;
    
                            .left {
                                display: flex;
                                flex-direction: column;
                                align-items: end;
    
                                h4 {
                                    color: var(--toot-color);
                                }
    
                                p.red {
                                    color: #DF2040;
                                    font-size: 14px;
                                }
    
                                .my-span {
                                    display: flex;
                                    direction: rtl;
    
                                    span {
                                        font-size: 14px;
                                        color: #A5A5A5;
                                    }
                                }
                            }
    
                            .right {
                                i {
                                    color: var(--toot-color);
                                    font-size: 18px;
                                }
                            }
                        }
    
                        .add-pakage {
                            //margin: 1.5em auto;
                            margin-top: 1.5em;
                            text-align: center;
                            button {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 10px;
    
                                background-color: #C7E1FB;
                                color: var(--blue-color);
                                border: 1px solid #C7E1FB;
                                outline: none;
                                padding: 0.5em;
                                border-radius: 6px;
                                width: 240px;
                                font-size: medium;
                                font-weight: 700;
                                cursor: pointer;
                                transition: 0.3s ease;
            
                                &:hover {
                                    background-color: var(--blue-color);
                                    color: #C7E1FB;
                                    transition: 0.3s ease;
                                }
                            }
                        }
                    }
                }

                span.center {
                    width: 2px;
                    height: 300px;
                    background-color: var(--blue-color);
                    display: inline-block;
                }
            }
        }



        .content {
            .info-flight {
                //width: 70%;

                .my-head {
                    background-color: #F38585;
                    padding: 1em;
                    border-radius: 6px;

                    h2 {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        justify-content: end;

                        color: var(--background-color);

                        a {
                            text-decoration: underline;
                            color: black;
                        }
                    }
                }

                form.form-for-flight {
                    margin-top: 1em;
                    padding: 1em;
                    background-color: var(--white-color);

                    h2 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        margin-top: 1em;
                        margin-bottom: 1em;
        
                        color: var(--toot-color);

                        i {
                            font-size: 30px;
                        }
                    }

                    .scane {
                        margin-top: 1.5em;
                        margin-bottom: 1em;
                        text-align: center;
                        button {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;

                            background-color: #C7E1FB;
                            color: var(--blue-color);
                            border: 1px solid #C7E1FB;
                            outline: none;
                            padding: 0.5em;
                            border-radius: 6px;
                            width: 240px;
                            margin: 0 auto;
                            font-size: medium;
                            font-weight: 700;
                            cursor: pointer;
                            transition: 0.3s ease;
        
                            &:hover {
                                background-color: var(--blue-color);
                                color: #C7E1FB;
                                transition: 0.3s ease;
                            }
                        }
                    }

                    .my-body {
                        display: flex;
                        justify-content: space-around;

                        .my-left {
                            display: flex;
                            flex-direction: column;
                            align-items: end;
    
                            input {
                                padding: 0.5em;
                                border-radius: 6px;
                                outline: none;
                                border: 1px solid #C8C7CC;
                                margin-top: 10px;
                                width: 250px;
                                direction: rtl;
    
                                &::placeholder {
                                    text-align: right;
                                    
                                }
                            }
                        }
    
                        .my-right {
                            display: flex;
                            flex-direction: column;
                            justify-content: end;
                            align-items: end;
    
                            .part {
                                display: flex;
                                flex-direction: column;
                                align-items: end;
    
                                &:not(:nth-child(1)) {
                                    margin-top: 2em;
                                }
    
                                input {
                                    padding: 0.5em;
                                    border-radius: 6px;
                                    outline: none;
                                    border: 1px solid #C8C7CC;
                                    margin-top: 10px;
                                    width: 250px;
                                    direction: rtl;
        
                                    &::placeholder {
                                        text-align: right;
                                    }
                                }

                                .my-radio {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;

                                    input {
                                        width: fit-content;
                                    }
                                }
    
                                p {
                                    font-size: 14px;
                                }

                                select {
                                    width: 250px;
                                    padding: 0.5em;
                                    direction: rtl;
                                    border-radius: 6px;
                                    outline: none;
                                    margin-top: 0.5em;
                                    border: 1px solid #C8C7CC;
                                }
                            }

                            // تخصيص المظهر للمكون react-select
                            .nationality {
                                label {
                                    margin-bottom: 0.5em;
                                }

                                .my-Select {
                                    width: 250px;
                                    border-radius: 6px;
                                    direction: rtl;
                                }
                                
                            }

                            // .my-HijriDatePicker {
                            //     تخصيص المظهر لعنصر إدخال التاريخ الهجري
                            //     .react-datepicker-wrapper {
                            //         width: 100%;
                            //     }
                                
                            //     .react-datepicker__input-container input {
                            //         width: 100%;
                            //         padding: 10px;
                            //         border: 1px solid #ccc;
                            //         border-radius: 4px;
                            //         font-size: 16px;
                            //     }
                                
                            //     .react-datepicker__input-container input:focus {
                            //         border-color: #aaa;
                            //     }
  
                            // }
    
                            // hr {
                            //     width: 230%;
                            //     margin-top: 1em;
                            //     height: 2px;
                            //     background-color: #E4E4E4;
                            //     border: none;
                            // }
    
                            .check {
                                display: flex;
                                flex-direction: column;
                                align-items: end;
    
                                margin-top: 2em;
    
                                .main {
                                    display: flex;
                                    gap: 5px;
                                }
    
                                .more-info {
                                    display: flex;
                                    flex-direction: column;
    
                                    input {
                                        padding: 0.5em;
                                        border-radius: 6px;
                                        outline: none;
                                        border: 1px solid #C8C7CC;
                                        margin-top: 10px;
                                        width: 250px;
                                        direction: rtl;
            
                                        &::placeholder {
                                            text-align: right;
                                        }
                                    }
                                }
                            }
    
                            
                        }
                    }

                    hr {
                        width: 80%;
                        margin: 1em auto;
                        height: 2px;
                        background-color: #E4E4E4;
                        border: none;
                    }

                    .passnger {
                        display: flex;
                        justify-content: space-between;

                        background-color: var(--toot-color);
                        color: var(--white-color);
                        padding: 1em;
                        margin: 2em 0;
                        cursor: pointer;

                        .passenger-number {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                        }
                    }

                    .total-price {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
            
                        //text-align: center;
                        width: 500px;
                        margin: 2em auto;
                        background-color: #E2F1FF;
                        padding: 1em;
            
                        button {
                            display: flex;
                            align-items: center;
                            gap: 10px;
            
                            background-color: var(--blue-color);
                            color: var(--white-color);
                            border: 1px solid var(--blue-color);
                            outline: none;
                            padding: 0.5em;
                            border-radius: 6px;
                            //width: 100%;
                            font-size: medium;
                            font-weight: 700;
                            cursor: pointer;
                            transition: 0.3s ease;
            
                            &:hover {
                                background-color: var(--white-color);
                                color: var(--blue-color);
                                transition: 0.3s ease;
                            }
                        }
                    }
                    
                    
                }
            }
        }
    }
  }



  /* 
    Start Media
  */
@media(max-width: 767px) {
    .information-for-flight {
        .container {
            .pakage-in-booking {
                .all-pakage {
                    flex-direction: column;
                    align-items: end;

                    margin: 2em 1em;

                    span.center {
                        height: 2px;
                        width: 85%;
                        margin: 0 auto;
                    }
                }
            }

        }
    }
}




@media(min-width: 480px) {
    .hide-in-large {
        display: none !important;
    }
}



@media(max-width: 480px) {
    .information-for-flight {
        .container {
            
            .content {
                .info-flight {
                    form.form-for-flight  {
                        .my-body {
                            flex-direction: column-reverse;

                            .my-left {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}






   /* 
    End Media
  */