:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
}

.my-profile-page .container .profile-content img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.my-profile-page .container .profile-content .head {
  background-color: var(--white-color);
  box-shadow: 3px 3px 3px rgba(75, 75, 75, 0.26);
  transform: translateY(-2em);
}
.my-profile-page .container .profile-content .head .top {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 10px;
}
.my-profile-page .container .profile-content .head .top img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 10em;
  transform: translateY(-2em);
  box-shadow: 0px 0px 10px rgba(75, 75, 75, 0.26);
}
.my-profile-page .container .profile-content .head .top label {
  transform: translateX(1em);
  cursor: pointer;
}
.my-profile-page .container .profile-content .head .top label i {
  color: var(--toot-color);
}
.my-profile-page .container .profile-content .head .top .my-name input {
  padding: 0.5em;
  border-radius: 6px;
  border: 1px solid var(--toot-color);
  outline: none;
}
.my-profile-page .container .profile-content .head .top .my-name h1 {
  color: var(--toot-color);
}
.my-profile-page .container .profile-content .head .top .my-name button {
  padding: 0.5em;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: var(--toot-color);
  color: var(--background-color);
  cursor: pointer;
  margin: 0.5em;
}
.my-profile-page .container .profile-content .head .down {
  display: flex;
  gap: 0.5em;
  justify-content: center;
  flex-direction: row-reverse;
}
.my-profile-page .container .profile-content .head .down .my-account::after {
  content: "";
  width: 50px;
  height: 2px;
  background-color: var(--toot-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}
.my-profile-page .container .profile-content .head .down a {
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: 0.3s;
  position: relative;
  color: var(--toot-color);
  text-decoration: none;
}
.my-profile-page .container .profile-content .head .down a:hover {
  transition: 0.3s;
}
.my-profile-page .container .profile-content .head .down a:hover::after {
  content: "";
  animation: widthNav 0.5s forwards;
  height: 2px;
  background-color: var(--toot-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}
.my-profile-page .container .profile-content .my-email {
  text-align: center;
}
.my-profile-page .container .profile-content .my-email .strong {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}
.my-profile-page .container .profile-content .my-email .strong p {
  background-color: var(--white-color);
  padding: 0.5em;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(75, 75, 75, 0.26);
  display: flex;
  gap: 10px;
  align-items: center;
}
.my-profile-page .container .profile-content .my-email .strong p i {
  color: var(--toot-color);
}

@keyframes widthNav {
  from {
    width: 0;
  }
  to {
    width: 50px;
  }
}
/* 
    Start Media
*/
@media (max-width: 480px) {
  .my-profile-page .container .profile-content .head .top {
    flex-direction: column;
    justify-content: center;
  }
  .my-profile-page .container .profile-content .head .top img {
    margin-right: 0;
    margin-top: 2em;
    transform: translateY(0em);
  }
  .my-profile-page .container .profile-content .head .top label {
    transform: translateY(-0.6em);
  }
  .my-profile-page .container .profile-content .head .top .my-name {
    text-align: center;
  }
  .my-profile-page .container .profile-content .head .down {
    flex-direction: column;
    text-align: center;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_profile_page.css.map */