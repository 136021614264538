:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
}

.my-reset-password {
  background-image: url(../images/login_and_signup.png);
  background-position: center;
  height: 100vh;
  text-align: center;
}
.my-reset-password h1, .my-reset-password h3 {
  color: var(--background-color);
}
.my-reset-password h1 {
  margin-bottom: 1em;
  padding-top: 6em;
}
.my-reset-password form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}
.my-reset-password form input {
  width: 100%;
  margin-left: auto;
  margin-bottom: 2em;
  text-align: right;
  padding: 0.9em;
  border: none;
  outline: none;
  background-color: #68738B;
  border-radius: 6px;
  color: var(--background-color);
}
.my-reset-password form input::-moz-placeholder {
  color: rgb(184, 184, 184);
}
.my-reset-password form input::placeholder {
  color: rgb(184, 184, 184);
}
.my-reset-password form button[type=submit] {
  padding: 0.5em;
  outline: none;
  width: 40%;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease;
  background-color: var(--toot-color);
  margin-left: auto;
  margin-right: auto;
  border: none;
  color: var(--background-color);
}
.my-reset-password form button[type=submit]:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  transition: 0.3s ease;
}

/* 
  Start Media
*/
@media (max-width: 767px) {
  .my-reset-password form {
    width: 70%;
  }
}
@media (max-width: 480px) {
  .my-reset-password form {
    width: 80%;
  }
  .my-reset-password form button[type=submit] {
    width: 60%;
    font-size: 16px;
  }
}
/* 
  End Media
*//*# sourceMappingURL=style_reset_password.css.map */