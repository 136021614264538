:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
  }


  .my-profile-page {
        // background-image: url(../images/cover.png);
        // height: 300px;
        // background-position: center;
        //background-size: cover;
    .container {
        .profile-content {
            img {
                width: 100%;
                object-fit: cover;
            }

            .head {
                background-color: var(--white-color);
                box-shadow: 3px 3px 3px  rgba(75, 75, 75, 0.26);
                transform: translateY(-2em);

                .top {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    gap: 10px;

                    img {
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                        margin-right: 10em;
                        transform: translateY(-2em);
                        box-shadow: 0px 0px 10px  rgba(75, 75, 75, 0.26);
                    }
    
                    label {
                        transform: translateX(1em);
                        cursor: pointer;
                        i {
                            color: var(--toot-color);
                        }
                    }
    
                    

                    .my-name {
                        input {
                            padding: 0.5em;
                            border-radius: 6px;
                            border: 1px solid var(--toot-color);
                            outline: none;
                        }

                        h1 {
                            color: var(--toot-color);
                            //margin-bottom: 0.5em;
                        }

                        button {
                            padding: 0.5em;
                            border-radius: 6px;
                            border: none;
                            outline: none;
                            background-color: var(--toot-color);
                            color: var(--background-color);
                            cursor: pointer;
                            margin: 0.5em;
                        }
                    }
                }

                .down {
                    display: flex;
                    gap: 0.5em;
                    justify-content: center;
                    flex-direction: row-reverse;
                    
                    .my-account {
                        &::after {
                            content: '';
                            width: 50px;
                            height: 2px;
                            background-color: var(--toot-color);
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            top: 100%;   
                        }     
                    }
        
                    a {
                        padding: 10px;
                        cursor: pointer;
                        font-size: 18px;
                        font-weight: bold;
                        transition: 0.3s;
                        position: relative;
                        color: var(--toot-color);
                        text-decoration: none;
                        
                        // &:first-of-type {
                        //     color: var(--main-red-color);
                        // }
        
                        &:hover {
                            //color: var(--main-red-color);
                            transition: 0.3s;
        
                            &::after {
                                content: '';
                                //width: 40px;
                                animation: widthNav 0.5s forwards;
                                height: 2px;
                                background-color: var(--toot-color);
                                position: absolute;
                                //left: 10px;
                                left: 50%;
                                transform: translateX(-50%);
                                top: 100%;   
                            }   
                        }   
                    }
                    
                }
            }

            .my-email {
                //display: flex;
                text-align: center;

                .strong {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    align-items: center;

                    p {
                        background-color: var(--white-color);
                        padding: 0.5em;
                        border-radius: 6px;
                        box-shadow: 0px 0px 10px  rgba(75, 75, 75, 0.26);
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        
                        i {
                            color: var(--toot-color);
                        }
                    }
                }
            }
            
        }
        
    }
  }



  @keyframes widthNav {
    from {
        width: 0;
    }
    to {
        width: 50px;
        
    }
}









/* 
    Start Media
*/
@media(max-width: 480px) {
    .my-profile-page {
        .container {
            .profile-content {
                .head {
                    .top {
                        flex-direction: column;
                        justify-content: center;

                        img {
                            margin-right: 0;
                            margin-top: 2em;
                            transform: translateY(0em);
                        }

                        label {
                            transform: translateY(-0.6em);
                        }

                        .my-name {
                            text-align: center;
                        }
                    }

                    .down {
                        flex-direction: column;
                        text-align: center;
                    }
                }
            }
        }
    }
}





/* 
    End Media
*/