:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }


.my-discound-dashboard {
    .container {
        form.generate-code {
            margin-top: 2em;

            // Public input for all inputs inside form
            input {
              padding: 0.8em;
              direction: rtl;
              border-radius: 6px;
              border: 1px solid #C8C7CC;
              width: 300px;
              outline: none;
            }

            // Public select for all slescts inside form
            select {
              //margin: 1em 0;
              background-color: var(--white-color);
              border: 1px solid #C8C7CC;
              padding: 0.5em;
              border-radius: 6px;
              width: 300px;
              direction: rtl;
              cursor: pointer;
              outline: none;
          }

            .my-first {
              display: flex;
              flex-direction: column;
              gap: 10px;
              align-items: center;
              justify-content: center;             
            }

            .discount-ditails {
              display: flex;
              align-items: center;
              justify-content: space-around;

              margin-top: 2em;

              .discount-left {
                display: flex;
                flex-direction: column;
                align-items: end;
                gap: 20px;

                .paket {
                  display: flex;
                  flex-direction: column;
                  align-items: end;
                  gap: 10px;
                }
              }

              .discount-right {
                display: flex;
                flex-direction: column;
                align-items: end;
                gap: 20px;

                .paket-right {
                  display: flex;
                  flex-direction: column;
                  align-items: end;
                  gap: 10px;
                }
              }
            }

            
            .btn-sub {
              display: flex;
              justify-content: center;
              
              margin-top: 2em;

              button {
                background-color: var(--blue-color);
                color: var(--white-color);
                border: 1px solid var(--blue-color);
                outline: none;
                padding: 0.5em;
                border-radius: 6px;
                width: 300px;
                font-size: medium;
                font-weight: 700;
                cursor: pointer;
                transition: 0.3s ease;
                //margin-left: auto;
                // text-align: right;
                // display: inline-block;
        
                &:hover {
                    background-color: var(--white-color);
                    color: var(--blue-color);
                    transition: 0.3s ease;
                }
              }
            }

            
        }

        .head-content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
      
            margin-top: 4em;
            margin-bottom: 4em;
      
            span {
              width: 350px;
              height: 2px;
              background-color: var(--toot-color);
              display: inline-block;
            }
      
            h2 {
              color: var(--toot-color);
            }
        }

        .curent-code {
            display: flex;
            flex-direction: column;
            align-items: end;

            margin-top: 2em;

            .this-code {
                display: flex;
                gap: 10px;

                .btn {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  i {
                    color: #33A850;
                  }

                  button {
                    //background-color: var(--blue-color);
                    color: var(--white-color);
                    border: none;
                    outline: none;
                    padding: 0.5em;
                    border-radius: 6px;
                    width: 100px;
                    font-size: 14px;
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.3s ease;
                  }
        
                  button.delete {
                    background-color: #E74133;
                    border: 1px solid #E74133;
        
                    &:hover {
                      background-color: var(--white-color);
                      color: var(--blue-color);
                      transition: 0.3s ease;
                    }
                  }


                  button.copy {
                    background-color: #FED415;
                    border: 1px solid #FED415;
                    transition: 0.3s ease;
        
                    &:hover {
                      background-color: var(--white-color);
                      color: var(--blue-color);
                      transition: 0.3s ease;
                    }
                  }


                  button.edit {
                    background-color: #33A850;
                    border: 1px solid #33A850;
                    transition: 0.3s ease;
        
                    &:hover {
                      background-color: var(--white-color);
                      color: var(--blue-color);
                      transition: 0.3s ease;
                    }
                  }
                }

                

                input {
                    padding: 0.8em;
                    direction: rtl;
                    border-radius: 6px;
                    border: 1px solid #C8C7CC;
                    width: 300px;
                    outline: none;
                  }
            }

            p {
                font-size: 12px;
            }
        }
    }
}



/* 
  Start Media
*/
@media(max-width: 767px) {
  .my-discound-dashboard {
    .container {
      form.generate-code {
        .my-first {
          align-items: end;
        }

        .discount-ditails {
          flex-direction: column;
          align-items: end;
        }

        .btn-sub {
          justify-content: end;
        }
      }
    }
  }
}




@media(max-width: 480px) {
  .my-discound-dashboard {
    .container {
      .head-content {
        gap: 10px;

        span {
          width: 100px;
        }

        h2 {
          font-size: 16px;
        }
      }

      .curent-code {
        .this-code {
          flex-wrap: wrap;
          flex-direction: column-reverse;
          align-items: end;
        }
      }
    }
  }
}



/* 
  End Media
*/