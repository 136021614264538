:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.my-who-we-are .container .hero {
  background-image: url(../../images/who_we_are.png);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.my-who-we-are .container .hero h1 {
  padding-top: 17em;
  color: var(--background-color);
  margin: 0 2em 0.5em;
}
.my-who-we-are .container .hero hr {
  width: 50%;
  margin: 0 4em;
}
.my-who-we-are .container .content {
  background-color: var(--white-color);
  width: 95%;
  margin: 2em auto;
  border-radius: 6px;
}
.my-who-we-are .container .content .vision {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 2em;
  margin-top: 2em;
}
.my-who-we-are .container .content .vision h2 {
  color: var(--toot-color);
  margin-bottom: 1em;
}
.my-who-we-are .container .content .vision .text {
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
}
.my-who-we-are .container .content .vision .text img {
  width: 25%;
  position: absolute;
  top: -90%;
}
.my-who-we-are .container .content .vision .text p.behinde-img {
  width: 60%;
  margin-left: auto;
}
.my-who-we-are .container .content .vision .text p {
  text-align: right;
  color: #3B3B3B;
  margin-top: 1em;
}
.my-who-we-are .container .content .vision .text i {
  font-size: 30px;
  color: var(--toot-color);
}
.my-who-we-are .container .content .vision img.hide-large-screen {
  width: 40%;
  margin: 2em auto;
}

/* 
    Start Media
*/
@media (min-width: 767px) {
  .my-who-we-are .container .content .vision img.hide-large-screen {
    display: none;
  }
}
@media (max-width: 767px) {
  .my-who-we-are .container .content .vision .text img {
    display: none;
  }
  .my-who-we-are .container .content .vision .text p.behinde-img {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .my-who-we-are .container .hero h1 {
    padding-top: 12em;
    text-align: center;
    margin: 0 auto;
  }
  .my-who-we-are .container .hero hr {
    width: 80%;
    margin: 1em auto;
  }
  .my-who-we-are .container .content .vision img.hide-large-screen {
    width: 70%;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_who_we_are.css.map */