:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --place-holder-color: #aaaaaa;
} 

// Public Class:-
.active-for-flight-search-bar {
    background-color: var(--toot-color) !important;
    color: var(--background-color) !important;
}


.my-search-bar-hotel {
    .container {
        .top {
            display: flex;
            justify-content: center;
            gap: 5px;

            button {
                padding: 0.8em;
                outline: none;
                width: 8em;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;
                border-radius: 6px;
                transition: 0.3s ease;

                i {
                    margin-right: 5px;
                    margin-left: 5px;
                }
            }

            button.Hotel {
                background-color: var(--toot-color);
                border: 2px solid var(--toot-color);
                color: var(--background-color);

                &:hover {
                    background-color: var(--toot-color) !important;
                    transition: 0.3s ease;
                }
            }

            button.Flight {
                background-color: transparent;
                border: 2px solid var(--toot-color);
                color: var(--background-color);

                &:hover {
                    background-color: var(--toot-color);
                    transition: 0.3s ease;
                }
            }
        }

        form.hotel-form {
            
            .content {
                display: flex;
                align-items: center;
                //justify-content: space-around;
                //gap: 2em;
    
                margin-top: 4em;
                left: 50%;
                transform: translateX(-50%);
                
                .search {
                    display: flex;
                    justify-content: center;
                    //align-items: center;
                    gap: 5px;
    
                    background-color: var(--toot-color);
                    padding: 0.4em;
                    border-radius: 6px;
    
                    input, select {
                        padding: 0.8em;
                        border-radius: 6px;
                        border: none;
                        outline: none;
                        // To Arabic
                        //direction: rtl;
                    }
    
                    
    
                    .data-from {
                        display: flex;
                        background-color: var(--background-color);
                        border-radius: 6px;
    
                        span {
                            color: var(--place-holder-color);
                            font-size: 12px;
                            margin: 8px 5px 0px 5px;
                        }
                    }
    
                    .location {
                        display: flex;
                        background-color: var(--background-color);
                        border-radius: 6px;
    
                        input {
                            width: 20em;
                        }
    
                        select {
                            width: 20em;
                        }
    
                        i {
                            color: var(--toot-color);
                            margin: 10px 5px 0px 5px;
                            font-size: 16px;
                        }
    
                        i.fa-caret-down {
                            color: var(--place-holder-color);
                            font-size: 14px;
                            margin: 11px 5px 0px 5px;
                            cursor: pointer;
                        }
                    }
    
                    .Guest {
                        cursor: pointer;
                    }
                }
    
                .search-icon {
                    transform: translateX(-2em);
                    margin-left: 4em;
                    cursor: pointer;
    
                    button {
                        border-radius: 50%;
                        cursor: pointer;
                        border: none;
                        outline: none;
                        background-color: var(--toot-color);
                        
    
                        i {
                            color: var(--background-color);
                            background-color: var(--toot-color);
                            padding: 0.5em;
                            border-radius: 50%;
                            font-size: 25px;
                            transition: 0.3s ease;
    
                            &:hover {
                                background-color: var(--background-color);
                                color: var(--toot-color);
                                transition: 0.3s ease;
                            }
                        }
    
                        
                    }
                }
            }

            .open-destinations {
                .my-content {
                    display: flex;
                    flex-direction: row-reverse;
                    gap: 5px;

                    position: absolute;
                    top: 300%;
                    left: -80%;
                    background-color: #1976d24b;
                    width: 290%;
                    text-align: right;

                    .card {
                        display: flex;

                        background-color: var(--background-color);
                        width: 30%;
                        height: 110px;
                        cursor: pointer;
                        border-radius: 14px;

                        .left {
                            padding: 0.5em;

                            &:hover {
                                h4, h5 {
                                    color: var(--toot-color);
                                    transition: 0.3s ease;
                                }
                            }

                            p {
                                font-size: 12px;
                                color: rgb(218, 48, 48);
                                margin-top: 1em;
                            }
                        }
                    }
                }
            }

            .open-other-select {
                .my-content {
                    display: flex;
                    flex-direction: row-reverse;
                    gap: 5px;

                    position: absolute;
                    top: 300%;
                    left: -80%;
                    //background-color: #1976d24b;
                    width: 290%;
                    text-align: right;
                    z-index: 999;

                    .card {
                        // display: flex;
                        // flex-direction: row-reverse;

                        background-color: var(--background-color);
                        width: 100%;
                        //height: 110px;
                        cursor: pointer;
                        border-radius: 14px;
                        padding: 1em;

                        .first {
                            display: flex;
                            gap: 30px;
                            flex-direction: row-reverse;

                            .test {
                                visibility: hidden;
                            }

                            h5 {
                                color: rgb(99, 99, 99);
                            }
                        }

                        .secound {
                            display: flex;
                            align-items: center;
                            flex-direction: row-reverse;
                            gap: 45px;

                            margin-top: 2em;

                            .room {
                                display: flex;
                                align-items: center;
                                gap: 5px;

                                h4, i {
                                    color: rgb(28, 28, 32);
                                }
                            }

                            .my-form {
                                .choose-room {
                                    display: flex;
                                    flex-direction: row-reverse;
                                    gap: 45px;

                                    .adult {
                                        select {
                                            color: var(--toot-color);
                                            border: none;
                                            outline: none;
                                            padding: 0.5em;
                                            font-weight: bold;
                                            text-align: center;
                                            cursor: pointer;
                                        }
                                    }
            
                                    .children {
                                        select {
                                            color: var(--toot-color);
                                            border: none;
                                            outline: none;
                                            padding: 0.5em;
                                            font-weight: bold;
                                            text-align: center;
                                            cursor: pointer;
                                        }
                                    }
                                } 
                            }
                        }


                        .save {
                                display: flex;
                                justify-content: center;

                            button {
                                margin-top: 2em;
                                background-color: var(--toot-color);
                                color: var(--background-color);
                                border: 1px solid transparent;
                                outline: none;
                                transition: 0.3s ease;
                                padding: 0.5em;
                                border-radius: 6px;
                                cursor: pointer;
                                font-size: 16px;
                                width: 20%;
                
                                &:hover {
                                    background-color: var(--background-color);
                                    color: var(--toot-color);
                                    border: 1px solid var(--toot-color);
                                    transition: 0.3s ease;
                                }
                            }
                        }



                        hr {
                            margin-top: 1em;
                        }



                        .add-room {
                            display: flex;
                            flex-direction: row-reverse;
                            align-items: center;
                            gap: 10px;

                            margin-top: 1em;
                            color: var(--toot-color);
                        }
                        
                    }
                }
            }
        }

        form.flight-form {
            margin-top: 2em;
            //background-color: rgba(0, 0, 0, 0.7);

            .head-flight {
                display: flex;
                gap: 10px;
                justify-content: center;

                button {
                    background-color: var(--background-color);
                    color: var(--toot-color);
                    border: none;
                    outline: none;
                    padding: 0.5em;
                    border-radius: 6px;
                    width: 150px;
                    font-size: medium;
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.3s ease;

                    &:hover {
                        background-color: var(--toot-color);
                        color: var(--background-color);
                        transition: 0.3s ease;
                    }
                }
            }

            .content-flight {
                display: flex;
                align-items: center;
    
                margin-top: 1em;
                .search {
                    display: flex;
                    justify-content: center;
                    //align-items: center;
                    gap: 5px;
    
                    background-color: var(--toot-color);
                    padding: 0.4em;
                    border-radius: 6px;
    
                    input, select {
                        padding: 0.8em;
                        border-radius: 6px;
                        border: none;
                        outline: none;
                        // To Arabic
                        //direction: rtl;
                    }
    
                    
    
                    .data-from {
                        display: flex;
                        background-color: var(--background-color);
                        border-radius: 6px;
    
                        span {
                            color: var(--place-holder-color);
                            font-size: 12px;
                            margin: 8px 5px 0px 5px;
                        }
                    }
    
                    .location {
                        display: flex;
                        background-color: var(--background-color);
                        border-radius: 6px;
    
                        input {
                            width: 20em;
                        }
    
                        select {
                            width: 20em;
                        }
    
                        i {
                            color: var(--toot-color);
                            margin: 10px 5px 0px 5px;
                            font-size: 16px;
                        }
    
                        i.fa-caret-down {
                            color: var(--place-holder-color);
                            font-size: 14px;
                            margin: 11px 5px 0px 5px;
                            cursor: pointer;
                        }
                    }
    
                    .Guest {
                        cursor: pointer;
                    }
                }
    
                .search-icon {
                    transform: translateX(-2em);
                    margin-left: 4em;
                    cursor: pointer;
    
                    button {
                        border-radius: 50%;
                        cursor: pointer;
                        border: none;
                        outline: none;
                        background-color: var(--toot-color);
                        
    
                        i {
                            color: var(--background-color);
                            background-color: var(--toot-color);
                            padding: 0.5em;
                            border-radius: 50%;
                            font-size: 25px;
                            transition: 0.3s ease;
    
                            &:hover {
                                background-color: var(--background-color);
                                color: var(--toot-color);
                                transition: 0.3s ease;
                            }
                        }
    
                        
                    }
                }
            }

            .this-multi-city {
                //margin-top: 1em;
                //background-color: #3f456b;
                //background-color: rgba(0, 0, 0, 0.7);
                
                .new-tripe {
                    .head-multi-city {
                        display: flex;
                        //justify-content: space-between;
                        flex-direction: row-reverse;
                        align-items: center;
                        gap: 20px;
    
                        padding: 1em 1em 0em;
                        
    
                        .left {
                            h3 {
                                display: flex;
                                gap: 5px;
                                align-items: center;
                                justify-content: end;
            
                                color: var(--background-color);
                            }
                        }
    
                        hr {
                            width: 80%;
                            height: 2px;
                        }
    
                        .right {
                            i {
                                color: rgb(194, 194, 194);
                                cursor: pointer;
                            }
                        }
                    }
    
                    .search {
                        display: flex;
                        justify-content: center;
                        //align-items: center;
                        gap: 5px;
        
                        background-color: var(--toot-color);
                        padding: 0.4em;
                        border-radius: 6px;
        
                        input, select {
                            padding: 0.8em;
                            border-radius: 6px;
                            border: none;
                            outline: none;
                            // To Arabic
                            //direction: rtl;
                        }
        
                        
        
                        .data-from {
                            display: flex;
                            background-color: var(--background-color);
                            border-radius: 6px;
        
                            span {
                                color: var(--place-holder-color);
                                font-size: 12px;
                                margin: 8px 5px 0px 5px;
                            }
                        }
        
                        .location {
                            display: flex;
                            background-color: var(--background-color);
                            border-radius: 6px;
        
                            input {
                                width: 20em;
                            }
        
                            i {
                                color: var(--toot-color);
                                margin: 10px 5px 0px 5px;
                                font-size: 16px;
                            }
        
                            i.fa-caret-down {
                                color: var(--place-holder-color);
                                font-size: 14px;
                                margin: 11px 5px 0px 5px;
                                cursor: pointer;
                            }
                        }
        
                        .Guest {
                            cursor: pointer;
                        }
                    }
                }

                

                .add-tripe {
                    display: flex;
                    justify-content: end;
                    gap: 5px;

                    margin: 0.5em;
                    padding-bottom: 1em;
                    color: var(--white-color);

                    i {
                        cursor: pointer;
                    }
                }
            }

            .down-flight {
                display: flex;
                justify-content: center;
                gap: 10px;

                margin-top: 1em;

                button[type=submit] {
                    background-color: #33A850;
                    color: var(--background-color);
                    border: none;
                    outline: none;
                    padding: 1em;
                    border-radius: 6px;
                    //width: 150px;
                    font-size: medium;
                    font-weight: bold;
                    cursor: pointer;
                    transition: 0.3s ease;

                    &:hover {
                        background-color: var(--background-color);
                        color: #33A850;
                        transition: 0.3s ease;
                    }
                }

                select {
                    background-color: var(--background-color);
                    color: var(--toot-color);
                    border-radius: 6px;
                    outline: none;
                    text-align: center;
                    padding: 0.5em;
                    cursor: pointer;
                    width: 150px;
                    font-weight: 600;
                }
            }
        }
    }
}




/* 
    Start Media
*/
@media(max-width: 992px) {
    .my-search-bar-hotel {
        .container {
            form.hotel-form {
                .content {
                    flex-direction: column;
                    justify-content: center;
        
                    .search {
                        flex-direction: column-reverse;
                        margin-left: 2em;
        
                        .data-from {
                            input {
                                width: 100%;
                            }
                            span {
                                width: 30px;
                            }
                        }
        
                        .location {
                            i {
                                width: 26px;
                            }
                        }
        
                        .my-select {
                            
                            width: 100%;
                            
                            i.fa-user {
                                //margin-left: 40px;
                                padding-right: 0 !important;
                                margin-right: 0 !important;
                                width: 20px !important;
                            }
                        }
        
                        
                    }
        
                    .search-icon {
                        margin-top: 1em;
                        
                        button {
                            margin-left: 2em;
                        }
                    }
                }
            }

            form.flight-form {
                .content-flight {
                    flex-direction: column !important;

                    .search {
                        flex-direction: column-reverse;

                        .data-from {
                            input {
                                width: 100%;
                            }
                            span {
                                width: 30px;
                            }
                        }
                    }
                }

                .this-multi-city {
                    .new-tripe {
                        .search {
                            flex-direction: column-reverse;
                            //margin-left: 2em;
            
                            .data-from {
                                input {
                                    width: 100%;
                                }
                                span {
                                    width: 30px;
                                }
                            }
            
                            .location {
                                width: 100%;
                                // i.fa-plane {
                                //     //width: 40px;
                                //     padding: 0 !important;
                                //     margin: 0 !important;
                                //     width: 20px !important;
                                // }
                            }
            
                            .my-select {
                                
                                width: 100%;
                                
                                i.fa-user {
                                    //margin-left: 40px;
                                    padding-right: 0 !important;
                                    margin-right: 0 !important;
                                    width: 20px !important;
                                }
                            }
            
                            
                        }
                    }
                }
            }
        }
    }
}



@media(max-width: 767px) {
    .my-search-bar-hotel {
        .container {
            form.hotel-form {
                .open-destinations {
                    .my-content {
                        flex-direction: column;
                    
                        left: 0;
                        background-color: transparent;
                    }
                }

                .open-other-select {
                    .my-content {
                        justify-content: center;
                        left: -100%;

                        .card {
                            width: 60%;
                            
                        }
                    }
                   
                }
            }

            
        }
    }
}





@media(max-width: 480px) {
    .my-search-bar-hotel {
        .container {
            form.hotel-form {
                .open-destinations {
                    .my-content {
                        width: 100%;

                        .card {
                            width: 100%;
                        }
                    }
                }

                .open-other-select {
                    .my-content {
                        //justify-content: center;
                        left: 0%;
                        top: 110%;
                        width: 100%;
                        //z-index: 1000;

                        .card {
                            width: 110%;

                            .first {
                                gap: 20px;
                            }

                            .secound {
                                gap: 20px;
                            }
                            
                        }
                    }
                   
                }
            }

            form.flight-form {
                margin-top: 1em;

                .head-flight {
                    gap: 5px;

                    button {
                        width: 100px;
                    }
                }

                .down-flight {
                    gap: 5px;

                    button[type=submit] {
                        padding: 0.5em;
                    }

                    select {
                        width: 100px;
                    }
                }
            }
        }
    }
}


/* 
    End Media
*/