:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
}

.my-hero-section {
    .container {
        .content {
            position: absolute;
            top: 5%;
            right: auto;
            left: 0;
            color: var(--white-color);
            margin-right: 3rem;
            margin-left: 3rem;
            font-size: 1.5em;
            display: flex;
            flex-direction: row-reverse;

            // Image For Logo in Line 
            img {
                width: 20%;
            }
        }

        .search-hotel-bar {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 10%;
            z-index: 2;
        }

        // Hero Image Background
        img {
            width: 100%;
        }
    }
}





/* 
    Start Media
*/
@media(max-width: 992px) {
    .my-hero-section {
        .container {
            .content {
                top: 3%;
            }

            

            // Hero Image Background
            img.big-image {
                width: 200%;
            }
        }
    }
}


@media(max-width: 767px) {
    .my-hero-section {
        .container {
            text-align: center;
            .content {
                font-size: 1.2em;

                display: flex;
                flex-direction: column;
                justify-content: center;

                img {
                    width: 50%;
                    margin-top: 2em;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

        }
    }
}



@media(max-width: 480px) {
    .my-hero-section {
        .container {
            .content {
                img {
                    //margin-top: -0.01em;
                    transform: translateY(-20px);
                }
            }

            .search-hotel-bar {
                top: 10%;
                //top: 55%;
            }
        }
    }
}


/* 
    End Media
*/