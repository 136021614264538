:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.my-offers-details .container .head-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 8em;
}
.my-offers-details .container .head-content span {
  width: 350px;
  height: 2px;
  background-color: var(--toot-color);
  display: inline-block;
}
.my-offers-details .container .head-content h2 {
  color: var(--toot-color);
}
.my-offers-details .container .content {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 4em;
  padding: 1em;
}
.my-offers-details .container .content .left {
  width: 50%;
}
.my-offers-details .container .content .left img {
  width: 100%;
  border-radius: 10px;
}
.my-offers-details .container .content .right {
  width: 50%;
  text-align: center;
}
.my-offers-details .container .content .right h2 {
  color: var(--toot-color);
}
.my-offers-details .container .content .right p {
  color: #3B3B3B;
  margin-top: 1em;
}
.my-offers-details .container .content .right h3 {
  color: var(--toot-color);
  margin-top: 5em;
}
.my-offers-details .container .content .right .code {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 2em;
}
.my-offers-details .container .content .right .code button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #FED415;
  border: 1px solid #FED415;
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  margin-bottom: 0.5em;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-offers-details .container .content .right .code button:hover {
  background-color: var(--white-color);
  color: var(--toot-color);
  transition: 0.3s ease;
}
.my-offers-details .container .content .right .code input {
  padding: 0.8em;
  direction: rtl;
  border-radius: 6px;
  border: 1px solid #C8C7CC;
  width: 300px;
  outline: none;
  text-align: center;
}
.my-offers-details .container .content .right button.watch {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: 1px solid var(--blue-color);
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  margin-bottom: 0.5em;
  margin-top: 4em;
  width: 100%;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-offers-details .container .content .right button.watch:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}

/* 
    Start Media
*/
@media (max-width: 767px) {
  .my-offers-details .container .content {
    flex-direction: column;
    align-items: center;
  }
  .my-offers-details .container .content .left {
    width: 70%;
  }
}
@media (max-width: 480px) {
  .my-offers-details .container .head-content {
    gap: 10px;
  }
  .my-offers-details .container .head-content span {
    width: 100px;
  }
  .my-offers-details .container .head-content h2 {
    font-size: 16px;
  }
  .my-offers-details .container .content {
    margin-top: 2em;
  }
  .my-offers-details .container .content .left {
    width: 90%;
  }
  .my-offers-details .container .content .right {
    width: 90%;
  }
  .my-offers-details .container .content .right .code input {
    width: 150px;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_offers_details.css.map */