:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --Dark-toot-color: #33385B;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
}

.my-footer {
    .container {
        display: flex;
        align-items: center;
        justify-content: space-around;

        background-color: var(--Dark-toot-color);
        //margin-top: 4em;

        .left {
            img {
                width: 70%;
            }
        }

        .right {
            display: flex;
            flex-direction: row-reverse;
            gap: 20px;

            color: var(--background-color);
            
            h4 {
                cursor: pointer;
                color: var(--white-color);

                &:hover {
                    text-decoration: underline;
                }
            }
        }   
    }
}





/* 
    Start Media
*/
@media(max-width: 480px) {
    .my-footer {
        .container {
            .left {
                img {
                    width: 80px;
                }
            }

            .right {
                gap: 15px;

                h4 {
                    font-size: 12px;
                }
            }
        } 
    }
}




/* 
    End Media
*/