:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
}

.login-container {
  background-image: url(../images/login_and_signup.png);
  background-position: center;
  height: 100vh;
}
.login-container .success-message {
  background-color: rgba(0, 0, 0, 0.486);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-container .success-message .my-massage {
  text-align: center;
  background-color: var(--white-color);
  padding: 1em;
  border-radius: 1em;
  position: relative;
}
.login-container .success-message .my-massage h3 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  color: var(--toot-color);
}
.login-container .success-message .my-massage button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--toot-color);
  color: var(--white-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}
.login-container .success-message .my-massage button:hover {
  background-color: var(--white-color);
  color: var(--toot-color);
  border: 1px solid var(--toot-color);
  transition: 0.3s ease;
}
.login-container .success-message .my-massage button:hover i {
  transform: rotate(0deg);
  transition: 0.3s ease;
}
.login-container .success-message .my-massage button i {
  transform: rotate(-45deg);
  transition: 0.3s ease;
}
.login-container .success-message .my-massage i.fa-circle-xmark {
  position: absolute;
  right: 1%;
  top: 1%;
  color: white;
  font-size: 30px;
  cursor: pointer;
  transition: 0.3s ease;
}
.login-container .success-message .my-massage i.fa-circle-xmark:hover {
  color: black;
  transition: 0.3s ease;
}
.login-container .container {
  text-align: right;
  margin-right: 3em;
}
.login-container .container .head {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 5px;
  padding-top: 10em;
}
.login-container .container .head h1 {
  color: var(--background-color);
}
.login-container .container .head .btn {
  display: flex;
  gap: 5px;
  flex-direction: row-reverse;
}
.login-container .container .head .btn button {
  padding: 0.4em;
  outline: none;
  width: 8em;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease;
}
.login-container .container .head .btn button i {
  margin-right: 5px;
  margin-left: 5px;
}
.login-container .container .head .btn button.email {
  background-color: var(--toot-color);
  border: 2px solid var(--toot-color);
  color: var(--background-color);
}
.login-container .container .head .btn button.email:hover {
  background-color: var(--toot-color) !important;
  transition: 0.3s ease;
}
.login-container .container .head .btn button.mobile {
  background-color: transparent;
  border: 2px solid var(--toot-color);
  color: var(--background-color);
}
.login-container .container .head .btn button.mobile:hover {
  background-color: var(--toot-color) !important;
  transition: 0.3s ease;
}
.login-container .container .my-option {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: start;
  width: 100%;
}
.login-container .container .my-option .form-email {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  width: 40%;
}
.login-container .container .my-option .form-email label {
  color: var(--background-color);
  margin-bottom: 0.5em;
}
.login-container .container .my-option .form-email input {
  width: 100%;
  margin-left: auto;
  margin-bottom: 2em;
  text-align: right;
  padding: 0.9em;
  border: none;
  outline: none;
  background-color: #68738B;
  border-radius: 6px;
  color: var(--background-color);
}
.login-container .container .my-option .form-email input::-moz-placeholder {
  color: rgb(184, 184, 184);
}
.login-container .container .my-option .form-email input::placeholder {
  color: rgb(184, 184, 184);
}
.login-container .container .my-option .form-email .options {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
}
.login-container .container .my-option .form-email .options .forget {
  color: rgb(214, 45, 45);
}
.login-container .container .my-option .form-email .options .sign-now {
  color: #68738B;
}
.login-container .container .my-option .form-email .options .sign-now a {
  color: var(--white-color);
}
.login-container .container .my-option .form-email button[type=submit] {
  padding: 0.8em;
  outline: none;
  width: 8em;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease;
  background-color: var(--toot-color);
  border: none;
  color: var(--background-color);
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
}
.login-container .container .my-option .form-email button[type=submit]:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  transition: 0.3s ease;
}
.login-container .container .my-option .google-and-twitter {
  width: 40%;
}
.login-container .container .my-option .google-and-twitter .my-head {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  color: var(--background-color);
  padding-top: 4em;
}
.login-container .container .my-option .google-and-twitter .my-head span {
  background-color: var(--background-color);
  width: 150px;
  height: 2px;
}
.login-container .container .my-option .google-and-twitter .img-platform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2em;
}
.login-container .container .my-option .google-and-twitter .img-platform img {
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  cursor: pointer;
}
.login-container .container .my-option .my-phone-section {
  margin-top: 2em;
  width: 40%;
}
.login-container .container .my-option .my-phone-section .telephon label {
  color: var(--background-color);
}
.login-container .container .my-option .my-phone-section .telephon input {
  width: 100%;
  margin-left: auto;
  margin-bottom: 2em;
  margin-top: 0.5em;
  text-align: right;
  padding: 0.9em;
  border: none;
  outline: none;
  background-color: #68738B;
  border-radius: 6px;
  color: var(--background-color);
}
.login-container .container .my-option .my-phone-section .telephon input::-moz-placeholder {
  color: rgb(184, 184, 184);
}
.login-container .container .my-option .my-phone-section .telephon input::placeholder {
  color: rgb(184, 184, 184);
}
.login-container .container .my-option .my-phone-section .telephon button {
  padding: 0.8em;
  outline: none;
  width: 8em;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease;
  background-color: var(--toot-color);
  border: none;
  color: var(--background-color);
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
}
.login-container .container .my-option .my-phone-section .telephon button:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  transition: 0.3s ease;
}

/* 
  Start Media
*/
@media (max-width: 480px) {
  .login-container {
    overflow-x: hidden !important;
  }
  .login-container .container {
    margin-right: 0em;
  }
  .login-container .container .head {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 6em;
    overflow-x: hidden !important;
  }
  .login-container .container .head h1 {
    margin-bottom: 1em;
  }
  .login-container .container .head button {
    margin-left: auto;
    margin-right: auto;
    padding: 0.5em;
    width: 5em;
  }
  .login-container .container .my-option {
    flex-direction: column;
  }
  .login-container .container .my-option .form-email {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .login-container .container .my-option .form-email button[type=submit] {
    position: relative;
    margin-top: 2em;
    width: -moz-fit-content;
    width: fit-content;
  }
  .login-container .container .my-option .google-and-twitter {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .login-container .container .my-option .google-and-twitter .my-head h1 {
    font-size: 18px;
  }
  .login-container .container .my-option .google-and-twitter .my-head span {
    width: 70px;
  }
  .login-container .container .my-option .google-and-twitter .img-platform {
    flex-direction: row;
  }
  .login-container .container .my-option .my-phone-section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .login-container .container .my-option .my-phone-section .telephon button {
    position: relative;
    margin-top: 2em;
    transform: translateX(-130%);
    width: -moz-fit-content;
    width: fit-content;
  }
}
/* 
  End Media
*//*# sourceMappingURL=style_login.css.map */