* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* تغيير لون الشريط في Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* عرض الشريط */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* لون المسار */
}

::-webkit-scrollbar-thumb {
  background: #33385B; /* لون شريط التمرير */
  border-radius: 10px; /* لتنعيم الحواف */
}

::-webkit-scrollbar-thumb:hover {
  background: #1d213d; /* لون شريط التمرير عند التمرير عليه */
}
