:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }


.my-master-dashboard {
    .container {
        .master {
            .head {
                display: flex;
                justify-content: space-between;

                margin-top: 2em;
                background-color: var(--white-color);
                border-radius: 6px;
                padding: 1.5em;

                img {
                    width: 30%;
                }

                .title, .main-title {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                }

                .main-title {
                    margin-top: 2em;
                    margin-right: 4em;
                }
            }

            .head-content {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
          
                margin-top: 4em;
          
                span {
                  width: 350px;
                  height: 2px;
                  background-color: var(--toot-color);
                  display: inline-block;
                }
          
                h2 {
                  color: var(--toot-color);
                }
            }

            .my-content {
                display: flex;
                gap: 10px;
                justify-content: center;
                flex-wrap: wrap;

                margin-top: 2em;
                margin-bottom: 2em;

                .card {
                    background-color: #FB958D;
                    width: 250px;
                    padding: 1em;
                    border-radius: 6px;
                    text-align: center;
                    color: var(--background-color);

                    // &:nth-child(2) {
                    //     background-color: #96C9D1;
                    // }

                    h1 {
                        font-size: 70px;
                        margin-bottom: 0.2em;
                    }

                    p {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        justify-content: center;

                        margin-bottom: 1em;

                        i {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}



/* 
    Start Media
*/
@media(max-width: 480px) {
    .my-master-dashboard {
        .container {
            .master {
                .head {
                    flex-direction: column;

                    img {
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                    }
    
                    .main-title {
                        margin-right: 0;
                        text-align: right;
                    }
                }

                .head-content {
                    gap: 10px;
            
                    span {
                      width: 100px;
                    }
            
                    h2 {
                      font-size: 16px;
                    }
                  }
            }
            
        }
    }
}
 


/* 
    End Media
*/