:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.active-for-search-subscribe-user {
  background-color: var(--toot-color) !important;
  color: var(--background-color) !important;
}

.my-subscibe-user .container .head-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 4em;
}
.my-subscibe-user .container .head-content span {
  width: 350px;
  height: 2px;
  background-color: var(--toot-color);
  display: inline-block;
}
.my-subscibe-user .container .head-content h2 {
  color: var(--toot-color);
}
.my-subscibe-user .container .search-user {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.my-subscibe-user .container .search-user .btn {
  display: flex;
  gap: 5px;
  margin-top: 2em;
}
.my-subscibe-user .container .search-user .btn button {
  padding: 0.5em;
  outline: none;
  width: 7em;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  transition: 0.3s ease;
}
.my-subscibe-user .container .search-user .btn button.mobile {
  background-color: transparent;
  border: 2px solid var(--toot-color);
  color: var(--toot-color);
}
.my-subscibe-user .container .search-user .btn button.mobile:hover {
  background-color: var(--toot-color);
  color: var(--background-color);
  transition: 0.3s ease;
}
.my-subscibe-user .container .search-user .btn button.email {
  background-color: var(--toot-color);
  border: 2px solid var(--toot-color);
  color: var(--background-color);
}
.my-subscibe-user .container .search-user .btn button.email:hover {
  background-color: var(--toot-color) !important;
  color: var(--background-color) !important;
  transition: 0.3s ease;
}
.my-subscibe-user .container .search-user input {
  padding: 0.8em;
  direction: rtl;
  border-radius: 6px;
  border: 1px solid #C8C7CC;
  width: 80%;
  outline: none;
  text-align: center;
}
.my-subscibe-user .container .search-user input::-moz-placeholder {
  text-align: center;
}
.my-subscibe-user .container .search-user input::placeholder {
  text-align: center;
}
.my-subscibe-user .container .search-user button.btn-search {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: 1px solid var(--blue-color);
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 150px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
  margin-top: 1em;
}
.my-subscibe-user .container .search-user button.btn-search:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}
.my-subscibe-user .container .subscribe-content {
  text-align: center;
  margin-top: 2em;
}
.my-subscibe-user .container .subscribe-content .master-search .search-content {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}
.my-subscibe-user .container .subscribe-content .master-search .search-content button {
  background-color: #E74133;
  color: var(--white-color);
  border: 1px solid #E74133;
  outline: none;
  padding: 0.3em;
  border-radius: 6px;
  margin-bottom: 0.5em;
  width: 50px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-subscibe-user .container .subscribe-content .master-search .search-content button:hover {
  background-color: var(--white-color);
  color: #E74133;
  transition: 0.3s ease;
}
.my-subscibe-user .container .subscribe-content .master-search .search-content p {
  background-color: var(--white-color);
  padding: 0.5em;
  border-radius: 6px;
}
.my-subscibe-user .container .subscribe-content .my-users {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 2em;
}
.my-subscibe-user .container .subscribe-content .my-users .info-user {
  display: flex;
  gap: 20px;
}
.my-subscibe-user .container .subscribe-content .my-users .info-user button {
  background-color: #E74133;
  color: var(--white-color);
  border: 1px solid #E74133;
  outline: none;
  padding: 0.3em;
  border-radius: 6px;
  margin-bottom: 0.5em;
  width: 100px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-subscibe-user .container .subscribe-content .my-users .info-user button:hover {
  background-color: var(--white-color);
  color: #E74133;
  transition: 0.3s ease;
}
.my-subscibe-user .container .subscribe-content .my-users .info-user input {
  padding: 0.5em;
  direction: rtl;
  border-radius: 6px;
  border: 1px solid #C8C7CC;
  width: 300px;
  outline: none;
  text-align: center;
}
.my-subscibe-user .container .subscribe-content .my-users p {
  font-size: 14px;
}

/* 
    Start Media
*/
@media (max-width: 480px) {
  .my-subscibe-user .container .head-content {
    gap: 10px;
  }
  .my-subscibe-user .container .head-content span {
    width: 100px;
  }
  .my-subscibe-user .container .head-content h2 {
    font-size: 16px;
  }
  .my-subscibe-user .container .subscribe-content .my-users .info-user {
    flex-direction: column-reverse;
    gap: 5px;
    align-items: end;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_subscribeUser.css.map */