:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
  }

//   body {
//     height: 2000px;
//   }


.my-personal-information {
    .container {
        .head {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            padding-top: 8em;

            p.circle {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 20px;
                height: 20px;
                border-radius: 50%;

                &:nth-child(2) {
                    border: 1px solid #C2C0C0;
                    color: #C2C0C0;
                }

                &:nth-child(5) {
                    background-color: var(--toot-color);
                    color: var(--background-color);
                }
            }

            hr {
                width: 15%;
                height: 2px;
                background-color: var(--toot-color);
                border: none;
            }

            i {
                color: var(--toot-color);
            }
        }

        .content {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            //width: 100%;

            margin: 1em;

            .left {
                width: 70%;

                .my-head {
                    background-color: #F38585;
                    padding: 1em;
                    border-radius: 6px;

                    h2 {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        justify-content: end;

                        color: var(--background-color);

                        a {
                            text-decoration: underline;
                            color: black;
                        }
                    }
                }

                form.form-for-hotel {
                    margin-top: 1em;
                    padding: 1em;
                    background-color: var(--white-color);

                    .my-body {
                        display: flex;
                        justify-content: space-around;

                        .my-left {
                            display: flex;
                            flex-direction: column;
                            align-items: end;
    
                            input {
                                padding: 0.5em;
                                border-radius: 6px;
                                outline: none;
                                border: 1px solid #C8C7CC;
                                margin-top: 10px;
                                width: 250px;
                                direction: rtl;
    
                                &::placeholder {
                                    text-align: right;
                                    
                                }
                            }
                        }
    
                        .my-right {
                            display: flex;
                            flex-direction: column;
                            justify-content: end;
                            align-items: end;
    
                            .part {
                                display: flex;
                                flex-direction: column;
                                align-items: end;
    
                                &:not(:nth-child(1)) {
                                    margin-top: 2em;
                                }
    
                                input {
                                    padding: 0.5em;
                                    border-radius: 6px;
                                    outline: none;
                                    border: 1px solid #C8C7CC;
                                    margin-top: 10px;
                                    width: 250px;
                                    direction: rtl;
        
                                    &::placeholder {
                                        text-align: right;
                                    }
                                }
    
                                p {
                                    font-size: 14px;
                                }
                            }
    
                            hr {
                                width: 230%;
                                margin-top: 1em;
                                height: 2px;
                                background-color: #E4E4E4;
                                border: none;
                            }
    
                            .check {
                                display: flex;
                                flex-direction: column;
                                align-items: end;
    
                                margin-top: 2em;
    
                                .main {
                                    display: flex;
                                    gap: 5px;
                                }
    
                                .more-info {
                                    display: flex;
                                    flex-direction: column;
    
                                    input {
                                        padding: 0.5em;
                                        border-radius: 6px;
                                        outline: none;
                                        border: 1px solid #C8C7CC;
                                        margin-top: 10px;
                                        width: 250px;
                                        direction: rtl;
            
                                        &::placeholder {
                                            text-align: right;
                                        }
                                    }
                                }
                            }
    
                            
                        }
                    }

                    .special-order {
                        display: flex;
                        flex-direction: column;
                        align-items: end;

                        padding: 1em;

                        p {
                            font-size: 14px;
                        }

                        textarea {
                            min-width: 100%;
                            max-width: 100%;
                            min-height: 150px;
                            margin-top: 1em;
                            direction: rtl;
                            padding: 1em;

                            &::placeholder {
                                text-align: right;
                                outline: none;
                                border: 1px solid #C8C7CC;
                                //padding: 1em;
                            }
                        }
                    }

                    .perfer {
                        display: flex;
                        gap: 5px;
                        justify-content: end;
                    }

                    .policy {
                        display: flex;
                        justify-content: end;
                        gap: 20px;

                        padding: 2em;
                        margin-top: 2em;
                        background-color: #E2F1FF;

                        .this-left {
                            p {
                                display: flex;
                                align-items: center;
                                justify-content: end;
                                gap: 5px;

                                i {
                                    color: var(--toot-color);
                                    font-size: 6px;
                                }
                            }
                        }

                        .this-right {
                            i {
                                color: var(--toot-color);
                                font-size: 25px;
                            }
                        }
                    }

                    .last-step {
                        button {
                            display: flex;
                            gap: 10px;
                            justify-content: center;
                            align-items: center;
            
                            background-color: var(--blue-color);
                            color: var(--background-color);
                            border: 1px solid transparent;
                            outline: none;
                            transition: 0.3s ease;
                            padding: 0.5em;
                            border-radius: 6px;
                            cursor: pointer;
                            font-size: 20px;
                            width: 25%;
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 1em;
            
                            &:hover {
                                background-color: #33A850;
                                color: var(--background-color);
                                border: 1px solid var(--background-color);
                                transition: 0.3s ease;
                            }
                        }
                    }
                    
                }
            }

            .right {
                width: 30%;
                background-color: var(--white-color);
                padding: 1em;
                height: fit-content;
                border-radius: 6px;
                
                h2 {
                    display: flex;
                    gap: 10px;
                    justify-content: center;
                    align-items: center;

                    //margin-top: 1em;
                    color: var(--toot-color);
                }

                h5 {
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    gap: 5px;

                    margin-top: 1em;

                    &:nth-of-type(2) {
                        color: var(--blue-color);
                    }

                    &:nth-of-type(3) {
                        color: #F38585;
                    }

                    i {
                        color: var(--toot-color);
                        font-size: 6px;
                    }
                }

                .my-choose {
                    display: flex;
                    flex-direction: column;
                    align-items: end;

                    margin: 0.5em 2em;
                }

                hr {
                    width: 80%;
                    margin: 1em auto;
                }

                .price {
                    margin-top: 1em;

                    h2 {
                        background-color: #33A850;
                        padding: 1em;
                        color: var(--background-color);
                    }
                }
            }
        }
    }
}






/* 
    Start Media
*/

@media(max-width: 767px) {
    .my-personal-information {
        .container {
            .content {
                flex-direction: column-reverse;
    
                .left {
                    width: 100%;
    
                    .my-head {
                        h2 {
                            font-size: 18px;
                        }
                    }
    
                    form.form-for-hotel {
                        .last-step {
                            button {
                                width: 80%;
                            }
                        }
                    }
                }
    
                .right {
                    width: 100%;
    
                    .price {
                        
                        h2 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}


@media(min-width: 480px) {
    .hide-in-large {
        display: none !important;
    }
}


@media(max-width: 480px) {
    .my-personal-information {
        .container {
            .head {
                gap: 2px;

                p {
                    font-size: 12px;
                }
            }

            .content {
                
                .left {
    
                    .my-head {
                        h2 {
                            gap: 0;
                            font-size: 16px;
                        }
                    }
    
                    form.form-for-hotel {
                        .my-body {
                            .my-left {
                                display: none;
                            }
                        }

                    }
                }
            }
        }
    }
}

/* 
    End Media
*/