:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }


//   body {
//     height: 2000px;
//   }

.my-offers {
    .container {
        .hero {
            background-image: url(../../images/offers.png);
            height: 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            display: flex;
            flex-direction: column;
            align-items: end;

            h1 {
                padding-top: 17em;
                color: var(--background-color);
                margin: 0 2em 0.5em;
            }

            hr {
                width: 50%;
                margin: 0 4em;
            }
        }

        .content {
            .head {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;

                margin-top: 2em;

                select {
                    background-color: var(--blue-color);
                    color: var(--background-color);
                    padding: 0.5em;
                    width: 300px;
                    border: none;
                    outline: none;
                    border-radius: 6px;
                    text-align: center;
                    cursor: pointer;
                    font-weight: bold;
                    direction: rtl;
                }

                label {
                    color: var(--blue-color);
                    font-weight: bold;
                }
            }

            .my-cards {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                justify-content: center;

                margin-top: 4em;

                .card {
                    background-color: var(--white-color);
                    width: 400px;
                    text-align: center;

                    img {
                        width: 100%;
                        height: 250px;
                        object-fit: cover;
                        border-radius: 10px;
                    }

                    h2 {
                        color: var(--toot-color);
                        margin: 0.5em 0;
                    }

                    p {
                        color: #3B3B3B;
                        padding: 0.5em;
                        height: 90px;
                        overflow-y: hidden;
                    }

                    .rate {
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        justify-content: end;

                        padding: 1em;

                        i {
                            color: #FED208;
                            font-size: 14px;
                        }

                        i.no-rate {
                            color: var(--place-holder-color);
                          }

                        span {
                            font-size: 14px;
                        }
                    }

                    button {
                        background-color: var(--blue-color);
                        color: var(--white-color);
                        border: 1px solid var(--blue-color);
                        outline: none;
                        padding: 0.5em;
                        border-radius: 6px;
                        margin-bottom: 0.5em;
                        width: 100%;
                        font-size: medium;
                        font-weight: 700;
                        cursor: pointer;
                        transition: 0.3s ease;
                
                        &:hover {
                            background-color: var(--white-color);
                            color: var(--blue-color);
                            transition: 0.3s ease;
                        }
                    }
                }
            }
        }
    }
}







/* 
    Start Media
*/
@media(max-width: 767px) {
    .my-offers {
        .container {
            .hero {
                background-image: url(../../images/offers_for_small.png);
            }

            .content {
                margin-top: 2em;
            }
        }
    }
}



@media(max-width: 480px) {
    .my-offers {
        .container {
            .hero {
                h1 {
                    padding-top: 12em;
                    text-align: center;
                    margin: 0 auto;
                }

                hr {
                    width: 80%;
                    margin: 1em auto;
                }
            }

            .content {
                .head {
                    flex-direction: column-reverse;
                }

                .my-cards {
                    .card {
                        width: 350px;
                    }
                }
            }
            
        }
    }
}


/* 
    End Media
*/