:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }


.my-home-dashboard {
    .container {
        .admin-login {
            display: flex;

            padding-top: 8em;
            width: 100%;

            .left {
                width: 50%;
                margin-left: 4em;
                img {
                    width: 70%;
                }
            }

            .right {
                width: 50%;
                text-align: center;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                form {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    

                    justify-content: center;
                    border: 2px solid var(--blue-color);
                    border-radius: 6px;
                    padding: 2em;
                    position: relative;
                    margin-top: 5em;
                    width: 500px;
                    

                    .lock {
                        position: absolute;
                        top: -7em;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    label {
                        margin-bottom: 0.5em;

                        &:nth-of-type(1) {
                            margin-top: 2em;
                        }
                    }

                    input {
                        direction: rtl;
                        width: 300px;
                        padding: 0.8em;
                        border-radius: 6px;
                        background-color: #68738B;
                        color: var(--white-color);
                        border: none;
                        outline: none;
                        margin-bottom: 2em;

                        &::placeholder {
                            color: #C8C7CC;
                        }
                    }

                    .btn {
                        
                        button {
                            background-color: var(--toot-color);
                            color: var(--white-color);
                            border: 1px solid var(--toot-color);
                            outline: none;
                            padding: 0.5em;
                            border-radius: 6px;
                            width: 150px;
                            font-size: medium;
                            font-weight: 700;
                            cursor: pointer;
                            transition: 0.3s ease;
        
                            &:hover {
                                background-color: var(--white-color);
                                color: var(--toot-color);
                                transition: 0.3s ease;
                            }
                        }
                    }
                }
            }
        }
    }
}




/* 
    Start Media
*/
@media(max-width: 767px) {
    .my-home-dashboard {
        .container {
            .admin-login {
                flex-direction: column;

                .left {
                    width: 100%;
                    margin-left: 0;
                    text-align: center;
                }

                .right {
                    margin-top: 2em;
                    margin-bottom: 5em;
                    width: 70%;
                }
            }
        }
    }
}






/* 
    End Media
*/