:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
  }


  .my-reset-password {
    background-image: url(../images/login_and_signup.png);
    background-position: center;
    height: 100vh;
    text-align: center;
    //padding-top: 10em;

    h1, h3 {
        color: var(--background-color);
    }
    h1 {
        margin-bottom: 1em;
        padding-top: 6em;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 30%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2em;

        input {
            width: 100%;
            margin-left: auto;
            margin-bottom: 2em;
            text-align: right;
            padding: 0.9em;
            border: none;
            outline: none;
            background-color: #68738B;
            border-radius: 6px;
            color: var(--background-color);
    
            &::placeholder {
              color: rgb(184, 184, 184);
              
            }
          }

          button[type=submit] {
            padding: 0.5em;
            outline: none;
            width: 40%;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            border-radius: 6px;
            transition: 0.3s ease;
            background-color: var(--toot-color);
            margin-left: auto;
            margin-right: auto;
            border: none;
            color: var(--background-color);
  
              &:hover {
                  background-color: var(--background-color);
                  color: var(--toot-color);
                  transition: 0.3s ease;
              }
          }
    }
  }









  /* 
    Start Media
  */
  @media(max-width: 767px) {
    .my-reset-password {
        form {
            width: 70%;
        }
    }
  }


  @media(max-width: 480px) {
    .my-reset-password {
        form {
            width: 80%;

            button[type=submit] {
                width: 60%;
                font-size: 16px;
            }
    }
  }
}



   /* 
    End Media
  */