:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --Dark-toot-color: #33385B;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
}


.my-help {
    .container {
        display: flex;
        justify-content: space-around;
        align-items: center;

        margin-top: 4em;

        .left {
            img {
                width: 60%;
            }
        }

        .right {
            text-align: right;

            .phone {
                display: flex;
                align-items: center;
                justify-content: end;
                gap: 20px;

                margin-top: 2em;

                i {
                    color: var(--Dark-toot-color);
                    font-size: 2em;
                }
            }
        }
    }
}




/* 
    Start Media
*/
@media(max-width: 480px) {
    .my-help {
        .container {
            flex-direction: column;
            justify-content: center;
    
            text-align: center;
            .left {
               img {
                width: 80%;
                
               }
            }
    
            .right {
                text-align: center;

                .phone {
                    justify-content: center;
                    gap: 10px;
                }
            }
        }
    }
}


/* 
    End Media
*/