:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }

.my-who-we-are-dashboard {
  .container {
    .add {
      display: flex;
      flex-direction: column;
      align-items: end;

      margin-top: 3em;

      .add-title {
        display: flex;
        flex-direction: column;
        align-items: end;

        margin-bottom: 1em;

        label {
          margin-bottom: 0.5em;
        }

        input {
          padding: 0.8em;
          direction: rtl;
          border-radius: 6px;
          border: 1px solid #C8C7CC;
          width: 800px;
          outline: none;
        }

        textarea {
          padding: 0.8em;
          direction: rtl;
          border-radius: 6px;
          border: 1px solid #C8C7CC;
          max-width: 800px;
          min-width: 800px;
          height: 100px;
          outline: none;
        }
      }

      button {
        background-color: var(--blue-color);
        color: var(--white-color);
        border: 1px solid var(--blue-color);
        outline: none;
        padding: 0.5em;
        border-radius: 6px;
        width: 150px;
        font-size: medium;
        font-weight: 700;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
            background-color: var(--white-color);
            color: var(--blue-color);
            transition: 0.3s ease;
        }
      }
    }

    .head-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      margin-top: 4em;

      span {
        width: 350px;
        height: 2px;
        background-color: var(--toot-color);
        display: inline-block;
      }

      h2 {
        color: var(--toot-color);
      }
    }

    .my-data {
      .vision {
        display: flex;
        flex-direction: column;
        align-items: end;

        h3 {
          display: flex;
          align-items: center;
          gap: 5px;
          justify-content: center;

          margin-bottom: 0.5em;
          margin-top: 1em;
          color: var(--toot-color);

          i {
            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
            background-color: #0000007e;
            color: var(--white-color);
            padding: 0.5em;
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }

        p {
          text-align: right;
          margin-bottom: 1em;
        }

        .option {
          display: flex;
          gap: 20px;

          margin-top: 1em;
          margin-right: auto;
          margin-left: auto;

          button {
            //background-color: var(--blue-color);
            color: var(--white-color);
            border: none;
            outline: none;
            padding: 0.5em;
            border-radius: 6px;
            width: 100px;
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
            transition: 0.3s ease;
          }

          button.edit {
            background-color: #33A850;
            border: 1px solid #33A850;
            transition: 0.3s ease;

            &:hover {
              background-color: var(--white-color);
              color: var(--blue-color);
              transition: 0.3s ease;
            }
          }

          button.delete {
            background-color: #E74133;
            border: 1px solid #E74133;

            &:hover {
              background-color: var(--white-color);
              color: var(--blue-color);
              transition: 0.3s ease;
            }
          }
        }
      }
    }
  }
}




/* 
  Start Media
*/

@media(max-width: 767px) {
  .my-who-we-are-dashboard {
    .container {
      .add {
        .add-title {
          input {
            width: 500px;
          }
  
          textarea {
            min-width: 500px;
            max-width: 500px;
          }
        }
      }
    }
  }
}


@media(max-width: 480px) {
  .my-who-we-are-dashboard {
    .container {
      .add {
        .add-title {
          input {
            width: 300px;
          }
  
          textarea {
            min-width: 300px;
            max-width: 300px;
          }
        }
      }

      .head-content {
        gap: 10px;

        span {
          width: 100px;
        }

        h2 {
          font-size: 16px;
        }
      }
    }
  }
}

/* 
  End Media
*/