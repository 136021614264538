:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
}

// Login.scss
// .login-container {
//     max-width: 400px;
//     margin: 0 auto;
//     padding: 2rem;
//     border: 1px solid #ddd;
//     border-radius: 8px;
//     text-align: center;
  
//     h1 {
//       margin-bottom: 1rem;
//     }
  
//     form {
//       display: flex;
//       flex-direction: column;
  
//       input {
//         margin-bottom: 0.5rem;
//         padding: 0.5rem;
//         border: 1px solid #ddd;
//         border-radius: 4px;
//       }
  
//       button {
//         padding: 0.5rem;
//         border: none;
//         background-color: #007bff;
//         color: white;
//         cursor: pointer;
//         border-radius: 4px;
  
//         &:hover {
//           background-color: #0056b3;
//         }
//       }
//     }
  
//     button {
//       margin-top: 1rem;
//     }
//   }
  

.login-container {
  background-image: url(../images/login_and_signup.png);
  background-position: center;
  height: 100vh;


  // Start Massage
  .success-message {
    background-color: rgba(0, 0, 0, 0.486);
    position: fixed;
    top: 0; // إضافة هذه الخاصية
    left: 0; // إضافة هذه الخاصية
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex; // استخدام flex لتوسيط المحتوى
    align-items: center; // توسيط عمودي
    justify-content: center; // توسيط أفقي

    .my-massage {
        text-align: center;
        background-color: var(--white-color);
        padding: 1em;
        border-radius: 1em;
        position: relative;

        h3 {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 1em;
            color: var(--toot-color);
        }

        button {
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;

            background-color: var(--toot-color);
            color: var(--white-color);
            border: 1px solid transparent;
            outline: none;
            transition: 0.3s ease;
            padding: 0.5em;
            border-radius: 6px;
            cursor: pointer;
            font-size: 20px;
            width: 25%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 1em;

            &:hover {
                background-color: var(--white-color);
                color: var(--toot-color);
                border: 1px solid var(--toot-color);
                transition: 0.3s ease;

                i {
                    transform: rotate(0deg);
                    transition: 0.3s ease;
                }
            }

            i {
                transform: rotate(-45deg);
                transition: 0.3s ease;
            }
        }

        i.fa-circle-xmark {
            position: absolute;
            right: 1%;
            top: 1%;
            color: white;
            font-size: 30px;
            cursor: pointer;
            transition: 0.3s ease;
    
            &:hover {
                color: black;
                transition: 0.3s ease;
            }
        }
    }

    
    
  }


// End Massage



  .container {
    text-align: right;
    margin-right: 3em;
    

    .head {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 5px;

      padding-top: 10em;

      h1 {
        //padding-top: 5em;
        color: var(--background-color);
      }

      .btn {
        display: flex;
        gap: 5px;
        flex-direction: row-reverse;

        button {
          padding: 0.4em;
          outline: none;
          width: 8em;
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
          border-radius: 6px;
          transition: 0.3s ease;
  
          i {
              margin-right: 5px;
              margin-left: 5px;
          }
      }
  
          button.email {
              background-color: var(--toot-color);
              border: 2px solid var(--toot-color);
              color: var(--background-color);
  
              &:hover {
                  background-color: var(--toot-color) !important;
                  transition: 0.3s ease;
              }
          }
  
          button.mobile {
              background-color: transparent;
              border: 2px solid var(--toot-color);
              color: var(--background-color);
  
              &:hover {
                  background-color: var(--toot-color) !important;
                  transition: 0.3s ease;
              }
          }
      }

      
    }

    .my-option {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      align-items: start;
      width: 100%;

      .form-email {
        display: flex;
        flex-direction: column;
  
        margin-top: 2em;
        width: 40%;
        //position: relative;
        
        label {
          color: var(--background-color);
          margin-bottom: 0.5em;
        }
  
        input {
          width: 100%;
          margin-left: auto;
          margin-bottom: 2em;
          text-align: right;
          padding: 0.9em;
          border: none;
          outline: none;
          background-color: #68738B;
          border-radius: 6px;
          color: var(--background-color);
  
          &::placeholder {
            color: rgb(184, 184, 184);
            
          }
        }
  
        .options {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
  
          //gap: 13em;
  
          width: 100%;
          margin-left: auto;
  
          .forget {
            color: rgb(214, 45, 45);
          }
  
          .sign-now {
            color: #68738B;
  
            a {
              color: var(--white-color);
            }
          }
        }

        button[type=submit] {
          padding: 0.8em;
          outline: none;
          width: 8em;
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
          border-radius: 6px;
          transition: 0.3s ease;
          background-color: var(--toot-color);
          //border: 2px solid var(--toot-color);
          border: none;
          color: var(--background-color);

          position: absolute;
          top: 85%;
          left: 50%;
          transform: translateX(-50%);
          

            &:hover {
                background-color: var(--background-color);
                color: var(--toot-color);
                transition: 0.3s ease;
            }
        }
      }

      .google-and-twitter {
        width: 40%;

        .my-head {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
  
          text-align: center;
          color: var(--background-color);
          padding-top: 4em;
  
          span {
              background-color: var(--background-color);
              width: 150px;
              height: 2px;
          }
        }

        .img-platform {
          display: flex;
          flex-direction: column;
          justify-content: center;
          
          margin-top: 2em;

          img {
            margin-left: auto;
            margin-right: auto;
            width: 20%;
            cursor: pointer;
          }
        }
      }
    }

    .my-option {
      .my-phone-section {
        margin-top: 2em;
        width: 40%;

        .telephon {
          label {
            color: var(--background-color);
          }

          input {
            width: 100%;
            margin-left: auto;
            margin-bottom: 2em;
            margin-top: 0.5em;
            text-align: right;
            padding: 0.9em;
            border: none;
            outline: none;
            background-color: #68738B;
            border-radius: 6px;
            color: var(--background-color);
    
            &::placeholder {
              color: rgb(184, 184, 184);
              
            }
          }

          button {
            padding: 0.8em;
            outline: none;
            width: 8em;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
            border-radius: 6px;
            transition: 0.3s ease;
            background-color: var(--toot-color);
            //border: 2px solid var(--toot-color);
            border: none;
            color: var(--background-color);
  
            position: absolute;
            top: 85%;
            left: 50%;
            transform: translateX(-50%);
            
  
              &:hover {
                  background-color: var(--background-color);
                  color: var(--toot-color);
                  transition: 0.3s ease;
              }
          }
        }
      }
    }
    
  }
}






/* 
  Start Media
*/
@media(max-width: 480px) {
  .login-container {
    overflow-x: hidden !important;
    //overflow-y: hidden;
    .container {
      //text-align: center;
      margin-right: 0em;
      //overflow-x: hidden;

      .head {
        flex-direction: column;
        justify-content: center;
        text-align: center;

        padding-top: 6em;
        overflow-x: hidden !important;

        h1 {
          margin-bottom: 1em;
        }

        button {
          margin-left: auto;
          margin-right: auto;
          padding: 0.5em;
          width: 5em;
        }
      }

      .my-option {
        flex-direction: column;

        .form-email {
          margin-left: auto;
          margin-right: auto;
          width: 80%;

          button[type=submit] {
            position: relative;
            margin-top: 2em;
            //top: 90%;
            width: fit-content;
          }
        }

        .google-and-twitter {
          width: 80%;
          margin-left: auto;
          margin-right: auto;

          .my-head {
            h1 {
              font-size: 18px;
            }

            span {
              width: 70px;
            }
          }

          .img-platform {
            flex-direction: row;
          }
        }
      }

      .my-option {
        .my-phone-section {
          width: 80%;
          margin-left: auto;
          margin-right: auto;

          .telephon {
            button {
              position: relative;
              margin-top: 2em;
              transform: translateX(-130%);
              //top: 90%;
              width: fit-content;
            }
          }
        }
      }
    }
  }
}





/* 
  End Media
*/