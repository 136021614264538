:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
}

.my-personal-information .container .head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 8em;
}
.my-personal-information .container .head p.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.my-personal-information .container .head p.circle:nth-child(2) {
  border: 1px solid #C2C0C0;
  color: #C2C0C0;
}
.my-personal-information .container .head p.circle:nth-child(5) {
  background-color: var(--toot-color);
  color: var(--background-color);
}
.my-personal-information .container .head hr {
  width: 15%;
  height: 2px;
  background-color: var(--toot-color);
  border: none;
}
.my-personal-information .container .head i {
  color: var(--toot-color);
}
.my-personal-information .container .content {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 1em;
}
.my-personal-information .container .content .left {
  width: 70%;
}
.my-personal-information .container .content .left .my-head {
  background-color: #F38585;
  padding: 1em;
  border-radius: 6px;
}
.my-personal-information .container .content .left .my-head h2 {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
  color: var(--background-color);
}
.my-personal-information .container .content .left .my-head h2 a {
  text-decoration: underline;
  color: black;
}
.my-personal-information .container .content .left form.form-for-hotel {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--white-color);
}
.my-personal-information .container .content .left form.form-for-hotel .my-body {
  display: flex;
  justify-content: space-around;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-left {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-left input {
  padding: 0.5em;
  border-radius: 6px;
  outline: none;
  border: 1px solid #C8C7CC;
  margin-top: 10px;
  width: 250px;
  direction: rtl;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-left input::-moz-placeholder {
  text-align: right;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-left input::placeholder {
  text-align: right;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .part {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .part:not(:nth-child(1)) {
  margin-top: 2em;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .part input {
  padding: 0.5em;
  border-radius: 6px;
  outline: none;
  border: 1px solid #C8C7CC;
  margin-top: 10px;
  width: 250px;
  direction: rtl;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .part input::-moz-placeholder {
  text-align: right;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .part input::placeholder {
  text-align: right;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .part p {
  font-size: 14px;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right hr {
  width: 230%;
  margin-top: 1em;
  height: 2px;
  background-color: #E4E4E4;
  border: none;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .check {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 2em;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .check .main {
  display: flex;
  gap: 5px;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .check .more-info {
  display: flex;
  flex-direction: column;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .check .more-info input {
  padding: 0.5em;
  border-radius: 6px;
  outline: none;
  border: 1px solid #C8C7CC;
  margin-top: 10px;
  width: 250px;
  direction: rtl;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .check .more-info input::-moz-placeholder {
  text-align: right;
}
.my-personal-information .container .content .left form.form-for-hotel .my-body .my-right .check .more-info input::placeholder {
  text-align: right;
}
.my-personal-information .container .content .left form.form-for-hotel .special-order {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 1em;
}
.my-personal-information .container .content .left form.form-for-hotel .special-order p {
  font-size: 14px;
}
.my-personal-information .container .content .left form.form-for-hotel .special-order textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 150px;
  margin-top: 1em;
  direction: rtl;
  padding: 1em;
}
.my-personal-information .container .content .left form.form-for-hotel .special-order textarea::-moz-placeholder {
  text-align: right;
  outline: none;
  border: 1px solid #C8C7CC;
}
.my-personal-information .container .content .left form.form-for-hotel .special-order textarea::placeholder {
  text-align: right;
  outline: none;
  border: 1px solid #C8C7CC;
}
.my-personal-information .container .content .left form.form-for-hotel .perfer {
  display: flex;
  gap: 5px;
  justify-content: end;
}
.my-personal-information .container .content .left form.form-for-hotel .policy {
  display: flex;
  justify-content: end;
  gap: 20px;
  padding: 2em;
  margin-top: 2em;
  background-color: #E2F1FF;
}
.my-personal-information .container .content .left form.form-for-hotel .policy .this-left p {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}
.my-personal-information .container .content .left form.form-for-hotel .policy .this-left p i {
  color: var(--toot-color);
  font-size: 6px;
}
.my-personal-information .container .content .left form.form-for-hotel .policy .this-right i {
  color: var(--toot-color);
  font-size: 25px;
}
.my-personal-information .container .content .left form.form-for-hotel .last-step button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}
.my-personal-information .container .content .left form.form-for-hotel .last-step button:hover {
  background-color: #33A850;
  color: var(--background-color);
  border: 1px solid var(--background-color);
  transition: 0.3s ease;
}
.my-personal-information .container .content .right {
  width: 30%;
  background-color: var(--white-color);
  padding: 1em;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 6px;
}
.my-personal-information .container .content .right h2 {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: var(--toot-color);
}
.my-personal-information .container .content .right h5 {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  margin-top: 1em;
}
.my-personal-information .container .content .right h5:nth-of-type(2) {
  color: var(--blue-color);
}
.my-personal-information .container .content .right h5:nth-of-type(3) {
  color: #F38585;
}
.my-personal-information .container .content .right h5 i {
  color: var(--toot-color);
  font-size: 6px;
}
.my-personal-information .container .content .right .my-choose {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: 0.5em 2em;
}
.my-personal-information .container .content .right hr {
  width: 80%;
  margin: 1em auto;
}
.my-personal-information .container .content .right .price {
  margin-top: 1em;
}
.my-personal-information .container .content .right .price h2 {
  background-color: #33A850;
  padding: 1em;
  color: var(--background-color);
}

/* 
    Start Media
*/
@media (max-width: 767px) {
  .my-personal-information .container .content {
    flex-direction: column-reverse;
  }
  .my-personal-information .container .content .left {
    width: 100%;
  }
  .my-personal-information .container .content .left .my-head h2 {
    font-size: 18px;
  }
  .my-personal-information .container .content .left form.form-for-hotel .last-step button {
    width: 80%;
  }
  .my-personal-information .container .content .right {
    width: 100%;
  }
  .my-personal-information .container .content .right .price h2 {
    font-size: 18px;
  }
}
@media (min-width: 480px) {
  .hide-in-large {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .my-personal-information .container .head {
    gap: 2px;
  }
  .my-personal-information .container .head p {
    font-size: 12px;
  }
  .my-personal-information .container .content .left .my-head h2 {
    gap: 0;
    font-size: 16px;
  }
  .my-personal-information .container .content .left form.form-for-hotel .my-body .my-left {
    display: none;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_personal_information.css.map */