:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
  }

  .my-last-flight {
    .container {
        .no-data {
            text-align: center;
            margin-bottom: 4em;
            img {
                width: 20% !important;
                mix-blend-mode: multiply;
            }
    
            h1 {
                color: #dc2626;
            }
        }     
    }
  }




  /* 
    Start Media
  */

  @media(max-width: 480px) {
    .my-last-flight {
        .container {
            .no-data {
                text-align: center;
                margin-bottom: 4em;
                img {
                    width: 40% !important;
                }
        
                h1 {
                    font-size: 16px;
                }
            }     
        }
      }
  }


   /* 
    End Media
  */