:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }

  // Public Class
  .active-link-for-sidebar-dashboard {
    background-color: #96C9D1;
    color:  var(--white-color);
    transition: 0.3s ease;
  }


  .my-sidebar-dashboard {
    .container {
        .content {
            display: flex;
            justify-content: space-between;

            width: 100%;

            .left {
                width: 80%;
                //background-color: rgb(226, 224, 224);
                padding: 1em;

                .main {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .to-bar {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        i {
                            cursor: pointer;
                        }
                    }
                }
            }

            .right {
                width: 20%;
                background-color: #33385B;
                padding: 1em;
                height: auto;

                .head {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    i {
                        color: var(--white-color);
                        cursor: pointer;
                    }

                    Link {
                        img {
                            width: 30% !important;
                        }
                    }
                }

                .my-link {
                    .this-link {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        margin-bottom: 0.5em;

                        &:hover {
                            background-color: #96C9D1;
                            color:  var(--white-color);
                            transition: 0.3s ease;
                        }

                        h4 {
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            color: var(--background-color);
                            cursor: pointer;
                            transition: 0.3s ease;
                            padding: 0.7em;
                        }
                    }
                }
            }
        }
    }
  }





  /* 
    Start Media
  */
  @media(min-width: 767px) {
    .my-sidebar-dashboard {
        .container {
            .content {
                position: relative;

               .left {
                .main {
                    i.fa-bars {
                        display: none;
                    }
                }
               }

               .right {
                .head {
                    i.fa-circle-xmark {
                        display: none;
                    }
                }
               }
            }
        }
    }
}


@media(max-width: 767px) {
    .my-sidebar-dashboard {
        .container {
            .content {
                position: relative;
                overflow-x: hidden !important;

                .left {
                    width: 100%;
                }

                .right {
                    width: 30%;
                    position: absolute;
                    right: -40%;
                    height: 100%;

                    .my-link {
                        .this-link {
                            h4 {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}



@media(max-width: 480px) {
    .my-sidebar-dashboard {
        .container {
            .content {
                .left {
                    .main {
                        h2 {
                            font-size: 18px;
                        }
                    }
                }

                .right {
                    width: 40%;
                }
            }
        }
    }
}



 /* 
    End Media
  */