:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
}

.my-last-hotel .container .no-data {
  text-align: center;
  margin-bottom: 4em;
}
.my-last-hotel .container .no-data img {
  width: 20% !important;
  mix-blend-mode: multiply;
}
.my-last-hotel .container .no-data h1 {
  color: #dc2626;
}

/* 
  Start Media
*/
@media (max-width: 480px) {
  .my-last-hotel .container .no-data {
    text-align: center;
    margin-bottom: 4em;
  }
  .my-last-hotel .container .no-data img {
    width: 40% !important;
  }
  .my-last-hotel .container .no-data h1 {
    font-size: 16px;
  }
}
/* 
  End Media
*//*# sourceMappingURL=style_last_hotel.css.map */