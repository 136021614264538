:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
  }

  .my-last-wallet {
    .container {
        .no-data {
            text-align: center;
            margin-bottom: 4em;

            img {
                width: 40% !important;
                mix-blend-mode: multiply;
            }
        }     
    }
  }




  /* 
    Start Media
  */

  @media(max-width: 480px) {
    .my-last-wallet {
        .container {
            .no-data {
                img {
                    width: 60% !important;
                }
            }     
        }
      }
  }


   /* 
    End Media
  */