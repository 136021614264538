:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.my-master-dashboard .container .master .head {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  background-color: var(--white-color);
  border-radius: 6px;
  padding: 1.5em;
}
.my-master-dashboard .container .master .head img {
  width: 30%;
}
.my-master-dashboard .container .master .head .title, .my-master-dashboard .container .master .head .main-title {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.my-master-dashboard .container .master .head .main-title {
  margin-top: 2em;
  margin-right: 4em;
}
.my-master-dashboard .container .master .head-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 4em;
}
.my-master-dashboard .container .master .head-content span {
  width: 350px;
  height: 2px;
  background-color: var(--toot-color);
  display: inline-block;
}
.my-master-dashboard .container .master .head-content h2 {
  color: var(--toot-color);
}
.my-master-dashboard .container .master .my-content {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2em;
  margin-bottom: 2em;
}
.my-master-dashboard .container .master .my-content .card {
  background-color: #FB958D;
  width: 250px;
  padding: 1em;
  border-radius: 6px;
  text-align: center;
  color: var(--background-color);
}
.my-master-dashboard .container .master .my-content .card h1 {
  font-size: 70px;
  margin-bottom: 0.2em;
}
.my-master-dashboard .container .master .my-content .card p {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  margin-bottom: 1em;
}
.my-master-dashboard .container .master .my-content .card p i {
  font-size: 20px;
}

/* 
    Start Media
*/
@media (max-width: 480px) {
  .my-master-dashboard .container .master .head {
    flex-direction: column;
  }
  .my-master-dashboard .container .master .head img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .my-master-dashboard .container .master .head .main-title {
    margin-right: 0;
    text-align: right;
  }
  .my-master-dashboard .container .master .head-content {
    gap: 10px;
  }
  .my-master-dashboard .container .master .head-content span {
    width: 100px;
  }
  .my-master-dashboard .container .master .head-content h2 {
    font-size: 16px;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_master_dashboard.css.map */