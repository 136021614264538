:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --Dark-toot-color: #33385B;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
}

.my-extra-services .success-message {
  background-color: rgba(0, 0, 0, 0.486);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-extra-services .success-message .my-massage {
  text-align: center;
  background-color: #16A34A;
  padding: 1em;
  border-radius: 1em;
  position: relative;
}
.my-extra-services .success-message .my-massage img {
  width: 50%;
}
.my-extra-services .success-message .my-massage h3 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}
.my-extra-services .success-message .my-massage h3 i {
  color: white;
  font-size: 40px;
  margin: 1em 0;
}
.my-extra-services .success-message .my-massage button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--toot-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}
.my-extra-services .success-message .my-massage button:hover {
  background-color: var(--toot-color);
  color: var(--background-color);
  border: 1px solid var(--background-color);
  transition: 0.3s ease;
}
.my-extra-services .success-message .my-massage button:hover i {
  transform: rotate(0deg);
  transition: 0.3s ease;
}
.my-extra-services .success-message .my-massage button i {
  transform: rotate(-45deg);
  transition: 0.3s ease;
}
.my-extra-services .success-message .my-massage i.fa-circle-xmark {
  position: absolute;
  right: 1%;
  top: 1%;
  color: white;
  font-size: 30px;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-extra-services .success-message .my-massage i.fa-circle-xmark:hover {
  color: black;
  transition: 0.3s ease;
}
.my-extra-services .container {
  margin-top: 4em;
  background-color: var(--Dark-toot-color);
  position: relative;
}
.my-extra-services .container .head {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  color: var(--background-color);
  padding-top: 4em;
}
.my-extra-services .container .head span {
  background-color: var(--background-color);
  width: 150px;
  height: 2px;
}
.my-extra-services .container .head i {
  font-size: 3em;
}
.my-extra-services .container .content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  margin-top: 6em;
  padding-bottom: 15em;
  z-index: 900;
  position: relative;
}
.my-extra-services .container .content button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--toot-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  width: 25%;
}
.my-extra-services .container .content button:hover {
  background-color: var(--toot-color);
  color: var(--background-color);
  border: 1px solid var(--background-color);
  transition: 0.3s ease;
}
.my-extra-services .container .content button:hover i {
  transform: rotate(0deg);
  transition: 0.3s ease;
}
.my-extra-services .container .content button i {
  transform: rotate(-45deg);
  transition: 0.3s ease;
}
.my-extra-services .container .content .my-select {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--toot-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 20px;
  width: 25%;
}
.my-extra-services .container .content .my-select:hover {
  background-color: var(--toot-color);
  color: var(--background-color);
  border: 1px solid var(--background-color);
  transition: 0.3s ease;
}
.my-extra-services .container .content .my-select:hover i {
  transform: rotate(0deg);
  transition: 0.3s ease;
}
.my-extra-services .container .content .my-select i {
  transform: rotate(-45deg);
  transition: 0.3s ease;
}
.my-extra-services .container .content .my-select select {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--toot-color);
  width: 100%;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}
.my-extra-services .container .content .my-select select:hover {
  color: var(--background-color);
}
.my-extra-services .container .plane {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 0;
}
.my-extra-services .container .plane img {
  width: 90%;
}

/* 
    Start Media
*/
@media (max-width: 767px) {
  .my-extra-services .container .head h1 {
    font-size: 1.5em;
  }
  .my-extra-services .container .head i {
    font-size: 2em;
  }
  .my-extra-services .container .content {
    flex-direction: column;
  }
  .my-extra-services .container .content button {
    width: 50%;
  }
  .my-extra-services .container .content .my-select {
    width: 50%;
  }
  .my-extra-services .container .plane {
    top: 20%;
    left: 30%;
  }
  .my-extra-services .container .plane img {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .my-extra-services {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .my-extra-services .success-message .my-massage button {
    width: 50%;
  }
  .my-extra-services .container .content {
    flex-direction: column;
    margin-top: 3em;
  }
  .my-extra-services .container .content button {
    width: 80%;
  }
  .my-extra-services .container .content .my-select {
    width: 80%;
  }
  .my-extra-services .container .plane {
    top: 50%;
    left: 20%;
  }
  .my-extra-services .container .plane img {
    width: 105%;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_extra_services.css.map */