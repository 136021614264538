:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content {
  display: flex;
  align-items: center;
  padding-top: 8em;
  justify-content: center;
  margin-top: 0em;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search {
  display: flex;
  justify-content: center;
  gap: 5px;
  background-color: var(--toot-color);
  padding: 0.4em;
  border-radius: 6px;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search input, .my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search select {
  padding: 0.8em;
  border-radius: 6px;
  border: none;
  outline: none;
  direction: rtl;
  text-align: right;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search .data-from {
  display: flex;
  background-color: var(--background-color);
  border-radius: 6px;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search .data-from span {
  color: var(--place-holder-color);
  font-size: 12px;
  margin: 8px 5px 0px 5px;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search .location {
  display: flex;
  background-color: var(--background-color);
  border-radius: 6px;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search .location input {
  width: 20em;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search .location select {
  width: 20em;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search .location i {
  color: var(--toot-color);
  margin: 10px 5px 0px 5px;
  font-size: 16px;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search .location i.fa-caret-down {
  color: var(--place-holder-color);
  font-size: 14px;
  margin: 11px 5px 0px 5px;
  cursor: pointer;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search .Guest {
  cursor: pointer;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search-icon {
  transform: translateX(-2em);
  margin-left: 4em;
  cursor: pointer;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search-icon button {
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: var(--toot-color);
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search-icon button i {
  color: var(--background-color);
  background-color: var(--toot-color);
  padding: 0.5em;
  border-radius: 50%;
  font-size: 25px;
  transition: 0.3s ease;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .content .search-icon button i:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  transition: 0.3s ease;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-destinations .my-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 5px;
  background-color: rgba(25, 118, 210, 0.2941176471);
  text-align: right;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-destinations .my-content .card {
  display: flex;
  background-color: var(--background-color);
  width: 30%;
  cursor: pointer;
  border-radius: 14px;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-destinations .my-content .card .left {
  padding: 0.5em;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-destinations .my-content .card .left:hover h4, .my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-destinations .my-content .card .left:hover h5 {
  color: var(--toot-color);
  transition: 0.3s ease;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-destinations .my-content .card .left p {
  font-size: 12px;
  color: rgb(218, 48, 48);
  margin-top: 1em;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  text-align: right;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card {
  background-color: var(--background-color);
  width: 100%;
  cursor: pointer;
  border-radius: 14px;
  padding: 1em;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .first {
  display: flex;
  gap: 30px;
  flex-direction: row-reverse;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .first .test {
  visibility: hidden;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .first h5 {
  color: rgb(99, 99, 99);
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .secound {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 45px;
  margin-top: 2em;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .secound .room {
  display: flex;
  align-items: center;
  gap: 5px;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .secound .room h4, .my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .secound .room i {
  color: rgb(28, 28, 32);
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .secound .my-form .choose-room {
  display: flex;
  flex-direction: row-reverse;
  gap: 45px;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .secound .my-form .choose-room .adult select {
  color: var(--toot-color);
  border: none;
  outline: none;
  padding: 0.5em;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .secound .my-form .choose-room .children select {
  color: var(--toot-color);
  border: none;
  outline: none;
  padding: 0.5em;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .save {
  display: flex;
  justify-content: center;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .save button {
  margin-top: 2em;
  background-color: var(--toot-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  transition: 0.3s ease;
  padding: 0.5em;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  width: 20%;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .save button:hover {
  background-color: var(--background-color);
  color: var(--toot-color);
  border: 1px solid var(--toot-color);
  transition: 0.3s ease;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card hr {
  margin-top: 1em;
}
.my-search-result-hotel .container .bar-hotel .this-search-bar-hotel .this-container form.hotel-form .open-other-select .my-content .card .add-room {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10px;
  margin-top: 1em;
  color: var(--toot-color);
}
.my-search-result-hotel .container .head {
  background-color: var(--white-color);
  padding: 1em;
}
.my-search-result-hotel .container .head .top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10em;
}
.my-search-result-hotel .container .head .top .filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.my-search-result-hotel .container .head .top .filter select {
  padding: 0.5em;
  background-color: var(--blue-color);
  color: var(--background-color);
  width: 150px;
  text-align: center;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  outline: none;
  cursor: pointer;
}
.my-search-result-hotel .container .head .top .filter h3 {
  color: var(--toot-color);
}
.my-search-result-hotel .container .head .top .result {
  text-align: center;
}
.my-search-result-hotel .container .head .top .result h2 {
  color: var(--toot-color);
}
.my-search-result-hotel .container .head .down h2 {
  text-align: end;
  margin-bottom: 1em;
  color: var(--toot-color);
}
.my-search-result-hotel .container .head .down .open-destinations {
  display: flex;
  align-items: center;
}
.my-search-result-hotel .container .head .down .open-destinations i {
  color: var(--toot-color);
  font-size: 2em;
  cursor: pointer;
  transition: 0.3s ease;
  margin: 0 1em;
}
.my-search-result-hotel .container .head .down .open-destinations i:hover {
  color: var(--blue-color);
  transition: 0.3s ease;
}
.my-search-result-hotel .container .head .down .open-destinations .my-content {
  display: flex;
  gap: 10px;
  overflow-x: scroll !important;
  scroll-behavior: smooth;
  width: 90%;
}
.my-search-result-hotel .container .head .down .open-destinations .my-content::-webkit-scrollbar {
  display: none;
}
.my-search-result-hotel .container .head .down .open-destinations .my-content .card {
  display: flex;
  width: 500px;
  background-color: var(--background-color);
  border-radius: 14px;
}
.my-search-result-hotel .container .head .down .open-destinations .my-content .card .left {
  padding: 1em;
  text-align: center;
  width: 200px;
}
.my-search-result-hotel .container .head .down .open-destinations .my-content .card .left h4, .my-search-result-hotel .container .head .down .open-destinations .my-content .card .left h5 {
  margin: 0;
}
.my-search-result-hotel .container .head .down .open-destinations .my-content .card .left p {
  font-size: 12px;
  color: rgb(218, 48, 48);
  margin-top: 1em;
}
.my-search-result-hotel .container .head .down .open-destinations .my-content .card .left button {
  padding: 0.5em;
  background-color: var(--blue-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 3em;
  width: 50%;
  transition: 0.3s ease;
}
.my-search-result-hotel .container .head .down .open-destinations .my-content .card .left button:hover {
  background-color: var(--background-color);
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
  transition: 0.3s ease;
}
.my-search-result-hotel .container .head .down .open-destinations .my-content .card .right img {
  width: 150px;
  height: auto;
}
.my-search-result-hotel .container .content {
  display: flex;
  gap: 1em;
  margin-top: 2em;
  width: 100%;
}
.my-search-result-hotel .container .content .left {
  width: 25%;
  margin-left: 1em;
}
.my-search-result-hotel .container .content .left .my-map img {
  width: 100%;
}
.my-search-result-hotel .container .content .left .my-filter {
  background-color: var(--white-color);
  padding: 0.5em;
  border-radius: 6px;
  border: 1px solid var(--blue-color);
}
.my-search-result-hotel .container .content .left .my-filter .rate-hotel h2 {
  color: var(--toot-color);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.my-search-result-hotel .container .content .left .my-filter .rate-hotel .my-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
}
.my-search-result-hotel .container .content .left .my-filter .rate-hotel .my-content .right {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 5px;
}
.my-search-result-hotel .container .content .left .my-filter .rate-hotel .my-content .right img {
  width: 100px;
}
.my-search-result-hotel .container .content .left .my-filter hr {
  margin: 1em 0;
  width: 80%;
  height: 1px;
  background-color: gray;
  margin-left: auto;
  margin-right: auto;
}
.my-search-result-hotel .container .content .left .my-filter .price h2 {
  color: var(--toot-color);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.my-search-result-hotel .container .content .left .my-filter .price .my-content {
  text-align: center;
  display: flex;
}
.my-search-result-hotel .container .content .left .my-filter .price .my-content input {
  width: 80%;
  margin-left: auto;
}
.my-search-result-hotel .container .content .right {
  width: 75%;
}
.my-search-result-hotel .container .content .right h1 {
  text-align: center;
  border: 1px solid var(--blue-color);
  padding: 0.6em;
  border-radius: 6px;
  box-shadow: 0px 0px 5px rgba(145, 145, 145, 0.623);
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card {
  display: flex;
  justify-content: space-between;
  background-color: var(--white-color);
  border-radius: 14px;
  margin-top: 1em;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .details {
  padding: 1em;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .details i {
  color: gray;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .details i:hover {
  color: red;
  transition: 0.3s ease;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .details h2 {
  color: var(--toot-color);
  margin-top: 2em;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .details p {
  font-size: 12px;
  margin-top: 1em;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .details button {
  padding: 0.5em;
  background-color: var(--blue-color);
  color: var(--background-color);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
  margin-top: 3em;
  width: 100%;
  transition: 0.3s ease;
  font-weight: bold;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .details button:hover {
  background-color: var(--background-color);
  color: var(--blue-color);
  border: 1px solid var(--blue-color);
  transition: 0.3s ease;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .my-image {
  display: flex;
  justify-content: end;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .my-image .left {
  padding: 1em;
  text-align: right;
  width: auto;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .my-image .left h2 {
  color: var(--toot-color);
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .my-image .left img {
  width: 100px;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .my-image .left p {
  font-size: 12px;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .my-image .left h5 {
  margin-top: 4em;
  border: 1px solid var(--blue-color);
  padding: 0.5em;
  border-radius: 6px;
  color: var(--blue-color);
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .my-image .left p.count {
  color: red;
  margin-top: 0.5em;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .my-image .right {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.my-search-result-hotel .container .content .right .my-hotels .my-content .card .my-image .right img {
  width: 150px;
}

/* 
  Start Media
*/
@media (max-width: 992px) {
  .my-search-result-hotel .container form.hotel-form .content {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .my-search-result-hotel .container form.hotel-form .content .search {
    flex-direction: column-reverse;
  }
  .my-search-result-hotel .container form.hotel-form .content .search .data-from input {
    width: 100%;
  }
  .my-search-result-hotel .container form.hotel-form .content .search .data-from span {
    width: 30px;
  }
  .my-search-result-hotel .container form.hotel-form .content .search .location i {
    width: 26px;
  }
  .my-search-result-hotel .container form.hotel-form .content .search .my-select {
    width: 100%;
  }
  .my-search-result-hotel .container form.hotel-form .content .search .my-select i.fa-user {
    padding-right: 0 !important;
    margin-right: 0 !important;
    width: 20px !important;
  }
  .my-search-result-hotel .container form.hotel-form .content .search-icon {
    margin-top: 1em;
  }
  .my-search-result-hotel .container form.hotel-form .content .search-icon button {
    margin-left: 2em;
  }
}
@media (max-width: 767px) {
  .my-search-result-hotel .container form.hotel-form .open-destinations .my-content {
    flex-direction: column !important;
    background-color: transparent;
  }
  .my-search-result-hotel .container form.hotel-form .open-destinations .my-content .card {
    width: 100% !important;
    justify-content: end;
  }
  .my-search-result-hotel .container .head .top {
    flex-direction: column;
    gap: 2em;
  }
  .my-search-result-hotel .container .head .top .filter img {
    width: 70px;
  }
  .my-search-result-hotel .container .head .top .result {
    margin-bottom: 2em;
  }
  .my-search-result-hotel .container .content {
    flex-direction: column;
  }
  .my-search-result-hotel .container .content .left {
    width: 100%;
    margin-left: 0;
  }
  .my-search-result-hotel .container .content .left .my-map img {
    height: 300px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .my-search-result-hotel .container .content .right {
    width: 100%;
  }
  .my-search-result-hotel .container .content .right .my-hotels .my-content .card {
    flex-direction: column-reverse;
    text-align: center;
  }
  .my-search-result-hotel .container .content .right .my-hotels .my-content .card .details i {
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .my-search-result-hotel .container form.hotel-form .open-other-select .my-content {
    left: 0%;
    top: 110%;
    width: 100%;
  }
  .my-search-result-hotel .container form.hotel-form .open-other-select .my-content .card .first {
    gap: 10px !important;
    justify-content: center;
  }
  .my-search-result-hotel .container form.hotel-form .open-other-select .my-content .card .first .test {
    display: none;
  }
  .my-search-result-hotel .container form.hotel-form .open-other-select .my-content .card .secound {
    gap: 10px !important;
    justify-content: center;
  }
  .my-search-result-hotel .container form.hotel-form .open-other-select .my-content .card .secound:nth-child(2) {
    margin-left: 2em;
  }
  .my-search-result-hotel .container form.hotel-form .open-other-select .my-content .card .save button {
    width: 60% !important;
  }
  .my-search-result-hotel .container .head .down h2 {
    text-align: center;
  }
  .my-search-result-hotel .container .head .down .open-destinations i {
    margin: 0 0.5em;
  }
  .my-search-result-hotel .container .head .down .open-destinations .my-content {
    width: 100%;
  }
  .my-search-result-hotel .container .head .down .open-destinations .my-content .card {
    flex-direction: column-reverse;
  }
  .my-search-result-hotel .container .head .down .open-destinations .my-content .card .right {
    margin-left: auto;
    margin-right: auto;
  }
  .my-search-result-hotel .container .head .down .open-destinations .my-content .card .right img {
    width: 100%;
    border-radius: 14px;
  }
}
/* 
  End Media
*//*# sourceMappingURL=style_search_result_hotel.css.map */