:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
    --place-holder-color: #aaaaaa;
  }

//   body {
//     height: 3000px;
//   }



  .my-pakage-flight {
    .container {
        .this-search-flight {
            padding-top: 7em;
            
            h1 {
                background-color: var(--place-holder-color);
            }
        }

        .head {
            .top {
                display: flex;
                justify-content: center;
                align-items: center;

                background-color: var(--white-color);

                .left {
                    img {
                        mix-blend-mode: multiply;
                    }
                }

                .right {
                    text-align: center;

                    h2 {
                        color: var(--toot-color);
                        font-weight: bold;
                    }
                }
            }

            .down {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 40px;

                background-color: #E2F1FF;
                padding: 1em;

                .info {
                    text-align: center;

                    p {
                        margin-bottom: 0.5em;
                    }
                }

                span {
                    width: 2px;
                    height: 25px;
                    background-color: var(--toot-color);
                    display: inline-block;
                }
            }

            .title {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
    
                padding-top: 4em;

                // For search result flight
                // p {
                //     &:nth-of-type(3) {
                //         color: var(--toot-color);
                //         font-weight: bold;
                //     }

                //     &:nth-of-type(4) {
                //         color: var(--toot-color);
                //         font-weight: bold;
                //     }

                //     &:nth-of-type(5) {
                //         color: var(--toot-color);
                //         font-weight: bold;
                //     }

                // }

                //  For back tripe
                p {
                    &:nth-of-type(1) {
                        color: var(--toot-color);
                        font-weight: bold;
                    }

                    &:nth-of-type(2) {
                        color: var(--toot-color);
                        font-weight: bold;
                    }

                    &:nth-of-type(3) {
                        color: var(--toot-color);
                        font-weight: bold;
                    }

                    &:nth-of-type(4) {
                        color: var(--toot-color);
                        font-weight: bold;
                    }

                }
                
    
                // For search result flight
                // p.circle {
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
    
                //     width: 20px;
                //     height: 20px;
                //     border-radius: 50%;
    
                //     &:nth-child(2) {
                //         border: 1px solid #C2C0C0;
                //         color: #C2C0C0;
                //     }
    
                //     &:nth-child(5) {
                //         background-color: var(--toot-color);
                //         color: var(--background-color);
                //     }
                // }

                //  For back tripe
                p.circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
    
                    &:nth-child(2) {
                        border: 1px solid var(--toot-color);
                        color: var(--toot-color);
                    }
    
                    &:nth-child(5) {
                        background-color: var(--toot-color);
                        color: var(--background-color);
                    }
                }
    
                hr {
                    width: 15%;
                    height: 2px;
                    background-color: var(--toot-color);
                    border: none;
                }
    
                i {
                    color: var(--toot-color);
                }
            }
        }

        .content {
            margin-top: 2em;
            .head {
                display: flex;
                align-items: center;
                justify-content: space-between;

                background-color: #33A850;
                padding: 1em 1em;
                border-radius: 6px;
                

                button {
                    background-color: var(--white-color);
                    color: #33A850;
                    border: none;
                    outline: none;
                    padding: 0.5em;
                    border-radius: 6px;
                    width: 100px;
                    font-size: medium;
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.3s ease;

                    &:hover {
                        background-color: #2e6b3d;
                        color: var(--white-color);
                        transition: 0.3s ease;
                    }
                }

                .choose-tripe {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    color: var(--white-color);

                    span {
                        width: 2px;
                        height: 30px;
                        background-color: var(--background-color);
                        display: inline-block;
                    }
                }
            }

            .card-tripe {
                background-color: var(--white-color);
                padding: 1em;
                border-radius: 6px;
                border: 1px solid #33A850;
                width: 60%;
                margin: 0 auto;

                .top {
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   gap: 20px;

                   .time {
                    text-align: center;

                    h4 {
                        color: var(--toot-color);
                    }

                    span {
                        color: #E33838;
                        font-size: 14px;
                    }
                   }

                   .line-flight {
                    text-align: center;
                    width: 50%;

                    p {
                        color: #878686;
                        font-size: 14px;
                    }

                    img {
                        width: 100%;
                    }
                   }

                   span.v-line {
                    width: 2px;
                    height: 60px;
                    background-color: var(--blue-color);
                    display: inline-block;
                   }

                   .logo-flight {
                    text-align: center;
                    img {
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        object-fit: contain;
                    }

                    span {
                        color: #878686;
                        font-size: 12px;
                    }
                   }
                }

                .details {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    margin: 0 1em;

                    cursor: pointer;

                    i {
                        font-size: 14px;
                    }
                }

                hr {
                    width: 80%;
                    margin: 2em auto;
                }

                .down {
                    display: flex;
                    justify-content: end;

                    .right {
                        width: fit-content;
                        text-align: center;
                        color: var(--blue-color);
                        margin: 0 1em;
                    }
                }
            }

            .pakage {
                display: flex;
                flex-direction: column;
                //justify-content: end;
                align-items: center;

                margin-top: 2em;

                .card-pakage {
                    display: flex;
                    gap: 20px;

                    .this-pakage {
                        text-align: center;
                        background-color: var(--white-color);
                        padding: 1em;
                        width: 300px;
    
                        margin-top: 2em;
                        border-radius: 6px;
    
                        .pakage-head {
                            h4 {
                                color: var(--blue-color);
                            }
    
                            h3 {
                                direction: rtl;
                                color: #33A850;
                                margin: 0.5em 0;
                            }
                        }
    
                        hr {
                            width: 80%;
                            margin: 1em auto;
                            height: 1px;
                            border: none;
                            background-color: var(--place-holder-color);
                        }
    
                        .pakage-content {
                            p {
                                display: flex;
                                gap: 5px;
                                align-items: center;
                                justify-content: center;
    
                                color: var(--toot-color);
                                font-weight: bold;
    
                                i {
                                    color: var(--toot-color);
                                    font-size: 18px;
                                }
                            }
    
                            .bag {
                                display: flex;
                                justify-content: space-between;
                                flex-direction: row-reverse;
    
                                direction: rtl;
                                margin-top: 1em;
    
                                span {
                                    font-size: 14px;
                                }
                                
                            }
                        }
    
                        .choose {
                            margin-top: 1em;
    
                            button {
                                background-color: var(--blue-color);
                                color: var(--white-color);
                                border: 1px solid var(--blue-color);
                                outline: none;
                                padding: 0.5em;
                                border-radius: 6px;
                                width: 100%;
                                font-size: medium;
                                font-weight: 700;
                                cursor: pointer;
                                transition: 0.3s ease;
            
                                &:hover {
                                    background-color: var(--white-color);
                                    color: var(--blue-color);
                                    transition: 0.3s ease;
                                }
                            }
                        }
                    }
                }
            }
        }

        .total-price {
            display: flex;
            justify-content: space-between;
            align-items: center;

            //text-align: center;
            width: 500px;
            margin: 2em auto;
            background-color: #E2F1FF;
            padding: 1em;

            button {
                display: flex;
                align-items: center;
                gap: 10px;

                background-color: var(--blue-color);
                color: var(--white-color);
                border: 1px solid var(--blue-color);
                outline: none;
                padding: 0.5em;
                border-radius: 6px;
                //width: 100%;
                font-size: medium;
                font-weight: 700;
                cursor: pointer;
                transition: 0.3s ease;

                &:hover {
                    background-color: var(--white-color);
                    color: var(--blue-color);
                    transition: 0.3s ease;
                }
            }
        }
    }
  }





  /* 
    Start Media
  */
@media(max-width: 767px) {
    .my-pakage-flight {
        .container {
            .head {
                .top {
                    flex-direction: column;

                    .right {
                        margin-bottom: 2em;
                    }
                }

                .down {
                    flex-wrap: wrap;
                    gap: 20px;
                }
            }

            .content {
                .card-tripe {
                    width: 100%;
                }
            }

            .pakage {
                .card-pakage {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }
    }
}









@media(max-width: 480px) {
    .my-pakage-flight {
        .container {
            .head {
                .title {
                    gap: 2px;

                    p {
                        font-size: 12px;
                    }

                    hr {
                        width: 10%;
                    }
                }
            }

            .content {
                .head {
                    .choose-tripe {
                        text-align: right;
                        // align-items: end;
                        // justify-content: end;

                        p, h3 {
                            width: fit-content !important;
                        }
                    }
                }
            }

            .total-price {
                width: 100%;

                h2 {
                    font-size: 18px;
                }
            }
        }
    }
}

  /* 
    End Media
  */