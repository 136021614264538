:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --Dark-toot-color: #33385B;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
}
 

.my-extra-services {
    // Start Massage
    .success-message {
        background-color: rgba(0, 0, 0, 0.486);
        position: fixed;
        top: 0; // إضافة هذه الخاصية
        left: 0; // إضافة هذه الخاصية
        width: 100%;
        height: 100vh;
        z-index: 9999;
        display: flex; // استخدام flex لتوسيط المحتوى
        align-items: center; // توسيط عمودي
        justify-content: center; // توسيط أفقي
    
        .my-massage {
            text-align: center;
            background-color: #16A34A;
            padding: 1em;
            border-radius: 1em;
            position: relative;
    
            img {
                width: 50%;
            }
    
            h3 {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 1em;
    
                i {
                    color: white;
                    font-size: 40px;
                    margin: 1em 0;
                }
            }

            button {
                display: flex;
                gap: 10px;
                justify-content: center;
                align-items: center;

                background-color: var(--background-color);
                color: var(--toot-color);
                border: 1px solid transparent;
                outline: none;
                transition: 0.3s ease;
                padding: 0.5em;
                border-radius: 6px;
                cursor: pointer;
                font-size: 20px;
                width: 25%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 1em;

                &:hover {
                    background-color: var(--toot-color);
                    color: var(--background-color);
                    border: 1px solid var(--background-color);
                    transition: 0.3s ease;

                    i {
                        transform: rotate(0deg);
                        transition: 0.3s ease;
                    }
                }

                i {
                    transform: rotate(-45deg);
                    transition: 0.3s ease;
                }
            }

            i.fa-circle-xmark {
                position: absolute;
                right: 1%;
                top: 1%;
                color: white;
                font-size: 30px;
                cursor: pointer;
                transition: 0.3s ease;
        
                &:hover {
                    color: black;
                    transition: 0.3s ease;
                }
            }
        }
    
        
        
      }


    // End Massage



    .container {
        margin-top: 4em;
        background-color: var(--Dark-toot-color);
        position: relative;

        .head {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            text-align: center;
            color: var(--background-color);
            padding-top: 4em;

            span {
                background-color: var(--background-color);
                width: 150px;
                height: 2px;
            }

            i {
                font-size: 3em;
            }
        }

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            text-align: center;
            margin-top: 6em;
            padding-bottom: 15em;
            z-index: 900;
            position: relative;

            button {
                display: flex;
                gap: 10px;
                justify-content: center;
                align-items: center;

                background-color: var(--background-color);
                color: var(--toot-color);
                border: 1px solid transparent;
                outline: none;
                transition: 0.3s ease;
                padding: 0.5em;
                border-radius: 6px;
                cursor: pointer;
                font-size: 20px;
                width: 25%;

                &:hover {
                    background-color: var(--toot-color);
                    color: var(--background-color);
                    border: 1px solid var(--background-color);
                    transition: 0.3s ease;

                    i {
                        transform: rotate(0deg);
                        transition: 0.3s ease;
                    }
                }

                i {
                    transform: rotate(-45deg);
                    transition: 0.3s ease;
                }
            }

            .my-select {
                display: flex;
                gap: 10px;
                justify-content: center;
                align-items: center;

                background-color: var(--background-color);
                color: var(--toot-color);
                border: 1px solid transparent;
                outline: none;
                transition: 0.3s ease;
                padding: 0.5em;
                border-radius: 6px;
                cursor: pointer;
                font-size: 20px;
                width: 25%;

                &:hover {
                    background-color: var(--toot-color);
                    color: var(--background-color);
                    border: 1px solid var(--background-color);
                    transition: 0.3s ease;

                    i {
                        transform: rotate(0deg);
                        transition: 0.3s ease;
                    }
                }

                i {
                    transform: rotate(-45deg);
                    transition: 0.3s ease;
                }

                select {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    color: var(--toot-color);
                    width: 100%;
                    font-size: 20px;
                    text-align: center;
                    cursor: pointer;

                    &:hover {
                        color: var(--background-color);
                    }
                }
            }
        }

        .plane {
            // direction: rtl;
            // transform: translateY(-15em);
            position: absolute;
            left: 50%;
            top: 0;
            z-index: 0;

            img {
                width: 90%;
            }
        }
    }
}





/* 
    Start Media
*/
@media(max-width: 767px) {
    .my-extra-services {
        .container {
            .head {
                h1 {
                    font-size: 1.5em;
                }

                i {
                    font-size: 2em;
                }
            }

            .content {
                flex-direction: column;

                button {
                    width: 50%;
                }

                .my-select {
                    width: 50%;
                }
            }

            .plane {
                top: 20%;
                left: 30%;

                img {
                    width: 100%;
                }
            }
        }
    }
}








@media(max-width: 480px) {
    .my-extra-services {
        overflow-x: hidden;
        overflow-y: hidden;

        .success-message {
            .my-massage {
                button {
                    width: 50%;
                }
            }
        }

        .container {

            .content {
                flex-direction: column;
                margin-top: 3em;

                button {
                    width: 80%;
                }

                .my-select {
                    width: 80%;
                }
            }

            .plane {
                top: 50%;
                left: 20%;

                img {
                    width: 105%;
                }
            }
        }
    }
}



/* 
    End Media
*/