:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
  --blue-color: #1976D2;
  --place-holder-color: #aaaaaa;
}

.my-offers-dashboard .container .add {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 3em;
}
.my-offers-dashboard .container .add .add-title {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-bottom: 1em;
}
.my-offers-dashboard .container .add .add-title label {
  margin-bottom: 0.5em;
}
.my-offers-dashboard .container .add .add-title input {
  padding: 0.8em;
  direction: rtl;
  border-radius: 6px;
  border: 1px solid #C8C7CC;
  width: 800px;
  outline: none;
}
.my-offers-dashboard .container .add .add-title textarea {
  padding: 0.8em;
  direction: rtl;
  border-radius: 6px;
  border: 1px solid #C8C7CC;
  max-width: 800px;
  min-width: 800px;
  height: 100px;
  outline: none;
}
.my-offers-dashboard .container .add .rate-and-image {
  display: flex;
  gap: 100px;
}
.my-offers-dashboard .container .add .rate-and-image .my-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white-color);
  padding: 1.5em;
}
.my-offers-dashboard .container .add .rate-and-image .my-left i {
  font-size: 60px;
  color: var(--blue-color);
}
.my-offers-dashboard .container .add .rate-and-image .my-right {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.my-offers-dashboard .container .add .rate-and-image .my-right label {
  margin-bottom: 0.5em;
}
.my-offers-dashboard .container .add .rate-and-image .my-right select {
  background-color: var(--white-color);
  padding: 0.5em;
  width: 400px;
  border: 1px solid #C8C7CC;
  outline: none;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  direction: rtl;
}
.my-offers-dashboard .container .add .rate-and-image .my-right input {
  padding: 0.8em;
  direction: rtl;
  border-radius: 6px;
  border: 1px solid #C8C7CC;
  width: 400px;
  outline: none;
}
.my-offers-dashboard .container .add button {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: 1px solid var(--blue-color);
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  width: 150px;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
  margin-top: 1em;
}
.my-offers-dashboard .container .add button:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}
.my-offers-dashboard .container .head-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 4em;
}
.my-offers-dashboard .container .head-content span {
  width: 350px;
  height: 2px;
  background-color: var(--toot-color);
  display: inline-block;
}
.my-offers-dashboard .container .head-content h2 {
  color: var(--toot-color);
}
.my-offers-dashboard .container .content .my-cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4em;
}
.my-offers-dashboard .container .content .my-cards .card {
  background-color: var(--white-color);
  width: 400px;
  text-align: center;
}
.my-offers-dashboard .container .content .my-cards .card img {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.my-offers-dashboard .container .content .my-cards .card h2 {
  color: var(--toot-color);
  margin: 0.5em 0;
}
.my-offers-dashboard .container .content .my-cards .card p {
  color: #3B3B3B;
  padding: 0.5em;
  height: 90px;
  overflow-y: hidden;
}
.my-offers-dashboard .container .content .my-cards .card .rate {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
  padding: 1em;
}
.my-offers-dashboard .container .content .my-cards .card .rate i {
  color: #FED208;
  font-size: 14px;
}
.my-offers-dashboard .container .content .my-cards .card .rate i.no-rate {
  color: var(--place-holder-color);
}
.my-offers-dashboard .container .content .my-cards .card .rate span {
  font-size: 14px;
}
.my-offers-dashboard .container .content .my-cards .card button {
  background-color: var(--blue-color);
  color: var(--white-color);
  border: 1px solid var(--blue-color);
  outline: none;
  padding: 0.5em;
  border-radius: 6px;
  margin-bottom: 0.5em;
  width: 100%;
  font-size: medium;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease;
}
.my-offers-dashboard .container .content .my-cards .card button:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}
.my-offers-dashboard .container .content .my-cards .card button.edit {
  background-color: #33A850;
  border: 1px solid #33A850;
  transition: 0.3s ease;
}
.my-offers-dashboard .container .content .my-cards .card button.edit:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}
.my-offers-dashboard .container .content .my-cards .card button.delete {
  background-color: #E74133;
  border: 1px solid #E74133;
}
.my-offers-dashboard .container .content .my-cards .card button.delete:hover {
  background-color: var(--white-color);
  color: var(--blue-color);
  transition: 0.3s ease;
}

/* 
    Start Media
*/
@media (max-width: 767px) {
  .my-offers-dashboard .container .add .add-title input {
    width: 500px;
  }
  .my-offers-dashboard .container .add .add-title textarea {
    min-width: 500px;
    max-width: 500px;
  }
  .my-offers-dashboard .container .add .rate-and-image {
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 480px) {
  .my-offers-dashboard .container .add .add-title input {
    width: 300px;
  }
  .my-offers-dashboard .container .add .add-title textarea {
    min-width: 300px;
    max-width: 300px;
  }
  .my-offers-dashboard .container .add .rate-and-image .my-right select, .my-offers-dashboard .container .add .rate-and-image .my-right input {
    width: 300px;
  }
  .my-offers-dashboard .container .head-content {
    gap: 10px;
  }
  .my-offers-dashboard .container .head-content span {
    width: 100px;
  }
  .my-offers-dashboard .container .head-content h2 {
    font-size: 16px;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_offers_dashboard.css.map */