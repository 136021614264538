:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
}

.my-navbar {
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 2rem;

        background-color: #9494ac77;
        position: fixed;
        width: 100%;
        z-index: 999;

        .left {

            .header {
                display: flex;
                //align-items: center;

                .my-account {
                    //margin-bottom: 2em;
                    a {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        flex-direction: row-reverse;

                        cursor: pointer;
                        
                        text-decoration: none;
        
                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            box-shadow: 3px 3px 3px  rgba(0, 0, 0, 0.616);
                        }
                    }
                    
                }
    
                button {
                    background-color: var(--white-color);
                    color: var(--toot-color);
                    border: none;
                    padding: 8px;
                    border-radius: 6px;
                    font-weight: bold;
                    cursor: pointer;
                    margin-bottom: 0.5rem;
                    transition: 0.3s ease;
                    width: fit-content;
    
                    &:hover {
                        background-color: var(--toot-color);
                        color: var(--white-color);
                        transition: 0.3s ease;
                    }
                }
    
                .select-container {
                    position: relative;
                    display: inline-block;
    
                    .curency {
                        padding: 6px;
                        border-radius: 6px;
                        margin: 0 0.5rem;
                        cursor: pointer;
                        color: var(--toot-color);
                        border: none;
                        outline: none;
                    }
    
                    i {
                        position: absolute;
                        right: 30px; /* لتحديد موقع الأيقونة بجانب العنصر */
                        top: 50%;
                        transform: translateY(-50%); /* لتوسيط الأيقونة عموديًا */
                    }
                }
    
                button.lang {
                    //margin-left: 1rem;
                    //margin-right: 1rem;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                }
            }
            
            


            .icons {
                color: var(--background-color);
                display: flex;
                gap: 12px;
                margin-top: 0.5em;
            }
        }

        .right {
            width: 12%;
            img {
                width: 100%;
                
            }
        }
    }
}



/* 
    Start Media
*/
@media(max-width: 767px) {
    .my-navbar {
        .container {
            .right {
                width: 20%;
            }
        }
    }
}



/* 
    End Media
*/