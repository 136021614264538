:root {
    --white-color: #FFFFFF;
    --toot-color: #5A64A8;
    --background-color: #F1F1F1;
    --blue-color: #1976D2;
  }

//   body {
//     height: 2000px;
//   }

a {
    text-decoration: none;
}


  .my-room-avaliable {
    .success-message {
        background-color: rgba(0, 0, 0, 0.486);
        position: fixed;
        top: 0; // إضافة هذه الخاصية
        left: 0; // إضافة هذه الخاصية
        width: 100%;
        height: 100vh;
        z-index: 9999;
        display: flex; // استخدام flex لتوسيط المحتوى
        align-items: center; // توسيط عمودي
        justify-content: center; // توسيط أفقي
    
        .my-massage {
            text-align: center;
            //background-color: #16A34A;
            background-color: rgba(0, 0, 0, 0.747);
            padding: 1em;
            border-radius: 1em;
            position: relative;
            width: 60%;

            h1 {
                color: var(--background-color);
                margin-bottom: 2em;
            }

            .book-details {
                display: flex;
                justify-content: space-between;
                color: var(--background-color);
                
                .left {
                    text-align: left;
                }

                .right {
                    text-align: right;

                    h2 {
                        &:nth-of-type(1) {
                            color: var(--blue-color);
                        }

                        &:nth-of-type(2) {
                            color: #F23737;
                        }
                    }
                }
            }

            hr {
                width: 80%;
                margin: 1em auto;
            }

            h2.final {
                margin: 2em 0;
                color: #33A850;
            }
    
            h3 {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 1em;
    
                i {
                    color: white;
                    font-size: 40px;
                    margin: 1em 0;
                }
            }

            button {
                display: flex;
                gap: 10px;
                justify-content: center;
                align-items: center;

                background-color: var(--blue-color);
                color: var(--background-color);
                border: 1px solid transparent;
                outline: none;
                transition: 0.3s ease;
                padding: 0.5em;
                border-radius: 6px;
                cursor: pointer;
                font-size: 20px;
                width: 25%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 1em;

                &:hover {
                    background-color: #33A850;
                    color: var(--background-color);
                    border: 1px solid var(--background-color);
                    transition: 0.3s ease;
                }

                
            }

            i.fa-circle-xmark {
                position: absolute;
                right: 1%;
                top: 1%;
                color: white;
                font-size: 30px;
                cursor: pointer;
                transition: 0.3s ease;
        
                &:hover {
                    color: rgb(122, 122, 122);
                    transition: 0.3s ease;
                }
            }
        } 
      }

    .container {

        .top {
            margin: 0 2em;
            .head {
                text-align: right;
                padding-top: 7.5em;

                h1 {
                    color: var(--toot-color);
                }

                .my-img {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;

                    margin-top: 1em;

                    img {
                        width: 40px;
                    }

                    h4 {
                        color: var(--toot-color);
                    }
                }

                hr {
                    width: 50%;
                    margin-left: auto;
                    height: 3px;
                    background-color: var(--toot-color);
                }

                img {
                    width: 100px;
                }
            }

            .content {
                display: flex;
                align-items: start;
                gap: 10px;

                width: 100%;

                .left {
                    width: 25%;

                    .top {
                        border: 1px solid var(--blue-color);
                        box-shadow: 0px 0px 5px rgba(145, 145, 145, 0.623);
                        border-radius: 6px;
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        padding: 1em;

                        .comment {
                            h4 {
                                text-align: center;
                                margin-bottom: 0.5em;
                                color: var(--toot-color);
                            }
    
                            .my-avatar {
                                display: flex;
                                align-items: center;
                                flex-direction: row-reverse;
    
                                img {
                                    width: 50px;
                                    mix-blend-mode: multiply;
                                }
    
                                h5 {
                                    color: var(--toot-color);
                                }
                            }
    
                            p {
                                text-align: right;
                            }
                        }
    
                        hr {
                            margin: 1em;
                        }
                    }

                    .my-map {
                        margin-top: 1em;
                        img {
                            //width: 577px;
                            width: 100%;
                            height: 260px;
                            border-radius: 6px;
                        }
                    }
                    
                }

                .right {
                    width: 75%;
                    cursor: pointer;
                    //background-color: rgb(94, 93, 93);
                    

                    .big-img {
                        display: flex;

                        .img-main {
                            //width: 45%;
    
                            img {
                                width: 580px;
                            }
                        }
    
                        .img-secound {
                            //width: 30%;
    
                            img {
                                width: 390px;
                            }
                        }
                    }

                    .small-img {
                        display: flex;
                        flex-wrap: nowrap;

                        width: 100%;
                        overflow-x: hidden;
                        position: relative;

                        .more-img {
                            position: absolute;
                            z-index: 2;
                            top: 50%;
                            left: 5%;
                            transform: translateY(-50%);
                            text-align: center;
                            color: var(--white-color);
                        }

                        img {
                            width: 200px;

                            &:nth-of-type(1) {
                                filter: brightness(0.5);
                            }
                        }
                    }
                }
            } 

            // Hide in small screen (show in large only)
            .more-img-small-screen {
                text-align: center;

                button {
                    padding: 0.5em;
                    background-color: var(--blue-color);
                    color: var(--background-color);
                    border: 1px solid transparent;
                    outline: none;
                    cursor: pointer;
                    border-radius: 6px;
                    //margin-top: 3em;
                    width: 100%;
                    transition: 0.3s ease;
                    font-weight: bold;

                    &:hover {
                    background-color: var(--background-color);
                    color: var(--blue-color);
                    border: 1px solid var(--blue-color);
                    transition: 0.3s ease;
                    }
                }
            }

            .room-filter {
                text-align: center;
                background-color: var(--white-color);
                padding: 1em;

                h2 {
                    color: var(--toot-color);
                }

                .filter {
                    display: flex;
                    justify-content: center;
                    gap: 10px;

                    margin-top: 2em;
                    margin-bottom: 1em;

                    h4 {
                        color: var(--toot-color);
                        border: 1px solid var(--blue-color);
                        width: max-content;
                        padding: 0.5em;
                        border-radius: 50px;
                        cursor: pointer;
                        transition: 0.3s ease;
    
                        &:hover {
                            background-color: #E2F1FF;
                            transition: 0.3s ease;
                        }
                    }
                }
            }
        }

        .down {
            .my-main {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 30px;
                margin-top: 4em;

                .card {
                    width: 300px;
                    background-color: var(--white-color);
                    border: 1px solid var(--blue-color);
                    border-radius: 6px;
                    //margin: 2em;

                    .mian-img {
                        position: relative;

                        img {
                            width: 100%;
                            border-radius: 6px;
                        }

                        .my-arrow {
                            display: flex;
                            justify-content: space-between;

                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);

                            i {
                                color:  var(--white-color);
                                margin-left: 0.1em;
                                cursor: pointer;

                                &:nth-of-type(2) {
                                    transform: translateX(1600%);
                                }
                            }
                        }

                        .my-number {
                            display: flex;
                            gap: 5px;

                            background-color: rgba(0, 0, 0, 0.801);
                            width: fit-content;
                            padding: 0.3em;
                            position: absolute;
                            bottom: 1%;
                            
                            p {
                                font-size: 10px;
                                color: var(--white-color);
                            }
                        }
                    }
    
    
                    h3 {
                        color: var(--toot-color);
                        margin: 1em 0;
                        text-align: center;
                    }
    
                    .details {
    
                        p {
                            display: flex;
                            gap: 5px;
                            justify-content: end;
                            align-items: center;
    
                            margin: 0.5em;
    
                            i {
                                color: var(--blue-color);
                            }
                        }
                    }
    
                    .my-select {
                        display: flex;
                        justify-content: space-around;
    
                        select {
                            text-align: center;
                            outline: none;
                            border: 1px solid var(--blue-color);
                            padding: 0.5em;
                            border-radius: 6px;
                            cursor: pointer;
                            margin: 1em 0;
                        }
                    }
    
                    h4 {
                        color: var(--blue-color);
                        text-align: center;
                        margin: 1em 0;
                    }
                }
            }

            .more-option {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                margin-top: 4em;

                .my-option {
                    display: flex;
                    align-items: center;
                    //justify-content: center;
                    gap: 5px;

                    border: 1px solid #BCBCBC;
                    width: fit-content;
                    padding: 0.5em;
                    border-radius: 6px;

                    i {
                        color: #3B3B3B;
                        font-size: 18px;
                    }
                }
                
            }
            
        }

        .book-now {
            text-align: center;

            button {
                padding: 0.5em;
                background-color: var(--blue-color);
                color: var(--background-color);
                border: 1px solid transparent;
                outline: none;
                cursor: pointer;
                border-radius: 6px;
                margin-top: 3em;
                width: 30%;
                transition: 0.3s ease;
                font-weight: bold;
                font-size: 18px;
                margin: 4em auto;
    
                &:hover {
                background-color: var(--background-color);
                color: var(--blue-color);
                border: 1px solid var(--blue-color);
                transition: 0.3s ease;
                }
            }
        } 

        
    }
  }







  /* 
    Start Media
  */
@media(min-width: 767px) {
    .more-img-small-screen {
        display: none;
    }
}

@media(max-width: 767px) {
    .my-room-avaliable {
        .success-message {
            .my-massage {
                width: 90%;
            }
        }

        .container {
            .top {
                margin: 0 0;

                .head {
                    text-align: center;

                    .my-img {
                        justify-content: center;
                    }

                    hr {
                        margin: 0 auto;
                    }
                }

                .content {
                    flex-direction: column;
                    //margin-left: 1em;
                    //margin-right: 2em;

                    .left {
                        display: flex;
                        //align-items: end;
                        gap: 5px;

                        width: 100%;

                        .my-map {
                            margin-top: 0;
                        }
                    }

                    .right {
                        width: 100%;

                        .big-img {
                            //flex-direction: column;
                            //gap: 10px;

                            .img-main {
                                img {
                                    width: 200px;
                                    height: 200px;
                                    object-fit: cover;
                                    margin-left: 10px;
                                }
                            }

                            .img-secound {
                                img {
                                    width: 200px;
                                    height: 200px;
                                    object-fit: cover;
                                    margin-left: 10px;
                                }
                            }
                        }

                        .small-img {
                            flex-wrap: wrap;

                            .more-img {
                                display: none;
                            }

                            img {
                                width: 200px;
                                height: 200px;
                                object-fit: cover;
                                margin-left: 10px;

                                &:nth-of-type(1) {
                                    filter: brightness(1);
                                }
                            }
                        }
                    }
                }
            }

            .down {
                .more-option {
                    flex-wrap: wrap;
                }
            }

            .book-now {
                button {
                    width: 50%;
                }
            }
        }
    }
}




@media(max-width: 480px) {
    .last-img {
        display: none;
    }

    .success-message {
        .my-massage {
            h1 {
                margin-bottom: 0.5em !important;
            }

            .book-details {
                flex-direction: column-reverse;
                justify-content: center;

                .left, .right {
                    text-align: center !important;
                }

                .right {
                    h2 {
                        font-size: 18px;
                    }
                }
            }

            button {
                width: 50% !important;
            }
        }
    }

    .my-room-avaliable {
        .container {
            .top {

                .head {
                    text-align: center;

                    .my-img {
                        justify-content: center;
                    }

                    hr {
                        margin: 0 auto;
                    }
                }

                .content {
                    flex-direction: column;

                    .left {
                        display: flex;
                        gap: 5px;

                        width: 100%;

                        .my-map {
                            margin-top: 0;
                        }
                    }

                    .right {
                        width: 100%;
                        margin: 0 auto;

                        .big-img {
                            justify-content: center;
            
                            margin: 0 auto;

                            .img-main {
                                img {
                                    width: 150px;
                                    height: 150px;
                                    object-fit: cover;
                                    margin-left: 10px;
                                }
                            }

                            .img-secound {
                               
                                img {
                                    width: 150px;
                                    height: 150px;
                                    object-fit: cover;
                                    margin-left: 10px;
                                }
                            }
                        }

                        .small-img {
                            justify-content: center;
                            flex-wrap: wrap;

                            .more-img {
                                display: none;
                            }

                            img {
                                width: 150px;
                                height: 150px;
                                object-fit: cover;
                                margin-left: 10px;

                                &:nth-of-type(1) {
                                    filter: brightness(1);
                                }
                            }
                        }
                    }
                }

                .room-filter {
                    .filter {
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
}


  /* 
    End Media
  */