@charset "UTF-8";
:root {
  --white-color: #FFFFFF;
  --toot-color: #5A64A8;
  --background-color: #F1F1F1;
}

.my-navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: rgba(148, 148, 172, 0.4666666667);
  position: fixed;
  width: 100%;
  z-index: 999;
}
.my-navbar .container .left .header {
  display: flex;
}
.my-navbar .container .left .header .my-account a {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row-reverse;
  cursor: pointer;
  text-decoration: none;
}
.my-navbar .container .left .header .my-account a img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.616);
}
.my-navbar .container .left .header button {
  background-color: var(--white-color);
  color: var(--toot-color);
  border: none;
  padding: 8px;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 0.5rem;
  transition: 0.3s ease;
  width: -moz-fit-content;
  width: fit-content;
}
.my-navbar .container .left .header button:hover {
  background-color: var(--toot-color);
  color: var(--white-color);
  transition: 0.3s ease;
}
.my-navbar .container .left .header .select-container {
  position: relative;
  display: inline-block;
}
.my-navbar .container .left .header .select-container .curency {
  padding: 6px;
  border-radius: 6px;
  margin: 0 0.5rem;
  cursor: pointer;
  color: var(--toot-color);
  border: none;
  outline: none;
}
.my-navbar .container .left .header .select-container i {
  position: absolute;
  right: 30px; /* لتحديد موقع الأيقونة بجانب العنصر */
  top: 50%;
  transform: translateY(-50%); /* لتوسيط الأيقونة عموديًا */
}
.my-navbar .container .left .header button.lang {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.my-navbar .container .left .icons {
  color: var(--background-color);
  display: flex;
  gap: 12px;
  margin-top: 0.5em;
}
.my-navbar .container .right {
  width: 12%;
}
.my-navbar .container .right img {
  width: 100%;
}

/* 
    Start Media
*/
@media (max-width: 767px) {
  .my-navbar .container .right {
    width: 20%;
  }
}
/* 
    End Media
*//*# sourceMappingURL=style_navbar.css.map */